enum Category {
  Commerce = 'Commerce',
  Integration = 'Integration',
  Logistics = 'Logistics',
  Management = 'Management',
  Strategy = 'Strategy',
  Tooling = 'Tooling',
}

export default Category;
