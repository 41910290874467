import SortOrder from 'search/models/SortOrder';
import ChannelsState from './models/ChannelsState';
import SearchParameters from './models/SearchParameters';

export default function selectSearchParams(state: ChannelsState): SearchParameters {
  return {
    page: state.nextPage,
    query: state.query,
    categories: state.categories,
    visibility: state.visibility,
    sortBy: 'createdAt' as const,
    sortOrder: SortOrder.Descending,
  };
}
