import React, { useRef } from 'react';
import Checkbox from '@ingka/checkbox';
import IndeterminateCheckboxState from '../models/IndeterminateCheckboxState';

export default function IndeterminateCheckbox({
  id, subtle, label, prefix, onChange, state,
}: any) {
  const ref = useRef(null);
  if (ref.current) {
    const indeterminate = state === IndeterminateCheckboxState.Indeterminate;

    (ref.current as any).checkbox.indeterminate = indeterminate;
  }

  return (
    <Checkbox
      id={id}
      subtle={subtle}
      label={label}
      prefix={prefix}
      checked={state === IndeterminateCheckboxState.Checked}
      ref={ref}
      onChange={onChange}
    />
  );
}
