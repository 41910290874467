import React from 'react';
import person from '@ingka/ssr-icon/paths/person';
import gear from '@ingka/ssr-icon/paths/gear';
import questionMarkCircle from '@ingka/ssr-icon/paths/question-mark-circle';
import shopping from '@ingka/ssr-icon/paths/shopping-list';
import logo from 'assets/ikea-logo.svg';
import {
  Row, Column, Inline, Stack, Alignment, Space, IconLink,
} from 'ui';
import { Link } from 'react-router-dom';
import homePath from 'navigation/homePath';
import profilePath from 'profile/profilePath';
import newNotificationPath from 'notifications/newNotificationPath';
import usagePath from 'help/usagePath';
import adminPagePath from 'admin/services/adminPagePath';
import ErrorPage from 'errors/components/ErrorPage';
import useAsyncResource from 'http/useAsyncResource';
import useBackendService from 'services/useBackendService';
import UserService from 'users/services/UserService';
import dashboardPath from 'Dashboard/services/dashboardPath';

export default function NavigationBar() {
  const userService = useBackendService(UserService);
  const [user, error] = useAsyncResource(() => userService.findCurrentUser());
  if (error) {
    return <ErrorPage error={error} />;
  }
  return (
    <nav>
      <Row align={Alignment.SpaceBetween}>
        <Column>
          <a href={homePath()}>
            <Inline space={Space.Space250}>
              <img src={logo} alt="IKEA Logo" className="width--space-550" />

              <Stack align={Alignment.Center}>
                <b>iSubscribe</b>
              </Stack>
            </Inline>
          </a>
        </Column>

        <Column>
          <Stack align={Alignment.Center} height="100%">

            <Inline space={Space.Space100}>
              <Stack align={Alignment.Center}>
                <Link to={dashboardPath()}>Dashboard</Link>
              </Stack>

              <IconLink icon={shopping} text="Create notification" to={newNotificationPath()} />
              <IconLink icon={person} text="Go to profile" to={profilePath()} />
              { user && user.privileges.length > 0
                ? (
                  <IconLink icon={gear} text="Go to administration" to={adminPagePath()} />
                ) : <></> }
              <IconLink icon={questionMarkCircle} text="Go to help page" to={usagePath()} />

            </Inline>
          </Stack>
        </Column>
      </Row>
    </nav>
  );
}
