import React from 'react';
import List from '@ingka/list';
import Space from 'ui/models/Space';
import { Stack } from 'ui';
import howToSendANotification from 'assets/how-to-send-a-notification.jpg';
import howToCreateANotification from 'assets/how-to-create-a-notification.jpg';

export default function HowToSendANotification() {
  return (
    <Stack space={Space.Space150}>
      <img
        className="width--100"
        src={howToSendANotification}
        alt="Hand pointing at text 'Create Notification'"
      />

      <List ordered>
        <li>
          At the iSubscribe main page, click on the option
          {' '}
          <b>
            <i>Create Notification</i>
          </b>
          .
        </li>
        <li>
          Select the notification of the notification type in the
          {' '}
          <b>
            <i>
              Create an update for existing notification
            </i>
          </b>
          {' '}
          list You intend to update.
        </li>
        The categories contain the active notifications that could be updated.
        <img
          className="width--100"
          src={howToCreateANotification}
          alt="Screenshot showing how to Create Notification"
        />
        <li>Select the notification and fill in the form and follow the instructions.</li>
      </List>
    </Stack>
  );
}
