import Toast from '@ingka/toast';
import channelPath from 'channels/channelPath';
import adminPath from 'admin/adminPath';
import ChannelApproval from 'channels/models/ChannelApproval';
import ShowChannel from 'channels/models/ShowChannel';
import ChannelService from 'channels/services/ChannelService';
import JSONAPISerializer from 'json-api-serializer';
import _ from 'lodash';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import useBackendService from 'services/useBackendService';
import Stack from 'ui/components/Stack';
import Space from 'ui/models/Space';
import ChannelForm from './ChannelForm';

export default function EditChannel({
  channel,
  editedChannel,
  setChannel,
  isAdmin,
}: {
  channel: ShowChannel;
  editedChannel: ShowChannel | undefined;
  setChannel: React.Dispatch<React.SetStateAction<ShowChannel | undefined>>;
  isAdmin: boolean;
}) {
  const channelService = useBackendService(ChannelService);
  const { id } = channel;
  const [errors, setErrors] = useState<Array<JSONAPISerializer.ErrorObject>>([]);
  const [toastText, setToastText] = useState('');
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [state, setState] = useState<'IDLE' | 'SENDING'>('IDLE');
  const isLoading = state === 'SENDING';

  if (redirectPath && editedChannel) {
    return (
      <Redirect
        to={{ pathname: redirectPath, state: { toastMessage: 'Channel updated successfully!' } }}
      />
    );
  }

  return (
    <>
      <Stack space={Space.Space400}>
        <h1>
          {isAdmin && channel.approvalStatus === 'Pending' ? 'Reviewing channel ' : 'Editing channel '}
          &quot;
          {channel.name}
          &quot;
        </h1>
        <ChannelForm
          formType="edit"
          channel={channel}
          errors={errors}
          isLoading={isLoading}
          isAdmin={isAdmin}
          onApprovalSubmit={(approval) => {
            const approvalMessage = ChannelApproval.fromApprovalStateForm(approval);
            setState('SENDING');
            channelService
              .sendChannelApproval({ ...approvalMessage, id })
              .then(() => {
                setState('IDLE');
                setRedirectPath(adminPath());
                setToastText('Channel approval submitted successfully');
              })
              .catch(() => {
                setState('IDLE');
                setToastText('Could not perform channel approval.');
              });
          }}
          onSubmit={(formChannel) => {
            setState('SENDING');
            channelService
              .update({ ...formChannel, id })
              .then((updatedChannel) => {
                setState('IDLE');
                setChannel(updatedChannel);
                setRedirectPath(channelPath(updatedChannel));
              })
              .catch(({ response }) => {
                setState('IDLE');
                setErrors(response.data.errors);
                setToastText('Could not update channel.');
              });
          }}
          defaultCountriesChecked={false}
        />
      </Stack>

      <Toast
        isOpen={!_.isEmpty(toastText)}
        text={toastText}
        onTransitionEnd={() => {}}
        onCloseRequest={() => setToastText('')}
      />
    </>
  );
}
