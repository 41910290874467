import Modal, { ModalFooter, Prompt } from '@ingka/modal';
import InputField, { InputFieldProps } from '@ingka/input-field';
import React, { useRef, useState } from 'react';
import calendarPath from '@ingka/ssr-icon/paths/calendar';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import StaticDateTimePicker from '@material-ui/lab/StaticDateTimePicker';
import Button from '@ingka/button';
import TextField from '@material-ui/core/TextField';
import { formatISO } from 'date-fns';

export default function DateTimePicker({
  label,
  valid,
  name,
  id,
  validation,
  onChange,
  value,
  req,
}: Pick<InputFieldProps, 'id' | 'label' | 'validation' | 'valid' | 'name' | 'req' | 'value'> & {
  onChange: (date: Date) => unknown;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const setInputValue = (val: string) => {
    const input = inputRef.current;

    if (input) {
      input.value = val;
    }
  };

  return (
    <>
      <InputField
        id={id}
        type="text"
        label={label}
        validation={validation}
        valid={valid}
        name={name}
        iconPosition="leading"
        onClick={() => setIsOpen(true)}
        ssrIcon={calendarPath}
        shouldValidate={!valid}
        readOnly
        req={req}
        value={value}
        onChange={() => {}}
        tabIndex={-1}
        ref={inputRef}
      />

      <Modal visible={isOpen} handleCloseBtn={() => setIsOpen(false)}>
        <Prompt
          title={label}
          footer={(
            <ModalFooter>
              <Button htmlType="button" type="primary" text="Ok" />
            </ModalFooter>
          )}
        >
          <div className="relative">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDateTimePicker
                className="padding--0"
                ampm={false}
                displayStaticWrapperAs="mobile"
                openTo="day"
                onChange={(date: Date | null) => {
                  if (date) {
                    onChange(date);
                    const newValue = formatISO(date, { representation: 'complete' });
                    setInputValue(newValue);
                  }
                }}
                value={value}
                onClose={() => setIsOpen(false)}
                onAccept={() => setIsOpen(false)}
                renderInput={(props) => (
                  <TextField id={props.id} ref={props.ref} inputProps={props.inputProps} />
                )}
              />
            </LocalizationProvider>
          </div>
        </Prompt>
      </Modal>
    </>
  );
}
