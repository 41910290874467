import { Response } from 'miragejs';
import { RouteHandler } from 'miragejs/server';
import Server from './models/Server';

export default function declareNotificationRoutes(this: Server) {
  this.post(
    '/notifications',
    function handler(
      this: RouteHandler<any> & { normalizedRequestAttrs: (type: string) => any },
      schema,
    ) {
      const normalizedAttrs = this.normalizedRequestAttrs('notification');
      return schema.create('notification', normalizedAttrs);
    },
  );

  this.get('/notifications/:id', (schema, request) => {
    const notification = schema.findBy('notification', { id: request.params.id });

    if (notification) {
      return notification;
    }

    return new Response(404);
  });
}
