import User from 'users/models/User';
import { Factory } from 'fishery';

export default Factory.define<User>(({ sequence }) => ({
  id: sequence.toString(),
  email: 'john.doe@example.com',
  nickname: 'jodoe',
  fullName: 'John Doe',
  isActive: true,
  privileges: [],
}));
