import Modal, {
  ModalBody, ModalFooter, ModalHeader, Sheets,
} from '@ingka/modal';
import React, { Dispatch, SetStateAction } from 'react';
import ShowChannel from 'channels/models/ShowChannel';
import CreateNotification from 'channels/models/CreateNotification';
import NotificationPreview from './NotificationPreview';

type Props = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  notification: Partial<Omit<CreateNotification, 'notificationType'>> &
  Pick<CreateNotification, 'notificationType'>;
  channel: ShowChannel;
};

export default function PreviewModal({
  isVisible = false,
  setIsVisible,
  notification,
  channel,
}: Props) {
  return (
    <Modal visible={isVisible} handleCloseBtn={() => setIsVisible(false)}>
      <Sheets size="large" header={<ModalHeader title="Preview" />} footer={<ModalFooter />}>
        <ModalBody>
          <NotificationPreview notification={notification} channel={channel} />
        </ModalBody>
      </Sheets>
    </Modal>
  );
}
