import React, { useState, Dispatch, useEffect } from 'react';
import {
  ColumnSize, Row, Column, Alignment, Stack, Space,
} from 'ui';
import Search from '@ingka/search';
import { Link, useHistory } from 'react-router-dom';
import channelsPath from 'channels/channelsPath';
import useBackendService from 'services/useBackendService';
import ChannelService from 'channels/services/ChannelService';
import newChannelPath from 'channels/newChannelPath';
import _ from 'lodash';
import CategoriesSelect from './CategoriesSelect';
import SearchResult from './SearchResult';
import buildSearchText from '../buildSearchText';
import ChannelsState from '../models/ChannelsState';
import ChannelAction from '../models/ChannelAction';
import selectSearchParams from '../selectSearchParams';

type Props = {
  state: ChannelsState;
  dispatch: Dispatch<ChannelAction>;
};

function Channels({ dispatch, state }: Props) {
  const history = useHistory();
  const { query, isSearchPerformed, latestAction } = state;
  const [searchText, setSearchText] = useState(buildSearchText(query, isSearchPerformed));
  const service = useBackendService(ChannelService);

  useEffect(() => {
    if (['SEARCH', 'TOGGLE_CATEGORY', 'LOAD_MORE', 'INIT'].includes(latestAction)) {
      const params = selectSearchParams(state);
      history.push(channelsPath(params));
      setSearchText(buildSearchText(query, isSearchPerformed));
      service.search(params).then(({ channels: foundChannels, nextPage: page }) => {
        if (latestAction === 'LOAD_MORE') {
          return dispatch({ type: 'APPEND_RESULT', foundChannels, nextPage: page });
        }

        return dispatch({ type: 'SET_RESULT', foundChannels, nextPage: page });
      });
    }
  }, [history, isSearchPerformed, dispatch, query, state, latestAction, service]);

  return (
    <Stack space={Space.Space550}>
      <Row align={Alignment.Center}>
        <Column size={{ m: ColumnSize.ThreeFourths }}>
          <Stack space={Space.Space200}>
            <Row align={Alignment.Center}>
              <Column>
                <h1>Subscribe to relevant information</h1>
              </Column>
            </Row>

            <Search
              id="channel-search"
              ariaLabel="Search among channels"
              onClear={() => dispatch({ type: 'SET_QUERY', query: '' })}
              onChange={(ev) => dispatch({ type: 'SET_QUERY', query: ev.target.value })}
              onSearch={() => dispatch({ type: 'SEARCH' })}
            />

            <CategoriesSelect
              isCategoryActive={(category) => state.categories.includes(category)}
              toggleCategory={(category) => dispatch({ type: 'TOGGLE_CATEGORY', category })}
            />

            <p className="text--align-center">
              Is your channel missing?
              {' '}
              <Link className="link" to={newChannelPath()}>
                Create a new one here.
              </Link>
            </p>
          </Stack>
        </Column>
      </Row>

      <SearchResult
        searchText={searchText}
        channels={state.channels}
        isAllLoaded={_.isNil(state.nextPage)}
        onLoadMore={() => dispatch({ type: 'LOAD_MORE' })}
      />
    </Stack>
  );
}

export default Channels;
