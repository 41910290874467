import MODELS from './MODELS';
import FACTORIES from './FACTORIES';
import SERIALIZERS from './SERIALIZERS';

const SERVER_REGISTRY = {
  factories: FACTORIES,
  models: MODELS,
  serializers: SERIALIZERS,
};

export default SERVER_REGISTRY;
