export default class Device {
  readonly mobileDeviceRegexes = [
    /Android/i,
    /BlackBerry/i,
    /iPhone|iPad|iPod/i,
    /Opera Mini/i,
    /IEMobile/i,
  ];

  constructor(private userAgent: string = window.navigator.userAgent) {}

  get isMobile(): boolean {
    return this.mobileDeviceRegexes.some((regex) => this.userAgent.match(regex));
  }
}
