import Privilege from 'admin/models/Privilege';
import Configuration from 'configuration/models/Configuration';
import BackendClient from 'http/services/BackendClient';
import { deserialize } from 'json-api';
import { JSONAPIDocument } from 'json-api-serializer';

export default class PrivilegeService {
  constructor(private client: BackendClient) {}

  async findAll(): Promise<Privilege[]> {
    return this.client
      .get<JSONAPIDocument>(`${Configuration.get('API_BASE_URL')}/privileges`)
      .then((response) => deserialize('privilege', response.data));
  }
}
