import React from 'react';
import NotificationModel from 'notifications/models/Notification';
import NotificationType from 'notifications/models/NotificationType';
import IssueNotification from './IssueNotification';
import ReleaseNotification from './ReleaseNotification';
import ServiceWindowNotification from './ServiceWindowNotification';
import OtherNotification from './OtherNotification';

type Props = {
  notification: NotificationModel;
};

export default function Notification({ notification }: Props) {
  let neverNotificationType: never;

  switch (notification.notificationType) {
    case NotificationType.Issue:
      return <IssueNotification notification={notification} />;
    case NotificationType.Release:
      return <ReleaseNotification notification={notification} />;
    case NotificationType.ServiceWindow:
      return <ServiceWindowNotification notification={notification} />;
    case NotificationType.OtherInformation:
      return <OtherNotification notification={notification} />;
    default:
      neverNotificationType = notification.notificationType;
      throw new Error(`${neverNotificationType} is not a valid notificationType`);
  }
}
