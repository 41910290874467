import { useState, useEffect } from 'react';

export default function useEffectOnce(callback: () => unknown) {
  const [isExecuted, setIsExecuted] = useState(false);

  useEffect(() => {
    if (isExecuted) {
      return;
    }

    callback();
    setIsExecuted(true);
  }, [callback, isExecuted]);
}
