import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import AuthHook from './models/AuthHook';
import TokenUser from './models/TokenUser';

const useAuth: AuthHook = () => {
  const result = { ...useAuth0() };
  const { user } = result;

  if (_.isNil(user)) {
    return result;
  }

  return {
    ...result,
    user: new TokenUser(user).toUser(),
  };
};

export default useAuth;
