export default function pluralize(text: string, count: number): string {
  if (count === 1) {
    return text;
  }

  const esSuffixes = ['s', 'ss', 'sh', 'ch', 'x', 'z'];
  if (esSuffixes.find((suffix) => text.endsWith(suffix))) {
    return `${text}es`;
  }

  return `${text}s`;
}
