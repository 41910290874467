import REQUIRED_ROLES from 'authorization/REQUIRED_ROLES';
import Footer from 'layouts/components/Footer';
import Table, { TableHeader, TableBody } from '@ingka/table';
import React from 'react';
import { Space, Stack } from 'ui';

export default function AppAccessForbidden() {
  return (
    <>
      <main className="container">
        <Stack space={Space.Space200}>
          <h1>Access forbidden</h1>

          <p>
            You are not allowed to view the application. Please contact support to help you get
            access.
          </p>

          <p>
            To view the application your CDS user will need to belong to at least one of the
            following groups:
          </p>

          <Table>
            <TableHeader>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
            </TableHeader>

            <TableBody>
              {REQUIRED_ROLES.map(([name, description]) => (
                <tr key={name}>
                  <td>{name}</td>
                  <td>{description}</td>
                </tr>
              ))}
            </TableBody>
          </Table>
        </Stack>
      </main>
      <Footer />
    </>
  );
}
