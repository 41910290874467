import React, { useEffect, useState } from 'react';
import { Space, Stack } from 'ui';
import ChannelService from 'channels/services/ChannelService';
import ErrorPage from 'errors/components/ErrorPage';
import useAsyncResource from 'http/useAsyncResource';
import useBackendService from 'services/useBackendService';
import Loading, { LoadingBall } from '@ingka/loading';
import Channel from 'channels/models/Channel';
import ISubscribeInNumbers from './ISubscribeInNumbers';
import TopChannels from './TopChannels';

export default function Dashboard() {
  const channelService = useBackendService(ChannelService);
  const [topChannels, setChannels] = useState<Channel[]>([]);
  const [error, setError] = useState<Error | undefined>(undefined);
  const shuffle = (arr: any) => [...arr].sort(() => Math.random() - 0.5);
  const [counter, counterError] = useAsyncResource(() => channelService.countDataForDashboard());

  useEffect(() => {
    channelService.findTopChannelsList()
      .then((response) => {
        const newList = shuffle(response);
        setChannels(newList);
      }).catch((e) => {
        setError(e);
      });
  }, [channelService]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (counterError) {
    return <ErrorPage error={counterError} />;
  }

  if (counter && topChannels) {
    return (
      <Stack space={Space.Space1000}>
        <h1 className="text--align-center">iSubscribe information dashboard</h1>
        <h2 className="text--align-center">iSubscribe in numbers</h2>
        <ISubscribeInNumbers
          channelsCount={counter.channelCount}
          usersCount={counter.userCount}
          notificationsCount={counter.notificationCount}
        />
        <TopChannels channels={topChannels} />
      </Stack>
    );
  }
  return (
    <Loading text="Loading...">
      <LoadingBall />
    </Loading>
  );
}
