import React from 'react';
import ErrorPage from 'errors/components/ErrorPage';
import useAsyncResource from 'http/useAsyncResource';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RegionService from 'regions/services/RegionService';
import useBackendService from 'services/useBackendService';
import CountryRouter from 'admin/components/countryManagement/CountryRouter';
import AddCountry from 'admin/components/countryManagement/AddCountry';
import UserService from 'users/services/UserService';
import UserContextType from 'admin/models/UserContextType';
import Loading, { LoadingBall } from '@ingka/loading';
import AccessForbidden from '../AccessForbidden';
import CountriesTable from './CountriesTable';

export default function RigionsRouter() {
  const match = useRouteMatch<{ id: string }>('/admin/regions/:id');
  const { id } = match?.params ?? { id: '' };
  const regionervice = useBackendService(RegionService);
  const [region, error] = useAsyncResource(async () => regionervice.findById(id));
  const userService = useBackendService(UserService);
  const [user, userError] = useAsyncResource(() => userService.findCurrentUser());
  const hasPermission = user?.privileges
    .some((p) => p.userContextType === UserContextType.Countries);

  if (error) {
    return <ErrorPage error={error} />;
  }
  if (userError) {
    return <ErrorPage error={userError} />;
  }
  if (user && !hasPermission) {
    return (<AccessForbidden />);
  }

  if (region) {
    return (
      <Switch>

        <Route exact path={`${match?.path}`}>
          <CountriesTable region={region} />
        </Route>
        <Route path={`${match?.path}/new`}>
          <AddCountry region={region} />
        </Route>
        <Route path={`${match?.path}/:id`}>
          <CountryRouter />
        </Route>

      </Switch>
    );
  }

  return (
    <Loading text="Loading...">
      <LoadingBall />
    </Loading>
  );
}
