import Configuration from 'configuration/models/Configuration';
import BackendClient from 'http/services/BackendClient';
import { deserialize, serialize } from 'json-api';
import { JSONAPIDocument } from 'json-api-serializer';
import Country from 'regions/models/Country';
import ShowRegion from 'regions/models/ShowRegion';

export default class CountryService {
  constructor(private client: BackendClient) {}

  async create(country: Country): Promise<ShowRegion> {
    const url = `${Configuration.get('API_BASE_URL')}/countries/${country.regionId}`;

    return this.client
      .post<JSONAPIDocument>(url, serialize('country', country))
      .then((response) => deserialize('country', response.data));
  }

  async delete(id: string): Promise<ShowRegion> {
    const url = `${Configuration.get('API_BASE_URL')}/countries/${id}`;

    return this.client
      .delete<JSONAPIDocument>(url)
      .then((response) => deserialize('country', response.data));
  }

  async findById(id: string): Promise<Country> {
    const url = `${Configuration.get('API_BASE_URL')}/countries/${id}`;

    return this.client
      .get<JSONAPIDocument>(url)
      .then((response) => deserialize<Country>('country', response.data));
  }
}
