import useAuth from 'authentication/useAuth';
import TermsOfUse from 'termsOfUse/components/TermsOfUse';
import TermsOfUseAgreement from 'termsOfUse/models/TermsOfUseAgreement';
import React, { ReactNode, useEffect, useState } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import isTermsOfUseAccepted from 'termsOfUse/isTermsOfUseAccepted';
import useBackendService from 'services/useBackendService';
import TermsOfUseService from 'termsOfUse/services/TermsOfUseService';
import ErrorPage from 'errors/components/ErrorPage';
import _ from 'lodash';
import HttpStatusCode from 'http/HttpStatusCode';
import AppAccessForbidden from 'authorization/components/AppAccessForbidden';

type Props = {
  children: ReactNode;
};

export default function TermsOfUseGuard({ children }: Props) {
  const { logout } = useAuth();
  const [fetchState, setFetchState] = useState('INIT');
  const [error, setError] = useState<Error | undefined>(undefined);
  const [agreements, setAgreements] = useState<TermsOfUseAgreement[]>([]);
  const termsService = useBackendService(TermsOfUseService);

  useEffect(() => {
    if (fetchState === 'INIT') {
      setFetchState('FETCHING');
      termsService
        .findAll()
        .then((ags) => {
          setAgreements(ags);
          return setFetchState('FETCHED');
        })
        .catch((err) => setError(err));
    }
  }, [agreements, fetchState, termsService]);

  if (error) {
    if (_.get(error, ['response', 'status']) === HttpStatusCode.Forbidden) {
      return <AppAccessForbidden />;
    }

    return <ErrorPage error={error} />;
  }

  if (fetchState === 'FETCHING') {
    return (
      <Loading text="Loading...">
        <LoadingBall />
      </Loading>
    );
  }

  if (isTermsOfUseAccepted(agreements)) {
    return <>{children}</>;
  }

  return <TermsOfUse setAgreements={setAgreements} agreements={agreements} logout={logout} />;
}
