import Button from '@ingka/button';
import adminPagePath from 'admin/services/adminPagePath';
import ShowChannel from 'channels/models/ShowChannel';
import ChannelService from 'channels/services/ChannelService';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useBackendService from 'services/useBackendService';
import { Inline } from 'ui';
import Space from 'ui/models/Space';

export default function DeleteChannel({
  channel,
}: {
  channel: ShowChannel;
}) {
  const channelService = useBackendService(ChannelService);
  const { id } = channel;
  const history = useHistory();
  const [state, setState] = useState<'IDLE' | 'SENDING'>('IDLE');
  const isLoading = state === 'SENDING';

  const [redirectPath, setRedirectPath] = useState<any | string>();
  if (redirectPath) {
    return (
      <Redirect
        to={{ pathname: redirectPath, state: { toastMessage: 'Channel deleted successfully!' } }}
      />
    );
  }
  function onDelete() {
    setState('SENDING');
    channelService.delete(id)
      .then(() => {
        setState('IDLE');
        setRedirectPath(adminPagePath());
      })
      .catch(() => {

      });
  }

  return (

    <>
      <div>
        <h1>
          Deleting channel &quot;
          {channel.name}
          &quot;
        </h1>
        <p>Are you sure that you want to delete this channel?</p>
        <hr />
      </div>
      <Inline space={Space.Space100}>
        <Button text="Cancel" htmlType="button" onClick={() => history.goBack()} />
        <Button
          type="danger"
          text="Delete"
          loading={isLoading}
          onClick={onDelete}
        />
      </Inline>
    </>
  );
}
