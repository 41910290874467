import PropTypes from 'prop-types';
import React, { ReactChild } from 'react';
import ColumnSize from '../models/ColumnSize';
import Breakpoint from '../models/Breakpoint';

type Props = {
  children: ReactChild | ReactChild[];
  size?: {
    [key in Breakpoint]?: ColumnSize;
  };
};

const Column = ({ children, size = {} }: Props) => {
  const className = Object.entries(size)
    .map(([key, colSize]) => `column--${key}-${colSize}`)
    .join(' ');

  return <div className={className}>{children}</div>;
};

Column.defaultProps = {
  children: [],
  size: {},
};

Column.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Column;
