enum Color {
  White = '#FFFFFF',
  Grey100 = '#F5F5F5',
  Grey200 = '#DFDFDF',
  Grey500 = '#929292',
  Grey700 = '#484848',
  Grey900 = '#111111',
  BrandBlue = '#0058A3',
  BrandYellow = '#FFDB00',
}

export default Color;
