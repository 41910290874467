import Configuration from 'configuration/models/Configuration';
import { JSONAPIDocument } from 'json-api-serializer';
import { deserialize, serialize } from 'json-api';
import TermsOfUseAgreement from 'termsOfUse/models/TermsOfUseAgreement';
import TermsOfUseAgreementDto from 'termsOfUse/models/TermsOfUseAgreementDto';
import BackendClient from 'http/services/BackendClient';

export default class TermsOfUseService {
  constructor(private client: BackendClient) {}

  async create(
    agreement: Omit<TermsOfUseAgreement, 'id' | 'createdAt'>,
  ): Promise<TermsOfUseAgreement> {
    const url = `${Configuration.get('API_BASE_URL')}/terms-of-use-agreements`;

    return this.client
      .post<JSONAPIDocument>(url, serialize('termsOfUseAgreement', agreement))
      .then((response) => deserialize<TermsOfUseAgreementDto>('termsOfUseAgreement', response.data))
      .then((data) => new TermsOfUseAgreementDto(data).deserialize());
  }

  async findAll(): Promise<TermsOfUseAgreement[]> {
    const url = `${Configuration.get('API_BASE_URL')}/terms-of-use-agreements`;

    return this.client
      .get<JSONAPIDocument>(url)
      .then((response) => deserialize<TermsOfUseAgreementDto[]>('termsOfUseAgreement', response.data))
      .then((agreements) => agreements.map((a) => new TermsOfUseAgreementDto(a).deserialize()));
  }
}
