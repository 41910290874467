import Configuration from 'configuration/models/Configuration';
import BackendClient from 'http/services/BackendClient';
import Notification from 'notifications/models/Notification';
import { JSONAPIDocument } from 'json-api-serializer';
import deserialize from 'json-api/deserialize';
import serialize from 'json-api/serialize';
import CreateNotification from 'channels/models/CreateNotification';
import Channel from 'channels/models/Channel';
import PaginatedNotifications from 'notifications/models/PaginatedNotifications';
import NotificationDto from 'notifications/models/NotificationDto';
import reactHtmlParser from 'react-html-parser';
import ReactDOMServer from 'react-dom/server';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';

export default class NotificationService {
  constructor(private client: BackendClient) {}

  async findOne(id: string): Promise<Notification> {
    const url = `${Configuration.get('API_BASE_URL')}/notifications/${id}`;
    return this.client
      .get<JSONAPIDocument>(url)
      .then((response) => NotificationService.deserializeNotification(response.data));
  }

  async countForChannel(channel: Channel): Promise<number> {
    const url = `${Configuration.get('API_BASE_URL')}/channels/${channel.id}/count-notifications`;
    return this.client
      .get<number>(url)
      .then((response) => response.data);
  }

  async createAndSend(notification: CreateNotification): Promise<Notification> {
    const url = `${Configuration.get('API_BASE_URL')}/notifications`;
    const allowedTags = ['p', 'a', 'b', 'i', 'em', 'strong', 'br', 'ul', 'ol', 'li', 'sup', 'sub', 'h1', 'h2', 'h3', 'h4', 's', 'u'];
    const config = {
      ALLOWED_TAGS: allowedTags,
    };
    const purifiedDescription = DOMPurify.sanitize(notification.description, config);
    const finalDescription = (() => {
      let modifiedDescription = purifiedDescription;
      modifiedDescription = modifiedDescription.replace(/<li class="ql-indent-(\d+)">(.*?)<\/li>/g,
        (match, p1, p2) => {
          const indentLevel = parseInt(p1, 10);
          const paddingLeft = indentLevel === 1 ? '0' : `${30 * (indentLevel - 1)}px`;
          return `<ul><li style="list-style-type: disc; list-style-position: inside; padding-left: ${paddingLeft}; margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">${p2}</li></ul>`;
        });
      for (let indentLevel = 1; indentLevel <= 3; indentLevel += 1) {
        const ulPattern = new RegExp(`(<li class="ql-indent-${indentLevel}".*?>.*?</li>)(?=<li class="ql-indent-${indentLevel}".*?>|<li class="ql-indent-${indentLevel + 1}".*?>|$)`, 'g');
        modifiedDescription = modifiedDescription
          .replace(ulPattern, (match) => `<ul style="padding-left: ${40 * (indentLevel - 1)}px;">${match}</ul>`);
      }
      modifiedDescription = modifiedDescription
        .replace(/<p>/g, '<p style="margin: 0; padding: 0">')
        .replace(/<p class="ql-align-center">(.*?)<\/p>/g, '<center style="margin: 0; margin-top: 0;margin-bottom: 0; padding-bottom:0; padding-top: 0;">$1</center>')
        .replace(/<p class="ql-align-right">(.*?)<\/p>/g, '<p style="text-align: right;margin: 0; padding: 0;">$1</p>')
        .replace(/<p class="ql-align-justify">(.*?)<\/p>/g, '<p style="text-align: justify;margin: 0; padding: 0;">$1</p>')
        .replace(/<p class="ql-indent-(\d+)">(.*?)<\/p>/g,
          (match, p1, p2) => {
            const indentLevel = parseInt(p1, 10);
            const paddingLeft = `${40 * indentLevel}px`;
            return `<p style="padding-left: ${paddingLeft};margin-top: 0; padding-top: 0; margin-bottom:0; padding-bottom:0;">${p2}</p>`;
          })
        .replace(/<strong(?!.*?<\/strong>)/g, '<strong style="margin: 0;">')
        .replace(/<(\w+)([^>]*)>/g, '<$1$2 style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">');
      return modifiedDescription;
    })();
    const parsedDescription = reactHtmlParser(finalDescription);
    for (let i = parsedDescription.length - 1; i > 0; i -= 1) {
      if (parsedDescription[i].props.children[0].type === 'br') delete parsedDescription[i];
      else {
        break;
      }
    }
    const htmlString = ReactDOMServer.renderToStaticMarkup(parsedDescription as any);
    const updatedNotification = {
      ...notification,
      description: htmlString,
    };
    return this.client
      .post<JSONAPIDocument>(url, serialize('notification', updatedNotification))
      .then((response) => NotificationService.deserializeNotification(response.data));
  }

  async update(notification: CreateNotification & { id: string }): Promise<Notification> {
    const allowedTags = ['p', 'a', 'b', 'i', 'em', 'strong', 'br', 'ul', 'ol', 'li', 'sup', 'sub', 'h1', 'h2', 'h3', 'h4', 's', 'u'];
    const config = {
      ALLOWED_TAGS: allowedTags,
    };
    const purifiedDescription = DOMPurify.sanitize(notification.description, config);
    const finalDescription = (() => {
      let modifiedDescription = purifiedDescription;
      modifiedDescription = modifiedDescription.replace(/<li class="ql-indent-(\d+)">(.*?)<\/li>/g,
        (match, p1, p2) => {
          const indentLevel = parseInt(p1, 10);
          const paddingLeft = indentLevel === 1 ? '0' : `${30 * (indentLevel - 1)}px`;
          return `<ul><li style="list-style-type: disc; list-style-position: inside; padding-left: ${paddingLeft}; margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">${p2}</li></ul>`;
        });
      for (let indentLevel = 1; indentLevel <= 3; indentLevel += 1) {
        const ulPattern = new RegExp(`(<li class="ql-indent-${indentLevel}".*?>.*?</li>)(?=<li class="ql-indent-${indentLevel}".*?>|<li class="ql-indent-${indentLevel + 1}".*?>|$)`, 'g');
        modifiedDescription = modifiedDescription
          .replace(ulPattern, (match) => `<ul style="padding-left: ${40 * (indentLevel - 1)}px;">${match}</ul>`);
      }
      modifiedDescription = modifiedDescription
        .replace(/<p>/g, '<p style="margin: 0; padding: 0">')
        .replace(/<p class="ql-align-center">(.*?)<\/p>/g, '<center style="margin: 0; margin-top: 0;margin-bottom: 0; padding-bottom:0; padding-top: 0;">$1</center>')
        .replace(/<p class="ql-align-right">(.*?)<\/p>/g, '<p style="text-align: right;margin: 0; padding: 0;">$1</p>')
        .replace(/<p class="ql-align-justify">(.*?)<\/p>/g, '<p style="text-align: justify;margin: 0; padding: 0;">$1</p>')
        .replace(/<p class="ql-indent-(\d+)">(.*?)<\/p>/g,
          (match, p1, p2) => {
            const indentLevel = parseInt(p1, 10);
            const paddingLeft = `${40 * indentLevel}px`;
            return `<p style="padding-left: ${paddingLeft};margin-top: 0; padding-top: 0; margin-bottom:0; padding-bottom:0;">${p2}</p>`;
          })
        .replace(/<strong(?!.*?<\/strong>)/g, '<strong style="margin: 0;">')
        .replace(/<(\w+)([^>]*)>/g, '<$1$2 style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">');
      return modifiedDescription;
    })();
    const parsedDescription = reactHtmlParser(finalDescription);
    for (let i = parsedDescription.length - 1; i > 0; i -= 1) {
      if (parsedDescription[i].props.children[0].type === 'br') delete parsedDescription[i];
      else {
        break;
      }
    }
    const htmlString = ReactDOMServer.renderToStaticMarkup(parsedDescription as any);
    const updatedNotification = {
      ...notification,
      description: htmlString,
    };
    const url = `${Configuration.get('API_BASE_URL')}/notifications/${notification.id}`;
    return this.client
      .put<JSONAPIDocument>(url, serialize('notification', updatedNotification))
      .then((response) => NotificationService.deserializeNotification(response.data));
  }

  async findForChannel(channel: Channel, nextPage?: string): Promise<PaginatedNotifications> {
    if (nextPage) {
      return this.client.get<JSONAPIDocument>(nextPage).then((response) => ({
        nextPage: response.data?.links?.next as string | undefined,
        notifications: NotificationService.deserializeNotifications(response.data),
      }));
    }

    const url = `${Configuration.get('API_BASE_URL')}/channels/${channel.id}/notifications`;
    return this.client.get<JSONAPIDocument>(url).then((response) => ({
      nextPage: response.data?.links?.next as string | undefined,
      notifications: NotificationService.deserializeNotifications(response.data),
    }));
  }

  async findAllOpenForUser(): Promise<Notification[]> {
    const url = `${Configuration.get('API_BASE_URL')}/user/open-notifications`;
    return this.client
      .get<JSONAPIDocument>(url)
      .then((response) => NotificationService.deserializeNotifications(response.data));
  }

  async delete(id: string): Promise<Notification> {
    const url = `${Configuration.get('API_BASE_URL')}/notifications/${id}`;

    return this.client
      .delete<JSONAPIDocument>(url)
      .then((response) => deserialize('notification', response.data));
  }

  static deserializeNotifications(data: JSONAPIDocument): Notification[] {
    return deserialize<NotificationDto[]>('notification', data).map((dto) => new NotificationDto(dto).deserialize());
  }

  static deserializeNotification(data: JSONAPIDocument): Notification {
    const dto = deserialize<NotificationDto>('notification', data);
    return new NotificationDto(dto).deserialize();
  }
}
