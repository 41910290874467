import _ from 'lodash';
import TermsOfUseAgreement from './models/TermsOfUseAgreement';

export default function isTermsOfUseAccepted(agreements: TermsOfUseAgreement[]): boolean {
  if (_.isEmpty(agreements)) {
    return false;
  }

  const lastAgreement = _.last(_.sortBy(agreements, (agreement) => agreement.createdAt));

  return lastAgreement?.decision === 'accepted';
}
