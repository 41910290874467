import Button from '@ingka/button';
import React from 'react';
import smallBanner from 'assets/isubscribe-banner-small.svg';
import logo from 'assets/ikea-logo-white.svg';
import largeBanner from 'assets/isubscribe-banner-large.svg';
import mediumBanner from 'assets/isubscribe-banner-medium.svg';
import Proptypes from 'prop-types';
import LoginWithRedirectFunc from 'authentication/models/LoginWithRedirectFunc';
import {
  Row, Alignment, Column, ColumnSize, Stack, Space,
} from 'ui';

type Props = { loginWithRedirect: LoginWithRedirectFunc };

export default function Landing({ loginWithRedirect }: Props) {
  return (
    <div className="relative height--100vh background--brand-blue">
      <picture className="width--100 fixed--bottom-right" style={{ position: 'fixed' }}>
        <source srcSet={largeBanner} media="(min-width: 900px)" />
        <source srcSet={mediumBanner} media="(min-width: 600px)" />
        <img
          className="display--block"
          src={smallBanner}
          alt="Letter containing channel subjects"
        />
      </picture>

      <main className="width--100 padding--y-1000 padding--x-550">
        <Row align={Alignment.Start}>
          <Column
            size={{
              l: ColumnSize.Half,
              xl: ColumnSize.Third,
            }}
          >
            <Stack space={Space.Space150}>
              <img src={logo} alt="IKEA Logo" className="width--space-550" />

              <Stack space={Space.Space75}>
                <h1 className="text--white">Welcome to iSubscribe</h1>
                <p className="text--white">
                  Subscribe to work related information and personalise the way you are receiving
                  notifications.
                </p>
              </Stack>
              <Button type="image-overlay" text="Sign in" onClick={() => loginWithRedirect()} />
            </Stack>
          </Column>
        </Row>
      </main>
    </div>
  );
}

Landing.propTypes = {
  loginWithRedirect: Proptypes.func.isRequired,
};
