import Privilege from 'admin/models/Privilege';
import User from 'users/models/User';

const NETWORK_ID_PROPERTY = 'http://accounts.ikea.com/networkid';

export default class TokenUser {
  email: string;

  family_name: string;

  given_name: string;

  name: string;

  nickname: string;

  picture: string;

  sub: string;

  privileges: Privilege[];

  isActive: boolean;

  [NETWORK_ID_PROPERTY]: string;

  updated_at: string;

  constructor({
    email,
    family_name,
    given_name,
    name,
    nickname,
    picture,
    sub,
    updated_at,
    [NETWORK_ID_PROPERTY]: networkId,
    privileges,
    isActive,
  }: Omit<TokenUser, 'toUser'>) {
    this.email = email;
    this.family_name = family_name;
    this.given_name = given_name;
    this.name = name;
    this.nickname = nickname;
    this.picture = picture;
    this.sub = sub;
    this.updated_at = updated_at;
    this[NETWORK_ID_PROPERTY] = networkId;
    this.privileges = privileges;
    this.isActive = isActive;
  }

  toUser(): User {
    return new User({
      email: this.email,
      nickname: this.nickname,
      fullName: this.name,
      id: this[NETWORK_ID_PROPERTY],
      privileges: this.privileges,
      isActive: this.isActive,
    });
  }
}
