import React from 'react';
import NavigationBar from 'navigation/components/NavigationBar';
import { Space, Stack } from 'ui';
import Footer from './Footer';

export default function DefaultLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <main className="container">
        <Stack space={Space.Space150}>

          <NavigationBar />
          <div>{children}</div>

        </Stack>
      </main>

      <Footer />
    </>
  );
}
