import React from 'react';
import pluralize from 'text/pluralize';
import Button from '@ingka/button';
import channelUrl from 'channels/channelUrl';
import linkIconPath from '@ingka/ssr-icon/paths/link';
import arrowLeftIconPath from '@ingka/ssr-icon/paths/arrow-left';
import copyToClipboard from 'clipboard/copyToClipboard';
import { useHistory } from 'react-router-dom';
import {
  Column, ColumnSize, Inline, Row, Space, Stack,
} from 'ui';
import { User } from '@auth0/auth0-react/dist/auth-state';
import ButtonLink from 'ui/components/ButtonLink';
import channelNotificationsPath from 'channels/channelNotificationsPath';
import ChannelSubscription from 'channels/models/ChannelSubscription';
import { FiUsers } from 'react-icons/fi';
import { MdOutlineFeaturedPlayList } from 'react-icons/md';
import NotificationService from 'notifications/services/NotificationService';
import useAsyncResource from 'http/useAsyncResource';
import useBackendService from 'services/useBackendService';
import ErrorPage from 'errors/components/ErrorPage';
import OwnerBanner from './OwnerBanner';
import SubscriptionForm from './SubscriptionForm';
import ChannelModel from '../models/ShowChannel';

export default function Channel({
  channel,
  owners,
  user,
  subscription,
  setSubscription,
}: {
  channel: ChannelModel;
  user: User;
  owners: User[];
  subscription: ChannelSubscription;
  setSubscription: React.Dispatch<React.SetStateAction<any>>;
}) {
  const history = useHistory();
  const ownerText = `Owners: ${owners.map((owner) => owner.email).join(', ')}`;
  const notificationService = useBackendService(NotificationService);
  const [notificationCounter, counterError] = useAsyncResource(
    () => notificationService.countForChannel(channel),
  );
  if (counterError) {
    return <ErrorPage error={counterError} />;
  }
  return (
    <>
      <Stack space={Space.Space200}>
        <Inline>
          <Button onClick={() => history.goBack()} type="tertiary" ssrIcon={arrowLeftIconPath} />
        </Inline>

        <Stack space={Space.Space550}>
          <OwnerBanner channel={channel} owners={owners} user={user} />

          <Row space={Space.Space400}>
            <Column size={{ m: ColumnSize.Half }}>
              <Stack space={Space.Space150}>
                <img
                  src={channel.thumbnailUrl}
                  alt={`Thumbnail for ${channel.name}`}
                  className="width--100"
                />
                <Inline space={Space.Space300}>
                  <div className="content">
                    <FiUsers size={30} />
                    <div className="text-content">
                      {channel.subscriberCount}
                      {' '}
                      {pluralize('subscriber', channel.subscriberCount)}
                    </div>
                  </div>
                  <div className="content">
                    <MdOutlineFeaturedPlayList size={30} />
                    <div className="text-content">
                      {notificationCounter}
                      {' '}
                      {pluralize('notification', notificationCounter || 0)}
                    </div>
                  </div>
                </Inline>
                <h1>{channel.name}</h1>

                <p className="carriage-return">{channel.description}</p>
                <p>{ownerText}</p>
                <Button
                  iconOnly
                  text="Copy Channel URL"
                  onClick={() => copyToClipboard(channelUrl(channel))}
                  type="primary"
                  ssrIcon={linkIconPath}
                  expanding
                />
              </Stack>
            </Column>

            <Column size={{ m: ColumnSize.Half }}>
              <Stack space={Space.Space400}>
                <SubscriptionForm
                  subscription={subscription}
                  setSubscription={setSubscription}
                  channel={channel}
                />
                <ButtonLink
                  text="View Notification Timeline"
                  to={channelNotificationsPath(channel)}
                  type="secondary"
                />
              </Stack>
            </Column>
          </Row>
        </Stack>
      </Stack>
    </>
  );
}
