enum ColorName {
  White = 'white',
  BrandBlue = 'brand-blue',
  NotificationTypeOther = 'notification-type-other',
  NotificationTypeRelease = 'notification-type-release',
  NotificationTypeServiceWindow = 'notification-type-service-window',
  NotificationTypeIssue = 'notification-type-issue',
  FeedbackError = 'feedback-error',
  Grey100 = 'grey-100',
  Grey200 = 'grey-200',
  Grey300 = 'grey-300',
  Grey500 = 'grey-500',
  Grey700 = 'grey-700',
  Grey900 = 'grey-900',
}

export default ColorName;
