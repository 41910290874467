import React, { ReactNode } from 'react';
import { Space } from 'ui';
import Stack from 'ui/components/Stack';

export default function NotificationAttribute({
  children,
  label,
}: {
  children: ReactNode;
  label: string;
}) {
  return (
    <Stack space={Space.Space75}>
      <p className="text--grey-500">{label}</p>
      {children}
    </Stack>
  );
}
