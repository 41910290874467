import Category from 'channels/models/Category';
import Channel from 'channels/models/Channel';
import Configuration from 'configuration/models/Configuration';
import { Response } from 'miragejs';
import SortOrder from 'search/models/SortOrder';
import { RouteHandler } from 'miragejs/server';
import Server from './models/Server';

export default function declareChannelRoutes(this: Server) {
  this.put(
    '/channels/:id/subscription',
    function handler(
      this: RouteHandler<any> & { normalizedRequestAttrs: (type: string) => any },
      schema,
      request,
    ) {
      const channel = schema.findBy('channel', { id: request.params.id });
      const subscription = schema.findOrCreateBy('subscription', { channelId: channel?.id });
      subscription.update(this.normalizedRequestAttrs('subscription'));
      const subscriberCount = schema.where('subscription', {
        isSubscribed: true,
        channelId: channel?.id,
      }).length;
      channel?.update({ subscriberCount });
      return subscription;
    },
  );

  this.put(
    '/channels/:id',
    function handler(
      this: RouteHandler<any> & { normalizedRequestAttrs: (type: string) => any },
      schema,
      request,
    ) {
      const channel = schema.findBy('channel', { id: request.params.id });

      if (channel) {
        channel?.update(this.normalizedRequestAttrs('channel'));
        return channel;
      }

      return new Response(404);
    },
  );

  this.get(
    '/channels/:id/notifications',

    function handler(
      this: RouteHandler<any> & { serialize: (resource: any) => any },
      schema,
      request,
    ) {
      const { id } = request.params;
      const channel = schema.findBy('channel', { id });
      if (!channel) {
        return new Response(404);
      }

      const pageSize = 5;
      const notifications = channel?.notifications ?? [];
      const page = parseInt(request.queryParams['page[number]'] ?? '1', 10);
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const paginatedNotifications = (notifications as any).slice(startIndex, endIndex);
      const json = this.serialize(paginatedNotifications);

      return {
        ...json,
        links: {
          next:
            endIndex <= channel?.notifications.length - 1
              ? `${Configuration.get('API_BASE_URL')}/channels/${id}/notifications?page[number]=${
                page + 1
              }`
              : undefined,
        },
      };
    },
  );

  this.get('/channels/:id', (schema, request) => {
    const channel = schema.findBy('channel', { id: request.params.id });

    if (channel) {
      return channel;
    }

    return new Response(404);
  });

  this.post(
    '/channels',
    function handler(
      this: RouteHandler<any> & { normalizedRequestAttrs: (type: string) => any },
      schema,
    ) {
      const normalizedAttrs = this.normalizedRequestAttrs('channel');
      if (schema.findBy('channel', { name: normalizedAttrs.name })) {
        return new Response(
          400,
          {},
          {
            errors: [
              {
                detail: `The name '${normalizedAttrs.name}' is occupied.`,
                source: {
                  pointer: '/data/attributes/name',
                },
              },
            ],
          },
        );
      }

      return schema.create('channel', normalizedAttrs);
    },
  );

  this.get(
    '/channels',
    function handler(
      this: RouteHandler<any> & { serialize: (resource: any) => any },
      schema,
      request,
    ) {
      const pageSize = 9;
      const page = parseInt(request.queryParams['page[number]'] ?? '1', 10);
      const query = request.queryParams.query ?? '';
      const categories = JSON.parse(request.queryParams['filter[categories]'] ?? '[]');
      const pageIndex = page - 1;
      const startIndex = pageIndex * pageSize;
      const endIndex = startIndex + pageSize;
      const sortBy: keyof Channel = (request.queryParams.sortBy as keyof Channel) ?? ('name' as const);
      const { sortOrder = SortOrder.Ascending } = request.queryParams;

      /*
       * The types of miragejs does not include the sort method although it
       * is there. Casting as any until it is fixed.
       */
      const channels = (schema.where(
        'channel',
        (channel: Channel) => channel.name.match(new RegExp(query, 'i'))
          && categories.every((cat: Category) => channel.categories.includes(cat)),
      ) as any)
        ?.sort((first: Channel, second: Channel) => {
          const sortFuncs = {
            [SortOrder.Ascending]: (a: any, b: any) => (b[sortBy] < a[sortBy] ? 1 : -1),
            [SortOrder.Descending]: (a: any, b: any) => (b[sortBy] > a[sortBy] ? 1 : -1),
          };

          if (first[sortBy].toString() === second[sortBy].toString()) {
            return 0;
          }

          return sortFuncs[sortOrder as SortOrder](first, second);
        })
        ?.slice(startIndex, endIndex);

      const json = this.serialize(channels.slice(channels ?? []));

      return {
        ...json,
        links: {
          next:
            endIndex <= schema.all('channel').length - 1
              ? `${Configuration.get('API_BASE_URL')}/channels?page[number]=${
                page + 1
              }&sortBy=${sortBy}&sortOrder=${sortOrder}&query=${query}&filter[categories]=${
                request.queryParams['filter[categories]']
              }`
              : undefined,
        },
      };
    },
  );
}
