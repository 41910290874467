import Privilege from 'admin/models/Privilege';

export default class User {
  id: string;

  email: string;

  fullName: string;

  nickname: string;

  isActive: boolean;

  privileges: Privilege[];

  createdAt?: Date;

  constructor(properties: User) {
    this.id = properties.id;

    this.email = properties.email;

    this.fullName = properties.fullName;

    this.nickname = properties.nickname;

    this.privileges = properties.privileges;

    this.isActive = properties.isActive;

    this.createdAt = properties.createdAt;
  }
}
