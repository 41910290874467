import React, { Dispatch, useEffect, useState } from 'react';
import useBackendService from 'services/useBackendService';
import { Space, Stack } from 'ui';
import ChannelService from 'channels/services/ChannelService';
import buildSearchText from 'channels/buildSearchText';
import selectSearchParams from 'channels/selectSearchParams';
import { useHistory } from 'react-router-dom';
import ChannelsState from 'channels/models/ChannelsState';
import ChannelAction from 'channels/models/ChannelAction';
import Search from '@ingka/search';
import _ from 'lodash';
import UserSelectSearchParams from 'users/models/UserSelectSearchParams';
import CategoriesSelect from 'channels/components/CategoriesSelect';
import adminPagePath from 'admin/services/adminPagePath';
import UserState from 'profile/models/UserState';
import ChannelSearchResult from './ChannelSearchResult';

type Props = {
  state: ChannelsState;
  dispatch: Dispatch<ChannelAction>;
  userState: UserState;
};

export default function ChannelsTable({ dispatch, state, userState }: Props) {
  const history = useHistory();
  const { query, isSearchPerformed, latestAction } = state;
  const [searchText, setSearchText] = useState(
    buildSearchText(query, isSearchPerformed),
  );
  const service = useBackendService(ChannelService);

  useEffect(() => {
    if (['SEARCH', 'TOGGLE_CATEGORY', 'TOGGLE_VISIBILITY', 'LOAD_MORE', 'INIT'].includes(latestAction)) {
      const params = selectSearchParams(state);
      const userParams = UserSelectSearchParams(userState);
      history.push(adminPagePath(params, userParams));
      setSearchText(buildSearchText(query, isSearchPerformed));
      service.search(params).then(({ channels: foundChannels, nextPage: page }) => {
        if (latestAction === 'LOAD_MORE') {
          return dispatch({ type: 'APPEND_RESULT', foundChannels, nextPage: page });
        }

        return dispatch({ type: 'SET_RESULT', foundChannels, nextPage: page });
      });
    }
  }, [history, isSearchPerformed, query, dispatch, state, userState, latestAction, service]);

  return (
    <Stack space={Space.Space200}>
      <Stack space={Space.Space550}>
        <Search
          id="channels-search"
          ariaLabel="Search among channels"
          onClear={() => dispatch({ type: 'SET_QUERY', query: '' })}
          onChange={(ev) => dispatch({ type: 'SET_QUERY', query: ev.target.value })}
          onSearch={() => dispatch({ type: 'SEARCH' })}
        />
        <CategoriesSelect
          isCategoryActive={(category) => state.categories.includes(category)}
          toggleCategory={(category) => dispatch({ type: 'TOGGLE_CATEGORY', category })}
        />

        <ChannelSearchResult
          searchText={searchText}
          channels={state.channels}
          isAllLoaded={_.isNil(state.nextPage)}
          onLoadMore={() => dispatch({ type: 'LOAD_MORE' })}
          state={state}
          dispatch={dispatch}
        />
      </Stack>
    </Stack>

  );
}
