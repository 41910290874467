import CreateNotification from 'channels/models/CreateNotification';
import ShowChannel from 'channels/models/ShowChannel';
import PreviewNotification from './models/PreviewNotification';
import toFriendlyNotificationStatus from './toFriendlyNotificationStatus';

const formatDate = (datestamp: string) => new Date(datestamp).toUTCString();
const KEY_FORMATTERS: { [key: string]: (value: any) => string | string[] } = {
  startsAt: formatDate,
  endsAt: formatDate,
  releasedAt: formatDate,
  status: toFriendlyNotificationStatus,
  default: (value: any) => value,
};

export default function toPreviewNotification(
  notification: Partial<CreateNotification>,
  channel: ShowChannel,
): PreviewNotification {
  const defaultProperties = {
    channelName: channel.name,
    createdAt: new Date().toUTCString(),
    countryCodes: [],
  };
  return Object.entries(notification).reduce((newNotification, [key, value]) => {
    const formatValue = KEY_FORMATTERS[key] ?? KEY_FORMATTERS.default;

    if (value) {
      return { ...newNotification, [key]: formatValue(value) };
    }

    return { ...newNotification, [key]: value };
  }, defaultProperties);
}
