import React, { useState } from 'react';
import InlineMessage, { InlineMessageVariant } from '@ingka/inline-message';
import NotificationModel from 'notifications/models/Notification';
import { Redirect } from 'react-router-dom';
import editNotificationPath from 'notifications/editNotificationPath';
import newSubNotificationPath from 'notifications/newSubNotificationPath';

type Props = {
  notification: NotificationModel;
  type: InlineMessageVariant
};

export default function NotificationType({ notification, type }: Props) {
  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);
  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (
    <InlineMessage
      variant={type}
      onClick={() => (notification.notificationType === 'Issue' ? (setRedirectPath(newSubNotificationPath(notification))) : (setRedirectPath(editNotificationPath(notification))))}
      body={(
        <>
          <p>
            {' '}
            {notification.title}
            {' '}
          </p>
          <p>
            {' '}
            {notification.channel.name}
            {' '}
          </p>
        </>
  )}
    />
  );
}
