import _ from 'lodash';
import ChannelVisibility from './models/ChannelVisibility';

export default function parseVisibility(json: string | null): ChannelVisibility[] {
  const visibility = JSON.parse(json || '[]');
  const channelVisibility = Object.values(ChannelVisibility);

  if (_.isArray(visibility)) {
    return _.intersection(visibility, channelVisibility);
  }

  return [];
}
