import React, { useState } from 'react';
import Button from '@ingka/button';
import CreateNotification from 'channels/models/CreateNotification';
import ShowChannel from 'channels/models/ShowChannel';
import PreviewModal from './PreviewModal';

type Props = {
  notification: Partial<Omit<CreateNotification, 'notificationType'>> &
  Pick<CreateNotification, 'notificationType'>;
  channel: ShowChannel;
};

export default function PreviewButton({ notification, channel }: Props) {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  return (
    <>
      <Button
        text="Preview"
        htmlType="button"
        type="secondary"
        onClick={() => setIsPreviewVisible(true)}
      />
      <PreviewModal
        isVisible={isPreviewVisible}
        setIsVisible={setIsPreviewVisible}
        notification={notification}
        channel={channel}
      />
    </>
  );
}
