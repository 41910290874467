import Button from '@ingka/button';
import React from 'react';
import {
  Row, Column, Space, ColumnSize, Alignment, Stack,
} from 'ui';
import ChannelListItem from './ChannelListItem';
import Channel from '../models/Channel';

type Props = {
  channels: Channel[];

  onLoadMore: () => void;

  isAllLoaded: boolean;

  searchText: string;
};

export default function SearchResult({
  searchText, channels, isAllLoaded, onLoadMore,
}: Props) {
  return (
    <section>
      <Stack space={Space.Space50}>
        <h3>{searchText}</h3>

        <Row space={Space.Space200}>
          {channels.map((channel) => (
            <Column size={{ l: ColumnSize.Third, m: ColumnSize.Half }} key={channel.id}>
              <ChannelListItem channel={channel} />
            </Column>
          ))}
        </Row>

        {isAllLoaded ? (
          <p>No more search results</p>
        ) : (
          <Row align={Alignment.Center}>
            <Column>
              <Button text="Load more" onClick={onLoadMore} />
            </Column>
          </Row>
        )}
      </Stack>
    </section>
  );
}
