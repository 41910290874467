import ChannelSubscription from 'channels/models/ChannelSubscription';
import React, { Dispatch, SetStateAction, useState } from 'react';
import Switch from '@ingka/switch';
import {
  Space, Stack, Row, Column, Alignment, SaveIndicator,
} from 'ui';
import Checkbox from '@ingka/checkbox';
import { SaveState } from 'ui/components/SaveIndicator';
import _ from 'lodash';
import Channel from 'channels/models/Channel';
import useBackendService from 'services/useBackendService';
import SubscriptionService from 'channels/services/SubscriptionService';
import NotificationType from '../../notifications/models/NotificationType';
import CarrierType from '../models/CarrierType';

export default function SubscriptionForm({
  subscription,
  setSubscription,
  channel,
}: {
  subscription: ChannelSubscription;
  setSubscription: Dispatch<SetStateAction<ChannelSubscription>>;
  channel: Channel;
}) {
  const [saveState, setSaveState] = useState<SaveState>('SAVED');
  const { isSubscribed } = subscription;
  const service = useBackendService(SubscriptionService);
  const toCheckbox = (relation: 'notificationTypes' | 'carrierTypes') => ([name, value]: [
    string,
    string,
  ]) => (
    <Checkbox
      id={name}
      key={name}
      label={name}
      value={value}
      disabled={!isSubscribed}
      checked={subscription[relation].includes(value as any)}
      onChange={() => {
        const updatedSubscription = {
          ...subscription,
          [relation]: _.xor(subscription[relation], [value as any]),
        };
        setSubscription(updatedSubscription);
        setSaveState('SAVING');
        service
          .update(channel.id, updatedSubscription)
          .then((sub) => {
            setSaveState('SAVED');
            setSubscription(sub);
          })
          .catch(() => setSaveState('ERROR'));
      }}
    />
  );

  const carrierTypes: [string, string][] = [
    ['Email', CarrierType.Email],
    ['SMS', CarrierType.SMS],
    ['Push notification', CarrierType.Push],
  ];

  const notificationTypes: [string, string][] = [
    ['Issues', NotificationType.Issue],
    ['Release', NotificationType.Release],
    ['Service window', NotificationType.ServiceWindow],
    ['Other', NotificationType.OtherInformation],
  ];

  const carrierCheckboxes = carrierTypes.map(toCheckbox('carrierTypes'));
  const notificationCheckboxes = notificationTypes.map(toCheckbox('notificationTypes'));

  return (
    <>
      <Row align={Alignment.SpaceBetween}>
        <Column>
          <Switch
            id="subscribe"
            checked={isSubscribed}
            label={isSubscribed ? 'Unsubscribe' : 'Subscribe'}
            onChange={() => {
              const updatedSubscription = { ...subscription, isSubscribed: !isSubscribed };
              setSubscription(updatedSubscription);
              setSaveState('SAVING');
              service
                .update(channel.id, updatedSubscription)
                .then((sub) => {
                  setSubscription(sub);
                  setSaveState('SAVED');
                })
                .catch(() => setSaveState('ERROR'));
            }}
          />
        </Column>

        <Column>
          <SaveIndicator state={saveState} />
        </Column>
      </Row>
      <hr />

      <Stack space={Space.Space400}>
        <Stack space={Space.Space200}>
          <Stack space={Space.Space50}>
            <h2>Subscribe to this channel</h2>
            <p>Select what kind of notifications you will receive.</p>
          </Stack>

          <Stack space={Space.Space150}>{notificationCheckboxes}</Stack>
        </Stack>

        <Stack space={Space.Space200}>
          <Stack space={Space.Space50}>
            <h2>Notification settings</h2>
            <p>
              Please note: SMS and push notifications will only be sent out for business critical
              issues.
            </p>
          </Stack>

          <Stack space={Space.Space150}>{carrierCheckboxes}</Stack>
        </Stack>
      </Stack>
    </>
  );
}
