import React, { useState } from 'react';
import Toast from '@ingka/toast';
import _ from 'lodash';
import { Stack, Space } from 'ui';
import useBackendService from 'services/useBackendService';
import ChannelService from 'channels/services/ChannelService';
import JSONAPISerializer from 'json-api-serializer';
import { Redirect } from 'react-router-dom';
import channelPath from 'channels/channelPath';
import useAuth from 'authentication/useAuth';
import ChannelForm from './ChannelForm';

export default function NewChannel() {
  const [text, setText] = useState('');
  const [errors, setErrors] = useState<JSONAPISerializer.ErrorObject[]>([]);
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const { user } = useAuth();
  const channelService = useBackendService(ChannelService);

  if (redirectPath) {
    return (
      <Redirect
        to={{ pathname: redirectPath, state: { toastMessage: 'Channel request submitted successfully!' } }}
      />
    );
  }

  return (
    <>
      <Stack space={Space.Space400}>
        <h1>Create a new channel request</h1>
        <div className="">
          <p>
            <a className="link" rel="noreferrer" href="https://inter.ikea.net/toolbox/Range-and-Supply/iSubscribe/iSubscribe-terms-and-conditions" target="_blank">
              Learn more about the pre-conditions for an information channel in iSubscribe
            </a>
          </p>
        </div>
        <ChannelForm
          formType="new"
          channel={{ owners: [user] }}
          errors={errors}
          onApprovalSubmit={() => {
          }}
          onSubmit={(attributes) => {
            setErrors([]);
            channelService
              .create(attributes)
              .then((createdChannel) => setRedirectPath(channelPath(createdChannel)))
              .catch(({ response }) => {
                setErrors(response.data.errors);
                setText('Could not submit channel request.');
              });
          }}
        />
      </Stack>

      <Toast
        isOpen={!_.isEmpty(text)}
        text={text}
        onTransitionEnd={() => {}}
        onCloseRequest={() => setText('')}
      />
    </>
  );
}
