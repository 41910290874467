import {
  Row, Column, Inline, Space, Alignment,
} from 'ui';
import React from 'react';
import Pill from '@ingka/pill';
import Category from '../models/Category';

export default function CategoriesSelect({
  isCategoryActive,
  toggleCategory,
}: {
  isCategoryActive: (category: Category) => boolean;
  toggleCategory: (category: Category) => void;
}) {
  return (
    <Row align={Alignment.Center}>
      <Column>
        <Inline space={Space.Space75}>
          {Object.values(Category).map((category) => (
            <Pill
              key={category}
              selected={isCategoryActive(category)}
              label={category}
              onClick={() => toggleCategory(category)}
            />
          ))}
        </Inline>
      </Column>
    </Row>
  );
}
