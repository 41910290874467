import Category from './models/Category';
import ChannelsState from './models/ChannelsState';
import ChannelVisibility from './models/ChannelVisibility';

export default function getInitialState(
  query: string,
  categories: Category[],
  visibility: ChannelVisibility[],
): ChannelsState {
  return {
    nextPage: null,
    query,
    visibility,
    categories,
    isSearchPerformed: query.length > 0 || categories.length > 0 || visibility.length > 0,
    channels: [],
    latestAction: 'INIT',
  };
}
