import ApprovalFormState from './ApprovalFormState';

export default class ChannelApproval {
  channelId: string;

  approvalMessage: string;

  approvalStatus: string;

  constructor(properties: ChannelApproval) {
    this.channelId = properties.channelId;
    this.approvalMessage = properties.approvalMessage;
    this.approvalStatus = properties.approvalStatus;
  }

  static fromApprovalStateForm({ channelId, approvalMessage, approvalStatus }: ApprovalFormState)
    : ChannelApproval {
    return new ChannelApproval(
      { channelId, approvalMessage, approvalStatus },
    );
  }
}
