import Loading, { LoadingBall } from '@ingka/loading';
import { CheckboxGroup } from 'ui';
import React, { useEffect, useState } from 'react';
import HubService from 'channels/services/HubService';
import useBackendService from 'services/useBackendService';
import ErrorPage from 'errors/components/ErrorPage';
import { CheckboxGroupProps } from 'ui/components/CheckboxGroup';
import Status from '@ingka/status';

type Props = {
  selectedHubs: string; // Plain string of selected hub names
  onChange: (hubs: string) => void; // Function to update selected hubs
  valid?: CheckboxGroupProps['valid'];
  validation?: CheckboxGroupProps['validation'];
  shouldValidate?: CheckboxGroupProps['shouldValidate'];
};

export default function HubCheckboxGroup({
  selectedHubs,
  valid = true,
  shouldValidate = false,
  validation,
  onChange = () => {},
}: Props) {
  const [hubs, setHubs] = useState<string[]>([]);
  const [state, setState] = useState('INITIAL');
  const [error, setError] = useState<Error | undefined>(undefined);
  const hubService = useBackendService(HubService);

  useEffect(() => {
    if (state === 'INITIAL') {
      setState('FETCHING_HUBS');
      hubService
        .findAllHubNames()
        .then((fetchedHubs) => {
          setHubs(fetchedHubs);
          setState('FETCHED_HUBS');
        })
        .catch((err) => {
          setError(err);
          setState('FAILED_FETCHING_HUBS');
        });
    }
  }, [hubService, state]);

  if (state === 'FETCHING_HUBS') {
    return (
      <Loading text="Loading hubs...">
        <LoadingBall />
      </Loading>
    );
  }

  if (state === 'FAILED_FETCHING_HUBS') {
    return <ErrorPage error={error ?? new Error()} />;
  }
  if (hubs.length === 0) {
    return (
      <Status
        label="No Hubs Found"
        statusColor="red"
        statusDotPosition="leading"
      />
    );
  }
  return (
    <CheckboxGroup
      valid={valid}
      validation={validation}
      shouldValidate={shouldValidate}
      onChange={(list) => {
        const selectedHubNames = list
          .filter((item: any) => item.checked)
          .map((item: any) => item.label)
          .join(',');

        onChange(selectedHubNames);
      }}
      list={hubs.map((hub) => ({
        id: hub,
        label: hub,
        checked: selectedHubs.split(',').includes(hub),
      }))}
    />
  );
}

HubCheckboxGroup.defaultProps = {
  valid: true,
  shouldValidate: false,
  validation: {},
};
