import React, { useEffect, useState } from 'react';
import {
  Space, Stack, IconLink,
} from 'ui';
import Table, { TableBody, TableHeader } from '@ingka/table';
import pencil from '@ingka/ssr-icon/paths/pencil';
import Region from 'regions/models/Region';
import useBackendService from 'services/useBackendService';
import RegionService from 'regions/services/RegionService';
import Loading, { LoadingBall } from '@ingka/loading';
import ErrorPage from 'errors/components/ErrorPage';
import countriesPath from 'admin/services/countriesPath';

export default function RegionsTable() {
  const [regions, setRegions] = useState<Region[]>([]);
  const [state, setState] = useState('INITIAL');
  const [error, setError] = useState<Error | undefined>(undefined);
  const regionService = useBackendService(RegionService);

  useEffect(() => {
    if (state === 'INITIAL') {
      setState('FETCHING_REGIONS');
      regionService
        .findAll()
        .then((newRegions) => {
          setRegions(newRegions);
          setState('FETCHED_REGIONS');
        })
        .catch((err) => {
          setError(err);
          setState('FAILED_FETCHING_REGIONS');
        });
    }
  }, [regionService, state]);

  if (state === 'FETCHING_REGIONS') {
    return (
      <Loading text="Loading regions...">
        <LoadingBall />
      </Loading>
    );
  }

  if (state === 'FAILED_FETCHING_REGIONS') {
    return <ErrorPage error={error ?? new Error()} />;
  }

  return (
    <section>
      <Stack space={Space.Space50}>
        <Table
          className="demo-sticky-table"
          inset
        >
          <TableHeader sticky>
            <tr>
              <th>
                <h2>Regions</h2>
              </th>
              <th>
                <h2>Actions</h2>
              </th>
            </tr>
          </TableHeader>
          <TableBody striped>
            {regions.map((region) => (
              <tr key={region.id}>
                <td>
                  <div className="tabel__body">
                    {region.name}
                  </div>
                </td>
                <td>
                  <IconLink icon={pencil} text="Edit country" to={countriesPath(region.id)} />
                </td>
              </tr>
            ))}
          </TableBody>
        </Table>

      </Stack>
    </section>
  );
}
