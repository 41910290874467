import ChannelListItem from 'channels/components/ChannelListItem';
import Channel from 'channels/models/Channel';
import _ from 'lodash';
import React from 'react';
import { Row, Space } from 'ui';
import Column from 'ui/components/Column';
import ColumnSize from 'ui/models/ColumnSize';

type Props = { channels: Channel[] };

export default function Channels({ channels }: Props) {
  if (_.isEmpty(channels)) {
    return (
      <Row>
        <Column size={{ m: ColumnSize.Third }}>No channels here yet.</Column>
      </Row>
    );
  }
  return (
    <Row space={Space.Space200}>
      {channels.filter((c) => c.approvalStatus !== 'Undefined')
        .map((channel) => (
          <Column size={{ m: ColumnSize.Third }} key={channel.id}>
            <ChannelListItem channel={channel} />
          </Column>
        ))}
    </Row>
  );
}
