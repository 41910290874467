import React from 'react';
import NotificationModel from 'notifications/models/Notification';
import TimelineEntry from 'timeline/components/TimelineEntry';
import ColorName from 'ui/models/ColorName';
import IssueNotificationAttributes from './IssueNotificationAttributes';

type Props = {
  notification: NotificationModel;
};

export default function IssueNotification({ notification }: Props) {
  return (
    <TimelineEntry color={ColorName.NotificationTypeIssue}>
      <IssueNotificationAttributes notification={notification} />
    </TimelineEntry>
  );
}
