import Loading, { LoadingBall } from '@ingka/loading';
import ShowChannel from 'channels/models/ShowChannel';
import ErrorPage from 'errors/components/ErrorPage';
import HttpStatusCode from 'http/HttpStatusCode';
import useAsyncResource from 'http/useAsyncResource';
import _ from 'lodash';
import NotificationService from 'notifications/services/NotificationService';
import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import useBackendService from 'services/useBackendService';
import DeleteNotification from '../../admin/components/notificationManegement/DeleteNotification';
import EditNotification from './EditNotification';
import NewSubNotification from './NewSubNotification';

type Props = {
  channel: ShowChannel;
};

export default function NotificationRouter({ channel }: Props) {
  const match = useRouteMatch<{ id: string }>('/channels/:channelId/notifications/:id');
  const { id } = match?.params || { id: '' };
  const service = useBackendService(NotificationService);
  const [notification, notificationError] = useAsyncResource(async () => service.findOne(id));

  if (notificationError) {
    if (_.get(notificationError, ['response', 'status']) === HttpStatusCode.NotFound) {
      return <h1>Notification not found</h1>;
    }
    return <ErrorPage error={notificationError} />;
  }
  if (notification) {
    return (
      <Switch>
        <Route path={`${match?.path}/notifications/new`}>
          <NewSubNotification parentNotification={notification} channel={channel} />
        </Route>
        <Route path={`${match?.path}/notifications/edit`}>
          <EditNotification notification={notification} channel={channel} />
        </Route>
        <Route path={`${match?.path}/notifications/delete`}>
          <DeleteNotification notification={notification} />
        </Route>
      </Switch>
    );
  }

  return (
    <Loading>
      <LoadingBall />
    </Loading>
  );
}
