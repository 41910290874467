import React, { useEffect, useState } from 'react';
import ErrorPage from 'errors/components/ErrorPage';
import Loading, { LoadingBall } from '@ingka/loading';
import useAsyncResource from 'http/useAsyncResource';
import useBackendService from 'services/useBackendService';
import UserService from 'users/services/UserService';
import UserContextType from 'admin/models/UserContextType';
import _ from 'lodash';
import HttpStatusCode from 'http/HttpStatusCode';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import AccessForbidden from '../AccessForbidden';

export default function UserRouter() {
  const userService = useBackendService(UserService);
  const match = useRouteMatch<{ id: string }>('/admin/users/:id');
  const { id } = match?.params ?? { id: '' };
  const [user, error] = useAsyncResource(async () => userService.findById(id));
  const [currentUser] = useAsyncResource(async () => userService.findCurrentUser());

  const [state, setUser] = useState(user);
  const hasPermission = currentUser?.privileges
    .some((p) => p.userContextType === UserContextType.Users);
  useEffect(() => {
    if (state) {
      setUser(state);
    } else {
      setUser(user);
    }
  }, [state, user]);

  if (error) {
    if (_.get(error, ['response', 'status']) === HttpStatusCode.Forbidden) {
      return <AccessForbidden />;
    }
    return <ErrorPage error={error} />;
  }
  if (state) {
    return (
      <Switch>
        {hasPermission ? (
          <>
            <Route path={`${match?.path}/edit`}>

              <EditUser
                user={state}
                editedUser={state}
                setUser={setUser}
              />

            </Route>
            <Route path={`${match?.path}/delete`}>

              <DeleteUser
                user={state}
              />

            </Route>
          </>
        ) : <AccessForbidden />}
      </Switch>
    );
  }

  return (
    <Loading text="Loading...">
      <LoadingBall />
    </Loading>
  );
}
