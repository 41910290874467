import { ModelInstance } from 'miragejs';
import User from 'users/models/User';

export default function hasPublishingRights(
  user: ModelInstance<any>,
  channel: ModelInstance<any>,
): boolean {
  const isOwner = channel.owners.models.some((o: ModelInstance<User>) => o.attrs.id === user.id);
  const isPublisher = channel.publishers.models.some(
    (p: ModelInstance<User>) => p.attrs.id === user.id,
  );
  return isOwner || isPublisher;
}
