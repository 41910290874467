import React, { useState } from 'react';
import { Inline, Space } from 'ui';
import UserService from 'users/services/UserService';
import useBackendService from 'services/useBackendService';
import { Redirect, useHistory } from 'react-router-dom';
import Button from '@ingka/button';
import User from 'users/models/User';

export default function DeleteUser({
  user,
}: {
  user: User;
}) {
  const service = useBackendService(UserService);
  const history = useHistory();
  const [redirectPath, setRedirectPath] = useState<any | string>();
  const { id } = user;
  if (redirectPath) {
    return (
      <Redirect
        to={{ pathname: redirectPath, state: { toastMessage: 'User deleted successfully!' } }}
      />
    );
  }

  return (
    <>
      <div>
        <h1>
          Deleting user &quot;
          {user.fullName}
          &quot;
        </h1>
        <p>Are you sure that you want to delete this user? </p>
        <hr />
      </div>
      <Inline space={Space.Space100}>
        <Button text="Cancel" htmlType="button" onClick={() => history.goBack()} />
        <Button
          type="danger"
          text="Delete"
          onClick={() => service.delete(id).then(() => {
            setRedirectPath(history.goBack());
          })}
        />
      </Inline>
    </>
  );
}
