import React from 'react';
import warningTrianglePath from '@ingka/ssr-icon/paths/warning-triangle';
import NotificationModel from 'notifications/models/Notification';
import Accordion, { AccordionItem } from '@ingka/accordion';
import {
  Column, ColumnSize, Inline, Row, Space, Stack,
} from 'ui';
import SSRIcon from '@ingka/ssr-icon';
import toFriendlyNotificationStatus from 'notifications/toFriendlyNotificationStatus';
import { format } from 'date-fns';
import ReactHtmlParser from 'react-html-parser';
import NotificationAttribute from './NotificationAttribute';
import AttachmentNotificationAttribute from './AttachmentNotificationAttribute';

type Props = {
  notification: NotificationModel;
};

export default function IssueNotificationAttributes({ notification }: Props) {
  return (
    <Accordion>
      <AccordionItem
        id={notification.id}
        title={notification.title}
        caption={format(notification.createdAt, 'yyyy-MM-dd HH:mm')}
      >
        <Stack space={Space.Space300}>
          <Row>
            <Column size={{ s: ColumnSize.Half }}>
              <NotificationAttribute label="Title">{notification.title}</NotificationAttribute>
            </Column>

            <Column size={{ s: ColumnSize.Half }}>
              <NotificationAttribute label="Impact">{notification.impact}</NotificationAttribute>
            </Column>
          </Row>

          <Row>
            <Column size={{ s: ColumnSize.Half }}>
              <NotificationAttribute label="Headline">
                {notification.headline}
              </NotificationAttribute>
            </Column>

            <Column size={{ s: ColumnSize.Half }}>
              <AttachmentNotificationAttribute notification={notification} />
            </Column>
          </Row>

          <Row>
            <Column size={{ s: ColumnSize.Full }}>
              <NotificationAttribute label="Description">
                <p
                  style={{
                    margin: '30px auto', lineHeight: '1.5rem', border: '2px solid lightGray', borderRadius: '5px', padding: '20px',
                  }}
                >
                  {ReactHtmlParser(notification.description)}
                </p>
              </NotificationAttribute>
            </Column>
          </Row>

          <Row>
            <Column size={{ s: ColumnSize.Half }}>
              <NotificationAttribute label="Severity">
                <Inline space={Space.Space50}>
                  <SSRIcon paths={warningTrianglePath} />
                  <p>{notification.severity}</p>
                </Inline>
              </NotificationAttribute>
            </Column>
          </Row>

          <Row>
            <Column size={{ s: ColumnSize.Half }}>
              <NotificationAttribute label="Status">
                <p>{notification.status && toFriendlyNotificationStatus(notification.status)}</p>
              </NotificationAttribute>
            </Column>
          </Row>

          <div className="padding--left-550">
            {notification.notifications.map((child) => (
              <IssueNotificationAttributes key={child.id} notification={child} />
            ))}
          </div>
        </Stack>
      </AccordionItem>
    </Accordion>
  );
}
