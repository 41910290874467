export default async function fileToBase64(file: File): Promise<string> {
  function removeDataUrlDeclaration(stringWithDeclaration: string): string {
    return stringWithDeclaration.split(',')[1];
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        return resolve(removeDataUrlDeclaration(reader.result as string));
      }

      return reject(new Error('Could not transform file. Please check for errors.'));
    };
    reader.onerror = (error) => reject(error);
  });
}
