import { camelCase } from 'lodash';
import { JSONAPISerializer, ModelInstance } from 'miragejs';
import { JSONAPISerializerInterface, SerializerInterface } from 'miragejs/serializer';

const ApplicationSerializer = JSONAPISerializer.extend({
  keyForAttribute: (attribute: string) => camelCase(attribute),
  keyForRelationship: (attribute: string) => camelCase(attribute),
  typeKeyForModel: (model: ModelInstance) => camelCase(model.modelName),
}) as JSONAPISerializerInterface & { extend: (param?: SerializerInterface) => SerializerInterface };

export default ApplicationSerializer;
