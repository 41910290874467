import { JSONAPIDocument } from 'json-api-serializer';
import registerSchemas from './registerSchemas';

export default function serialize(
  type: string,
  model: unknown,
  { metadata, excludeMetadata = false }: { metadata?: unknown; excludeMetadata?: boolean } = {},
  schemaOptions: any = {},
): JSONAPIDocument {
  const serializer = registerSchemas();
  return serializer.serialize(type, model, 'default', metadata, excludeMetadata, schemaOptions);
}
