import _ from 'lodash';
import ChannelAction from './models/ChannelAction';
import ChannelsState from './models/ChannelsState';

export default function channelsReducer(
  state: ChannelsState,
  action: ChannelAction,
): ChannelsState {
  switch (action.type) {
    case 'SET_QUERY':
      return { ...state, query: action.query, latestAction: action.type };
    case 'SEARCH':
      return {
        ...state,
        isSearchPerformed: true,
        nextPage: null,
        latestAction: action.type,
      };
    case 'TOGGLE_CATEGORY':
      return {
        ...state,
        isSearchPerformed: true,
        nextPage: null,
        categories: _.xor(state.categories, [action.category]),
        latestAction: action.type,
      };
    case 'TOGGLE_VISIBILITY':
      return {
        ...state,
        isSearchPerformed: true,
        nextPage: null,
        visibility: _.xor(state.visibility, [action.visibility]),
        latestAction: action.type,
      };
    case 'SET_RESULT':
      return {
        ...state,
        nextPage: action.nextPage,
        channels: action.foundChannels,
        latestAction: action.type,
      };
    case 'APPEND_RESULT':
      return {
        ...state,
        nextPage: action.nextPage,
        channels: [...state.channels, ...action.foundChannels],
        latestAction: action.type,
      };
    case 'LOAD_MORE':
      return { ...state, latestAction: action.type };
    default:
      throw new Error(`channelsReducer does not implement ${action.type}.`);
  }
}
