import Footer from 'layouts/components/Footer';
import React from 'react';
import { Space, Stack } from 'ui';

export default function AccessForbidden() {
  return (
    <>
      <main className="container">
        <Stack space={Space.Space200}>
          <h1>Access forbidden</h1>

          <p>
            You are not allowed to view this. Please contact the administration to help you get
            access.
          </p>
        </Stack>
      </main>
      <Footer />
    </>
  );
}
