import React, { useReducer } from 'react';
import { Space, Stack } from 'ui';
import arrowLeftIconPath from '@ingka/ssr-icon/paths/arrow-left';
import Button from '@ingka/button';
import { useHistory, useLocation } from 'react-router-dom';
import useBackendService from 'services/useBackendService';
import UserService from 'users/services/UserService';
import useAsyncResource from 'http/useAsyncResource';
import ErrorPage from 'errors/components/ErrorPage';
import Loading, { LoadingBall } from '@ingka/loading';
import ChannelsState from 'channels/models/ChannelsState';
import ChannelAction from 'channels/models/ChannelAction';
import parseCategories from 'channels/parseCategories';
import channelsReducer from 'channels/channelsReducer';
import getInitialState from 'channels/getInitialState';
import UserState from 'profile/models/UserState';
import UserAction from 'profile/models/UserAction';
import UserReducer from 'users/models/UsersReducer';
import UserContextType from 'admin/models/UserContextType';
import getInitialUserState from 'users/models/getInitialUserState';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import RegionsTable from 'admin/components/countryManagement/RegionsTable';
import parseVisibility from 'channels/parseVisibility';
import ChannelsTable from './channelManagement/ChannelsTable';
import UsersTabel from './userManagement/UsersTabel';

export default function AdminPage() {
  const history = useHistory();
  const userService = useBackendService(UserService);
  const [user, error] = useAsyncResource(() => userService.findCurrentUser());
  const queryParams = new URLSearchParams(useLocation().search);
  const query = queryParams.get('query') || '';
  const userQuery = queryParams.get('userQuery') || '';
  const categories = parseCategories(queryParams.get('filter[categories]'));
  const visibility = parseVisibility(queryParams.get('filter[visibility]'));
  const [state, dispatch] = useReducer(
    (prevState: ChannelsState, action: ChannelAction) => channelsReducer(prevState, action),
    getInitialState(query, categories, visibility),
  );

  const [userState, userDispatch] = useReducer(
    (prevState: UserState, action: UserAction) => UserReducer(prevState, action),
    getInitialUserState(userQuery),
  );
  const previlege = user?.privileges[0];
  let addNew = true;
  const tabs = [
    user?.privileges.map((p) => {
      switch (p.userContextType) {
        case UserContextType.Users:
          return (
            <>
              <Tab key="Users" text="Users" tabPanelId="Users" />
            </>
          );
        case UserContextType.Channels:
          addNew = false;
          return (
            <>
              <Tab key="Channels" text="Channels" tabPanelId="Channels" />
            </>
          );
        case UserContextType.Notifications:
          if (!addNew) return null;
          return (
            <>
              <Tab key="Notifications" text="Notifications" tabPanelId="Notifications" />
            </>
          );
        case UserContextType.Countries:
          return (
            <>
              <Tab key="Countries" text="Regions" tabPanelId="Countries" />
            </>
          );
        default:
          return null;
      }
    }),

  ];

  const tabPanels = [
    user?.privileges.map((p) => {
      switch (p.userContextType) {
        case UserContextType.Channels:
          addNew = false;
          return (
            <>
              <TabPanel key="Channels" tabPanelId="Channels" data-testid="Channels">
                <ChannelsTable state={state} dispatch={dispatch} userState={userState} />
              </TabPanel>
            </>
          );
        case UserContextType.Notifications:
          if (!addNew) return null;
          return (
            <>
              <TabPanel key="Notifications" tabPanelId="Notifications" data-testid="Notifications">
                <ChannelsTable state={state} dispatch={dispatch} userState={userState} />
              </TabPanel>
            </>
          );
        default:
          return null;
      }
    }),

    <>
      <TabPanel key="Users" tabPanelId="Users" data-testid="Users">
        <UsersTabel state={userState} dispatch={userDispatch} channelState={state} />
      </TabPanel>

      <TabPanel key="Countries" tabPanelId="Countries" data-testid="Countries">
        <RegionsTable />
      </TabPanel>
    </>,

  ];

  if (error) {
    return <ErrorPage error={error} />;
  }
  if (user) {
    return (
      <Stack space={Space.Space250}>
        <Button onClick={() => history.goBack()} type="tertiary" ssrIcon={arrowLeftIconPath} />
        <h1>Administration</h1>
        { user.privileges.length > 0
          ? (
            <>
              <Tabs
                tabs={tabs}
                tabPanels={tabPanels}
                defaultActiveTab={previlege?.userContextType}
              />
            </>
          )
          : <p>You don&apos;t have any privilege for administration.</p>}
      </Stack>
    );
  }

  return (
    <Stack space={Space.Space200}>
      <Loading text="Loading...">
        <LoadingBall />
      </Loading>
    </Stack>
  );
}
