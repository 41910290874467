import React from 'react';
import Checkbox from '@ingka/checkbox';
import IndeterminateCheckboxState from 'ui/models/IndeterminateCheckboxState';
import { FormFieldStatusProps } from '@ingka/form-field-status';
import mapMatching from 'arrays/mapMatching';
import IndeterminateCheckbox from './IndeterminateCheckbox';

type CheckboxProps = {
  id: string;
  subtle?: boolean;
  name?: string;
  label: string;
  prefix?: string;
  checked?: boolean;
};

export type CheckboxGroupProps = {
  list: (CheckboxProps | NestedCheckboxGroupProps)[];
  valid?: FormFieldStatusProps['valid'];
  shouldValidate?: FormFieldStatusProps['shouldValidate'];
  validation?: FormFieldStatusProps['validation'];
  onChange: (value: any) => unknown;
};

type NestedCheckboxGroupProps = Omit<CheckboxProps, 'onChange'> & CheckboxGroupProps;

function isNestedCheckbox(
  props: CheckboxProps | NestedCheckboxGroupProps,
): props is NestedCheckboxGroupProps {
  return 'list' in props;
}

const collectDescendants = (
  x: CheckboxProps | NestedCheckboxGroupProps,
  acc = [],
): CheckboxProps[] => {
  if (isNestedCheckbox(x)) {
    return [...acc, ...x.list.flatMap((y: any) => collectDescendants(y))];
  }

  return [...acc, x];
};

const isChecked = (x: any) => x.checked ?? x.state === IndeterminateCheckboxState.Checked;

function decideIndeterminateCheckboxState(item: any) {
  const descendants = collectDescendants(item);
  const isAnyChecked = descendants.some(isChecked);
  const isAllChecked = descendants.every(isChecked);

  if (isAllChecked) {
    return IndeterminateCheckboxState.Checked;
  }

  if (isAnyChecked) {
    return IndeterminateCheckboxState.Indeterminate;
  }

  return IndeterminateCheckboxState.Unchecked;
}

function setDescendants(item: any, checked: boolean): any {
  if (isNestedCheckbox(item)) {
    return {
      ...item,
      list: item.list.map((grandChild) => setDescendants(grandChild, checked)),
    };
  }

  return { ...item, checked };
}

function shouldDescendantsBeChecked(item: any): boolean {
  const descendants = collectDescendants(item);
  const isAllDescendantsChecked = descendants.every(isChecked);
  return !isAllDescendantsChecked;
}

export default function CheckboxGroup({
  list,
  onChange,
}: CheckboxGroupProps) {
  const byId = (id: string) => (item: any) => item.id === id;

  return (
    <div>
      <ul className="list--plain">
        {list.map((item) => {
          if (isNestedCheckbox(item)) {
            return (
              <li className="margin--y-100" key={item.id}>
                <IndeterminateCheckbox
                  id={item.id}
                  subtle={item.subtle}
                  label={item.label}
                  prefix={item.prefix}
                  onChange={() => {
                    onChange(
                      mapMatching(list, byId(item.id), () => {
                        const isDescendantsChecked = shouldDescendantsBeChecked(item);
                        return setDescendants(item, isDescendantsChecked);
                      }),
                    );
                  }}
                  state={decideIndeterminateCheckboxState(item)}
                />
                <div className="padding--left-250">
                  <CheckboxGroup
                    list={item.list}
                    onChange={(nestedList) => {
                      onChange(
                        mapMatching(list, byId(item.id), () => ({ ...item, list: nestedList })),
                      );
                    }}
                  />
                </div>
              </li>
            );
          }

          return (
            <li className="margin--y-100" key={item.id}>
              <Checkbox
                id={item.id}
                subtle={item.subtle}
                label={item.label}
                name={item.name}
                prefix={item.prefix}
                checked={item.checked}
                onChange={() => {
                  onChange(
                    mapMatching(list, byId(item.id), (x) => ({ ...x, checked: !x.checked })),
                  );
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
