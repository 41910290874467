import React from 'react';
import Column from './Column';
import Row from './Row';
import ColumnSize from '../models/ColumnSize';
import Space from '../models/Space';
import Stack from './Stack';
import Inline from './Inline';
import Color from '../models/Color';

export default function UiComponents() {
  return (
    <>
      <h1>.row--250</h1>
      <Row space={Space.Space250}>
        <Column size={{ s: ColumnSize.Full }}>
          <div style={{ backgroundColor: Color.Grey200 }}>s-full</div>
        </Column>

        <Column size={{ m: ColumnSize.Half }}>
          <div style={{ backgroundColor: Color.Grey200 }}>m-half</div>
        </Column>
        <Column size={{ m: ColumnSize.Half }}>
          <div style={{ backgroundColor: Color.Grey200 }}>m-half</div>
        </Column>

        <Column size={{ l: ColumnSize.Third }}>
          <div style={{ backgroundColor: Color.Grey200 }}>l-third</div>
        </Column>
        <Column size={{ l: ColumnSize.Third }}>
          <div style={{ backgroundColor: Color.Grey200 }}>l-third</div>
        </Column>
        <Column size={{ l: ColumnSize.Third }}>
          <div style={{ backgroundColor: Color.Grey200 }}>l-third</div>
        </Column>
      </Row>

      <h1>.stack--250</h1>
      <Stack space={Space.Space250}>
        <p style={{ backgroundColor: Color.Grey200 }}>stack-item p</p>
        <span style={{ backgroundColor: Color.Grey200 }}>stack-item span</span>
        <div style={{ backgroundColor: Color.Grey200 }}>stack-item</div>
      </Stack>

      <h1>.inline--100</h1>
      <Inline space={Space.Space100}>
        <span style={{ backgroundColor: Color.Grey200 }}>inline-item</span>
        <span style={{ backgroundColor: Color.Grey200 }}>inline-item</span>
        <span style={{ backgroundColor: Color.Grey200 }}>inline-item</span>
      </Inline>
    </>
  );
}
