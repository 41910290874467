import ApprovalFormState from './models/ApprovalFormState';

type SetPropertyAction<K extends keyof ApprovalFormState = keyof ApprovalFormState> = {
  type: 'SET_PROPERTY';
  name: K;
  value: ApprovalFormState[K];
};
type Action = SetPropertyAction;

export default function approvalFormReducer(
  state: ApprovalFormState,
  action: Action,
): ApprovalFormState {
  switch (action.type) {
    case 'SET_PROPERTY':
      return { ...state, [action.name]: action.value };
    default:
      return state;
  }
}
