import Button from '@ingka/button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Inline, Space, Stack } from 'ui';
import arrowLeftIconPath from '@ingka/ssr-icon/paths/arrow-left';
import NewNotificationChoices from './NewNotificationChoices';
import ExistingNotificationChoices from './ExistingNotificationChoices';

export default function NewNotification() {
  const history = useHistory();
  return (
    <Stack space={Space.Space750}>
      <Stack space={Space.Space300}>
        <Inline>
          <Button onClick={() => history.goBack()} type="tertiary" ssrIcon={arrowLeftIconPath} />
        </Inline>

        <h1>Create a notification</h1>
      </Stack>

      <ExistingNotificationChoices />

      <NewNotificationChoices />
    </Stack>
  );
}
