import arrowLeftIconPath from '@ingka/ssr-icon/paths/arrow-left';
import { useHistory } from 'react-router-dom';
import Button from '@ingka/button';
import React from 'react';

export default function BackButton() {
  const history = useHistory();

  return <Button onClick={() => history.goBack()} type="tertiary" ssrIcon={arrowLeftIconPath} />;
}
