import React, { useState } from 'react';
import arrowLeftIconPath from '@ingka/ssr-icon/paths/arrow-left';
import ShowChannel from 'channels/models/ShowChannel';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';

import NotificationsTable from 'admin/components/notificationManegement/NotificationsTable';
import Button from '@ingka/button';
import { Space, Stack } from 'ui';
import { useHistory } from 'react-router-dom';
import useBackendService from 'services/useBackendService';
import NotificationService from 'notifications/services/NotificationService';
import PaginatedNotifications from 'notifications/models/PaginatedNotifications';
import useEffectOnce from 'hooks/useEffectOnce';
import ErrorPage from 'errors/components/ErrorPage';

type Props = {
  channel: ShowChannel;
};

export default function NotificationTypeTabs({ channel }: Props) {
  const history = useHistory();
  const service = useBackendService(NotificationService);
  const [result, setResult] = useState<PaginatedNotifications | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const notifications = result?.notifications ?? [];
  const fetchNotifications = () => service
    .findForChannel(channel, result?.nextPage)
    .then((res) => setResult({ ...res, notifications: [...notifications, ...res.notifications] }))
    .catch((err) => setError(err));

  useEffectOnce(fetchNotifications);

  const renderNextPageButton = () => {
    if (result?.nextPage) {
      return <Button type="primary" text="Load more" onClick={fetchNotifications} />;
    }

    return '';
  };

  if (error) {
    return <ErrorPage error={error} />;
  }

  const tabs = [
    <Tab key="issue" text="Issue" tabPanelId="issue" className="tabs__tab--issue" />,
    <Tab key="release" text="Release" tabPanelId="release" className="tabs__tab--release" />,
    <Tab key="serviceWindow" text="Service window" tabPanelId="serviceWindow" className="tabs__tab--service-window" />,
    <Tab key="otherInformation" text="Other" tabPanelId="otherInformation" className="tabs__tab--other" />,
  ];
  const tabPanels = [
    result?.notifications.map((notification) => {
      switch (notification.notificationType) {
        case 'Issue':
          return (
            <>
              <TabPanel key="issue" tabPanelId="issue" data-testid="issue">
                <NotificationsTable notification={notification} />
                {renderNextPageButton()}
              </TabPanel>
            </>
          );

        case 'Release':
          return (
            <>
              <TabPanel key="release" tabPanelId="release" data-testid="release">
                <NotificationsTable notification={notification} />
                {renderNextPageButton()}
              </TabPanel>
            </>
          );
        case 'ServiceWindow':
          return (
            <>
              <TabPanel key="serviceWindow" tabPanelId="serviceWindow" data-testid="serviceWindow">
                <NotificationsTable notification={notification} />
                {renderNextPageButton()}
              </TabPanel>
            </>
          );
        case 'OtherInformation':
          return (
            <>
              <TabPanel key="otherInformation" tabPanelId="otherInformation" data-testid="otherInformation">
                <NotificationsTable notification={notification} />
              </TabPanel>
            </>
          );

        default:
          return null;
      }
    }),
  ];

  return (
    <Stack space={Space.Space250}>
      <Button onClick={() => history.goBack()} type="tertiary" ssrIcon={arrowLeftIconPath} />
      <h1>
        Notifications for &quot;
        {channel.name}
        &quot;
      </h1>

      <>
        <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="issue" />
      </>

    </Stack>
  );
}
