import Banner from '@ingka/banner';
import Button from '@ingka/button';
import useAuth from 'authentication/useAuth';
import useEffectOnce from 'hooks/useEffectOnce';
import React, { useState } from 'react';
import useBackendService from 'services/useBackendService';
import UserService from 'users/services/UserService';

export default function UserSynchronizationBanner() {
  const { user, logout } = useAuth();
  const userService = useBackendService(UserService);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffectOnce(() => userService.synchronizeInfo(user).catch((err) => setError(err)));

  if (error) {
    const couldNotSaveText = 'Could not save user information. ';
    const errorText = `Error: ${error.message}. `;
    const signOutButton = (
      <Button type="plain" onClick={() => logout()}>
        signing out
      </Button>
    );

    const signoutText = (
      <span>
        Please try
        {signOutButton}
        {' '}
        and signing in if the problem persists.
      </span>
    );

    const body = (
      <>
        {couldNotSaveText}
        {errorText}
        {signoutText}
      </>
    );

    return <Banner body={body} dismissable />;
  }

  return <></>;
}
