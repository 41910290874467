import { ApexOptions } from 'apexcharts';
import Channel from 'channels/models/Channel';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function TopChannels({
  channels,
}: {
  channels: Channel[];
}) {
  const percent = (subscriberCount : number) => {
    const total = channels.reduce(
      (totalSubscribers: number, channel: Channel) => totalSubscribers + channel.subscriberCount, 0,
    );
    return subscriberCount * (100 / total);
  };

  const seriesData: any = [];

  channels.forEach((value) => {
    seriesData.push(
      {
        x: value.name,
        y: percent(value.subscriberCount),
      },
    );
  });
  const data = {
    series: [{ data: seriesData }],
    options: {
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '14px',
        },
        formatter: (text: any, op: any) => [`${op.value.toFixed(0)}%`, text.length > 30
          ? `${text.substring(0, 30)}...`
          : text],
        offsetY: -4,
      },
      colors: ['#45b6fe', '#3792cb', '#296d98', '#1c4966', '#0e2433'],
      fill: {
        type: 'solid',
        opacity: 0.8,
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (y: any) => `${y.toFixed(0)}%`,
        },
      },
    } as unknown as ApexOptions,

  };
  return (
    <>
      <h2 className="text--align-center">Top 10 most subscribed channels</h2>
      <ReactApexChart options={data.options} series={data.series} height={800} type="treemap" />
    </>
  );
}
