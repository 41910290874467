import PropTypes from 'prop-types';
import React, { CSSProperties, ReactNode } from 'react';
import Alignment from '../models/Alignment';
import Space from '../models/Space';

interface Props {
  children: ReactNode;

  align?: Alignment;

  space?: Space;
}

const Row = ({ children, align, space }: Props) => {
  const style: CSSProperties = { justifyContent: align };
  const className = `row--space-${space}`;

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

Row.defaultProps = {
  children: [],
  align: Alignment.Start,
  space: Space.None,
};

Row.propTypes = {
  align: PropTypes.oneOf(Object.values(Alignment)),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  space: PropTypes.oneOf(Object.values(Space)),
};

export default Row;
