import JSONAPISerializer from 'json-api-serializer';

const SCHEMAS: { name: string; options?: JSONAPISerializer.Options }[] = [
  {
    name: 'user',
    options: {
      relationships: {
        termsOfUseAgreements: { type: 'termsOfUseAgreement' },
        subscribedCountries: { type: 'country' },
        ownedChannels: { type: 'channel' },
        publishingChannels: { type: 'channel' },
        subscribedChannels: { type: 'channel' },
        privileges: { type: 'admin' },
      },
    },
  },
  {
    name: 'notification',
    options: {
      relationships: {
        channel: { type: 'channel' },
        notifications: { type: 'notification' },
        countries: { type: 'country' },
      },
    },
  },
  {
    name: 'channel',
    options: {
      relationships: {
        subscription: { type: 'subscription' },
        owners: { type: 'user' },
        publishers: { type: 'user' },
        countries: { type: 'country' },
      },
    },
  },
  {
    name: 'termsOfUseAgreement',
    options: {
      relationships: {
        user: { type: 'user' },
      },
    },
  },
  {
    name: 'admin',
    options: {
      relationships: {
        user: { type: 'user' },
      },
    },
  },
  {
    name: 'region',
    options: {
      relationships: {
        countries: { type: 'country' },
      },
    },
  },
  {
    name: 'country',
    options: {
      relationships: {
        region: { type: 'region' },
      },
    },
  },
  { name: 'subscription' },
  { name: 'mailPreview' },
  { name: 'privilege' },
  { name: 'informationDashboard' },
  { name: 'approval' },
];

export default function registerSchemas() {
  const serializer = new JSONAPISerializer();
  SCHEMAS.forEach(({ name, options }) => serializer.register(name, options));
  return serializer;
}
