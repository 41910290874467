import React from 'react';
import {
  Alignment,
  Inline, Row, Space, Stack,
} from 'ui';
import { FiUsers } from 'react-icons/fi';
import { FaNetworkWired } from 'react-icons/fa';
import { MdOutlineFeaturedPlayList } from 'react-icons/md';
import Counter from './Counter';

export default function ISubscribeInNumbers({
  channelsCount,
  usersCount,
  notificationsCount,
}: {
  channelsCount: number;
  usersCount: number;
  notificationsCount: number
}) {
  return (

    <Stack space={Space.Space300}>
      <Row align={Alignment.Center}>
        <Inline space={Space.Space1000}>
          <div className="content">
            <FiUsers size={50} className="circle-icon" />
            <div>
              <h1>
                <Counter number={usersCount} />
              </h1>
              <h3>Active users</h3>
            </div>
          </div>
          <div className="content">
            <FaNetworkWired size={50} className="circle-icon" />
            <div>
              <h1>
                <Counter number={channelsCount} />
              </h1>
              <h3>Information channels</h3>
            </div>
          </div>
          <div className="content">
            <MdOutlineFeaturedPlayList size={50} className="circle-icon" />
            <div>
              <h1>
                <Counter number={notificationsCount} />
              </h1>
              <h3>Notifications</h3>
            </div>
          </div>
        </Inline>
      </Row>
    </Stack>
  );
}
