import ChannelFormState from './models/ChannelFormState';
import ChannelVisibility from './models/ChannelVisibility';

const INITIAL_CHANNEL_FORM_STATE: ChannelFormState = {
  name: '',
  url: '',
  description: '',
  owners: [],
  ownersText: '',
  publishers: [],
  publishersText: '',
  categories: [],
  countries: [],
  thumbnailUrl: '',
  visibility: ChannelVisibility.Visible,
  hubs: '',
};

export default INITIAL_CHANNEL_FORM_STATE;
