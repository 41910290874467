import { useEffect, useState } from 'react';

export default function useAsyncResource<T>(
  callback: () => Promise<T>,
): [T | undefined, Error | undefined, 'INITIAL' | 'FETCHING' | 'DONE' | 'FAILED'] {
  const [resource, setResource] = useState<T | undefined>(undefined);
  const [fetchState, setFetchState] = useState<'INITIAL' | 'FETCHING' | 'DONE' | 'FAILED'>(
    'INITIAL',
  );
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (fetchState === 'INITIAL') {
      setFetchState('FETCHING');
      callback()
        .then((fetchedResource) => {
          setFetchState('DONE');
          setResource(fetchedResource);
        })
        .catch((err: Error) => {
          setFetchState('FAILED');
          setError(err);
        });
    }
  }, [callback, fetchState]);

  return [resource, error, fetchState];
}
