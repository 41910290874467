import { AxiosResponse } from 'axios';
import HttpStatusCode from 'http/HttpStatusCode';
import Configuration from 'configuration/models/Configuration';
import ShowResponse from 'channels/models/ShowResponse';
import ShowChannel from 'channels/models/ShowChannel';
import deserialize from 'json-api/deserialize';
import Channel from 'channels/models/Channel';
import BackendClient from 'http/services/BackendClient';
import { JSONAPIDocument } from 'json-api-serializer';
import { serialize } from 'json-api';
import InformationDashboard from 'Dashboard/models/InformationDashboard';
import ChannelFormState from 'channels/models/ChannelFormState';
import ChannelApproval from 'channels/models/ChannelApproval';
import SearchParameters from '../models/SearchParameters';
import IndexResponse from '../models/IndexResponse';
import SearchResult from '../models/SearchResult';

export default class ChannelService {
  constructor(private client: BackendClient) {}

  async update(channel: ChannelFormState & { id: string }): Promise<ShowChannel> {
    const url = `${Configuration.get('API_BASE_URL')}/channels/${channel.id}`;

    return this.client
      .put<JSONAPIDocument>(url, serialize('channel', channel))
      .then((response) => deserialize('channel', response.data));
  }

  async create(channel: ChannelFormState): Promise<ShowChannel> {
    const url = `${Configuration.get('API_BASE_URL')}/channels`;

    return this.client
      .post<JSONAPIDocument>(url, serialize('channel', channel))
      .then((response) => deserialize('channel', response.data));
  }

  async search(params: SearchParameters): Promise<SearchResult> {
    if (params.page) {
      return this.client
        .get<IndexResponse>(params.page)
        .then(ChannelService.deserializeSearchResult);
    }

    const url = `${Configuration.get('API_BASE_URL')}/channels`;
    const queryParams = {
      query: params.query,
      'filter[categories]': JSON.stringify(params.categories),
      'filter[visibility]': JSON.stringify(params.visibility),
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    };
    return this.client
      .get<IndexResponse>(url, { params: queryParams })
      .then(ChannelService.deserializeSearchResult);
  }

  async findAllPublishable(): Promise<Channel[]> {
    const url = `${Configuration.get('API_BASE_URL')}/user/publishable-channels`;
    return this.client
      .get<JSONAPIDocument>(url)
      .then((response) => deserialize<Channel[]>('channel', response.data));
  }

  async findById(id: string): Promise<ShowChannel> {
    const url = `${Configuration.get('API_BASE_URL')}/channels/${id}`;
    return this.client
      .get<ShowResponse>(url)
      .then((response) => deserialize<ShowChannel>('channel', response.data));
  }

  async countDataForDashboard(): Promise<InformationDashboard> {
    return this.client
      .get<InformationDashboard>(`${Configuration.get('API_BASE_URL')}/channels/dashboard`)
      .then((response) => response.data);
  }

  async findTopChannelsList(): Promise<Channel[]> {
    return this.client
      .get<JSONAPIDocument>(`${Configuration.get('API_BASE_URL')}/channels/top-channels`)
      .then((response) => deserialize<Channel[]>('channel', response.data));
  }

  async delete(id: string): Promise<ShowChannel> {
    const url = `${Configuration.get('API_BASE_URL')}/channels/${id}`;

    return this.client
      .delete<JSONAPIDocument>(url)
      .then((response) => deserialize('channel', response.data));
  }

  async sendChannelApproval(channelApproval: ChannelApproval & { id: string })
    : Promise<HttpStatusCode> {
    const url = `${Configuration.get('API_BASE_URL')}/channels/${channelApproval.id}/approval`;
    return this.client
      .post<JSONAPIDocument>(url, serialize('approval', channelApproval))
      .then((response) => response.status);
  }

  static deserializeSearchResult = (response: AxiosResponse<IndexResponse>) => ({
    nextPage: response.data.links.next,
    channels: deserialize<Channel[]>('channel', response.data),
  });
}
