import React from 'react';
import {
  Column, ColumnSize, IconLink, Row, Space, Stack,
} from 'ui';
import Notification from 'notifications/models/Notification';
import cross from '@ingka/ssr-icon/paths/cross';
import deleteNotificationPath from 'admin/services/deleteNotificationPath';
import pencil from '@ingka/ssr-icon/paths/pencil';
import newSubNotificationPath from 'notifications/newSubNotificationPath';
import editNotificationPath from 'notifications/editNotificationPath';

type Props = {
  notification: Notification;
};

export default function NotificationsTable({ notification }: Props) {
  return (
    <Stack space={Space.Space300}>
      <Row space={Space.Space50}>
        <Column size={{ m: ColumnSize.Half }}>
          <h3>{notification.title}</h3>
        </Column>
        <Column size={{ m: ColumnSize.Half }}>
          <IconLink icon={pencil} text="Update notification" to={notification.notificationType === 'Issue' ? (newSubNotificationPath(notification)) : (editNotificationPath(notification))} />
          <IconLink icon={cross} text="Delete notification" to={deleteNotificationPath(notification)} />
        </Column>
      </Row>

    </Stack>
  );
}
