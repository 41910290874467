import React from 'react';
import { EmphasisedCard, Color } from 'ui';
import Channel from 'channels/models/Channel';
import channelPath from 'channels/channelPath';

export default function ChannelListItem({ channel }: { channel: Channel }) {
  if (channel.approvalStatus !== 'Undefined') {
    return (
      <EmphasisedCard
        title={channel.name}
        body={channel.description.length > 200
          ? `${
            channel.description.substring(0, 200)}...` : channel.description}
        href={channelPath(channel)}
        backgroundColor={Color.Grey100}
        aspectRatioImageProps={{
          src: channel.thumbnailUrl,
          alt: `Preview image of ${channel.name}`,
        }}
      />
    );
  }
  return (
    null
  );
}
