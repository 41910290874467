import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import Space from 'ui/models/Space';
import Alignment from '../models/Alignment';

interface Props {
  children: ReactNode;
  align?: Alignment;
  height?: 'auto' | '100%';
  space?: Space;
}

function StackItem({ children }: { children: ReactNode }) {
  return <div className="stack__item">{children}</div>;
}

const Stack = ({
  children, align, height, space,
}: Props) => {
  const className = `stack--space-${space}`;

  return (
    <div
      className={className}
      style={{
        justifyContent: align,
        height,
      }}
    >
      {React.Children.map(children, (child: ReactNode) => (
        <StackItem>{child}</StackItem>
      ))}
    </div>
  );
};

Stack.defaultProps = {
  children: [],
  align: Alignment.Start,
  height: 'auto',
  space: Space.None,
};

Stack.propTypes = {
  height: PropTypes.string,
  align: PropTypes.oneOf(Object.values(Alignment)),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  space: PropTypes.oneOf(Object.values(Space)),
};

export default Stack;
