import InlineMessage from '@ingka/inline-message';
import React from 'react';

export default function ErrorPage({ error }: { error: Error }) {
  const body = (
    <>
      <p>
        We encountered an error we could not handle. Please contact support with a screenshot of
        this information and they&apos;ll help you resolve the issue.
      </p>

      <h2>{error.message}</h2>
      <pre>{error.stack}</pre>
    </>
  );
  return (
    <>
      <InlineMessage variant="negative" title="Something went wrong" body={body} />
    </>
  );
}
