import Accordion, { AccordionItem } from '@ingka/accordion';
import React from 'react';
import {
  Alignment, Column, ColumnSize, Row, Space, Stack,
} from 'ui';
import HowToCreateAChannel from './HowToCreateAChannel';
import HowToEditChannel from './HowToEditChannel';
import HowToSendANotification from './HowToSendANotification';
import HowToSubscribeToAChannel from './HowToSubscribeToAChannel';

export default function Usage() {
  return (
    <Stack space={Space.Space550}>
      <h1 className="text--align-center">iSubscribe Guide</h1>

      <Row align={Alignment.Center}>
        <Column size={{ m: ColumnSize.ThreeFourths }}>
          <Accordion>
            <AccordionItem id="how-to-subscribe" title="How can I subscribe to a channel?">
              <HowToSubscribeToAChannel />
            </AccordionItem>

            <AccordionItem id="how-to-create-channel" title="How can I create a channel?">
              <HowToCreateAChannel />
            </AccordionItem>

            <AccordionItem
              id="how-to-edit-channel"
              title="How can I change a channel and/or permissions to it?"
            >
              <HowToEditChannel />
            </AccordionItem>

            <AccordionItem id="how-to-create-notification" title="How can I send a notification?">
              <HowToSendANotification />
            </AccordionItem>
          </Accordion>
        </Column>
      </Row>
    </Stack>
  );
}
