import List from '@ingka/list';
import React from 'react';
import { Space, Stack } from 'ui';
import howToEditAChannel from 'assets/how-to-edit-a-channel.jpg';

export default function HowToEditChannel() {
  return (
    <Stack space={Space.Space300}>
      <b>
        Note! Changes to the information channel can only be done by the information channel owners
      </b>

      <List ordered>
        <li>
          Find the information channel either through the search functionality on the main page or
          in your profile section.
        </li>
        <li>
          <p>
            Above the information channel details, click on the option
            {' '}
            <b>
              <i>Click here to edit this channel</i>
            </b>
          </p>

          <img
            className="width--100"
            src={howToEditAChannel}
            alt="Screenshot showing a hand pointing to the edit link of a channel"
          />
        </li>
        <li>The information channel details will be editable.</li>
        <li>
          When you&apos;re done with your changes press the
          {' '}
          <b>
            <i>Save</i>
          </b>
          {' '}
          button and you will be redirected back to the information channel presentation.
        </li>
      </List>
    </Stack>
  );
}
