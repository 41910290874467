import React from 'react';
import { Space } from 'ui';
import Stack from 'ui/components/Stack';
import Footer from 'layouts/components/Footer';

export default function InactiveUserMessage() {
  return (

    <>
      <main className="container">
        <Stack space={Space.Space200}>
          <h1>Inactive user</h1>

          <p>
            Your user is inactive and don&apos;t have access to iSubscribe.
            If you think you should have access please contact maintenance
            team for further assistant.
          </p>
        </Stack>
      </main>
      <Footer />
    </>

  );
}
