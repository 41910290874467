import React from 'react';
import Accordion, { AccordionItem } from '@ingka/accordion';
import Loading, { LoadingBall } from '@ingka/loading';
import ErrorPage from 'errors/components/ErrorPage';
import useAsyncResource from 'http/useAsyncResource';
import _ from 'lodash';
import NotificationService from 'notifications/services/NotificationService';
import useBackendService from 'services/useBackendService';
import {
  Alignment, Column, ColumnSize, Row, Space,
} from 'ui';
import Stack from 'ui/components/Stack';
import NotificationType from 'notifications/models/NotificationType';
import NotificationsType from './NotificationsType';

export default function ExistingNotificationChoices() {
  const helpText = <span>You have no open notifications. Create a notification below.</span>;
  const notificationService = useBackendService(NotificationService);
  const [notifications, error] = useAsyncResource(() => notificationService.findAllOpenForUser());
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (notifications) {
    const choices = (
      <>
        <>
          <>
            <Accordion>
              <AccordionItem id="issue" title="Issue">
                {notifications.filter(
                  (n) => n.notificationType === NotificationType.Issue,
                ).map((notification) => (
                  <NotificationsType notification={notification} type="negative" />
                ))}
              </AccordionItem>
            </Accordion>
            <Accordion>
              <AccordionItem id="release" title="Release">
                {notifications.filter(
                  (n) => n.notificationType === NotificationType.Release,
                ).map((notification) => (
                  <NotificationsType notification={notification} type="positive" />
                ))}
              </AccordionItem>
            </Accordion>
          </>
          <Accordion>
            <AccordionItem id="service-window" title="Service Window">
              {notifications.filter(
                (n) => n.notificationType === NotificationType.ServiceWindow,
              ).map((notification) => (
                <NotificationsType notification={notification} type="cautionary" />
              ))}
            </AccordionItem>
          </Accordion>
        </>
      </>
    );
    return (
      <>
        <Stack space={Space.Space200}>
          <div>
            <p>Create an update for an existing notification</p>
            <hr />
          </div>

          <Row align={Alignment.Center}>
            <Column size={{ m: ColumnSize.FiveSixths }}>
              {_.isEmpty(notifications) ? helpText : choices}
            </Column>
          </Row>
        </Stack>
      </>
    );
  }

  return (
    <Loading text="Loading...">
      <LoadingBall />
    </Loading>
  );
}
