import { Response } from 'miragejs';
import Server from './models/Server';

export default function declareTemplateRoutes(this: Server) {
  this.post('/templates/:notificationType/mail-previews', (schema, request) => {
    const data = JSON.parse(request.requestBody);
    return new Response(
      200,
      {},
      {
        data: {
          type: 'mailPreview',
          attributes: {
            htmlContent: `${data.title}`,
          },
        },
      },
    );
  });
}
