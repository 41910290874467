/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import Button from '@ingka/button';
import InputField from '@ingka/input-field';
import TextArea from '@ingka/text-area';
import JSONAPISerializer from 'json-api-serializer';
import React, { useState } from 'react';
import {
  FileInput, Inline, Space, Stack,
} from 'ui';
import errorsForField from 'validation/errorsForField';
import fileToBase64 from 'files/fileToBase64';
import NotificationType from 'notifications/models/NotificationType';
import CreateNotification from 'channels/models/CreateNotification';
import ShowChannel from 'channels/models/ShowChannel';
import Accordion, { AccordionItem } from '@ingka/accordion';
import CountryCheckboxGroup from 'regions/components/CountryCheckboxGroup';
import FormFieldStatus from '@ingka/form-field-status';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';
import { InputLabel } from '@material-ui/core';
import PreviewButton from './PreviewButton';

type Props = {
  channel: ShowChannel;
  onSubmit: (notification: CreateNotification) => unknown;
  errors: Array<JSONAPISerializer.ErrorObject>;
  isLoading?: boolean;
  defaultValues?: Partial<
  Pick<
  CreateNotification,
  'parentId' | 'title' | 'headline' | 'description' |
  'signature' | 'countries'
  >
  >;
};

type FormState = CreateNotification & { attachment?: File };

export default function OtherNotificationForm({
  defaultValues = {},
  onSubmit,
  errors,
  channel,
  isLoading = false,
}: Props) {
  const notificationType = NotificationType.OtherInformation;
  const [state, setFormState] = useState<FormState>({
    title: defaultValues.title ?? '',
    headline: defaultValues.headline ?? '',
    description: defaultValues.description ?? '',
    signature: defaultValues.signature ?? '',
    countries: defaultValues.countries ?? [],
    parentId: defaultValues.parentId,
    channelId: channel.id,
    notificationType,
  });
  function isValid(attribute: string) {
    return _.isEmpty(errorsForField(errors, attribute).body);
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const { attachment, ...data } = state;

        if (attachment) {
          return fileToBase64(attachment).then((attachmentUrl) => {
            onSubmit({
              ...data,
              attachmentUrl,
              attachmentName: attachment.name,
            } as CreateNotification);
          });
        }

        return onSubmit({ ...data } as CreateNotification);
      }}
    >
      <style>
        {
        `
        ol [class^="ql-indent-"] {
        list-style-type: disc !important;
        list-style-position: inside !important;
        }

        /* Apply styles to pseudo-elements of all classes starting with "ql-indent-" */
        ol [class^="ql-indent-"]::before {
        content: "" !important;
      }
        
        `
        }
      </style>
      <Stack space={Space.Space300}>
        <InputField
          onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
          id="other-title"
          name="title"
          type="text"
          label="Title"
          req
          validation={errorsForField(errors, 'title')}
          valid={isValid('title')}
          shouldValidate={!isValid('title')}
          value={state.title}
          onChange={(ev) => setFormState({ ...state, title: ev.target.value })}
        />
        <InputField
          onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
          id="other-headline"
          name="headline"
          type="text"
          label="Headline"
          req
          validation={errorsForField(errors, 'headline')}
          valid={isValid('headline')}
          shouldValidate={!isValid('headline')}
          value={state.headline}
          onChange={(ev) => setFormState({ ...state, headline: ev.target.value })}
        />
        <InputLabel title="other-desc" className="input-field" style={{ color: 'black' }}>Description</InputLabel>
        <div aria-label="Description">
          <ReactQuill
            style={{ height: '10vw', fontSize: '16px', fontFamily: 'Noto IKEA, Noto Sans, Roboto, Open Sans, sans-serif' }}
            theme="snow"
            id="other-description"
            onChange={(description) => setFormState({ ...state, description })}
            value={state.description}
            modules={
              { toolbar: [[{ header: [false, 1, 2, 3] }], ['bold', 'italic', 'underline', 'strike'], [{ align: [] }], [{ list: 'bullet' }], [{ list: 'ordered' }], ['link'], [{ script: 'sub' }, { script: 'super' }], [{ indent: '+1' }, { indent: '-1' }], ['clean']] }
            }
          />
        </div>
        <FileInput
          id="other-attachment"
          name="attachment"
          label="Attachment (optional)"
          validation={errorsForField(errors, 'attachmentUrl')}
          valid={isValid('attachmentUrl')}
          onChange={(ev) => setFormState({ ...state, attachment: _.first(ev.target.files) })}
        />

        <TextArea
          id="other-signature"
          name="signature"
          label="Signature"
          validation={errorsForField(errors, 'signature')}
          valid={isValid('signature')}
          shouldValidate={!isValid('signature')}
          value={state.signature}
          onChange={(ev) => setFormState({ ...state, signature: ev.target.value })}
        />

        <Accordion>
          <AccordionItem id="issue-countries" title="Regions and Countries">
            <CountryCheckboxGroup
              onChange={(newCountries) => setFormState({ ...state, countries: newCountries })}
              defaultChecked={false}
              selectedCountries={state.countries}
            />
          </AccordionItem>
        </Accordion>

        <FormFieldStatus valid={isValid('countries')} shouldValidate={!isValid('countries')} validation={errorsForField(errors, 'countries')} />
        <FormFieldStatus valid={isValid('description')} shouldValidate={!isValid('description')} validation={errorsForField(errors, 'description')} />
        <Inline space={Space.Space100}>
          <PreviewButton notification={state} channel={channel} />
          <Button text="Send now" htmlType="submit" type="emphasised" loading={isLoading} />
        </Inline>
      </Stack>
    </form>
  );
}

OtherNotificationForm.defaultProps = {
  defaultValues: {},
  isLoading: false,
};
