import Configuration from 'configuration/models/Configuration';
import BackendClient from 'http/services/BackendClient';
import { deserialize } from 'json-api';
import { JSONAPIDocument } from 'json-api-serializer';
import Region from 'regions/models/Region';
import ShowRegion from 'regions/models/ShowRegion';

export default class RegionService {
  constructor(private client: BackendClient) {}

  async findAll(): Promise<Region[]> {
    return this.client
      .get<JSONAPIDocument>(`${Configuration.get('API_BASE_URL')}/regions`)
      .then((response) => deserialize('region', response.data));
  }

  async findById(id: string): Promise<ShowRegion> {
    const url = `${Configuration.get('API_BASE_URL')}/regions/${id}`;

    return this.client
      .get<JSONAPIDocument>(url)
      .then((response) => deserialize<ShowRegion>('region', response.data));
  }
}
