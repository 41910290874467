import PropTypes from 'prop-types';
import React, { ReactChild } from 'react';
import Space from '../models/Space';

interface Props {
  children: ReactChild | ReactChild[];

  space?: Space;
}

const Inline = ({ children, space }: Props) => {
  const className = `inline--space-${space}`;

  return <div className={className}>{children}</div>;
};

Inline.defaultProps = {
  children: [],
  space: Space.None,
};

Inline.propTypes = {
  space: PropTypes.oneOf(Object.values(Space)),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Inline;
