import UserState from 'profile/models/UserState';
import SortOrder from 'search/models/SortOrder';
import UserSearchParameters from './UserSearchParameters';

export default function UserSelectSearchParams(state: UserState): UserSearchParameters {
  return {
    page: state.nextPage,
    userQuery: state.userQuery,
    sortBy: 'fullName' as const,
    sortOrder: SortOrder.Descending,
  };
}
