import Button from '@ingka/button';
import React, { useState } from 'react';
import {
  Inline, Space, Stack,
} from 'ui';
import { useHistory } from 'react-router-dom';
import JSONAPISerializer from 'json-api-serializer';
import errorsForField from 'validation/errorsForField';
import User from 'users/models/User';
import PrivilegeCheckboxGroup from './PrivilegeCheckboxGroup';
import UserStatusForm from './UserStatusForm';

type Props = {
  onSubmit: (user: User) => unknown;
  errors: Array<JSONAPISerializer.ErrorObject>;
  isLoading? : boolean;
  defaultValues?: Partial<
  Pick< User,
  | 'privileges'
  | 'id'
  | 'email'
  | 'fullName'
  | 'nickname'
  | 'isActive'
  >
  >;
};

type FormState = User;

export default function UserForm({
  onSubmit,
  errors,
  isLoading = false,
  defaultValues = {},
}: Props) {
  const [state, setFormState] = useState<FormState>({
    privileges: defaultValues.privileges ?? [],
    id: defaultValues.id ?? '',
    email: defaultValues.email ?? '',
    nickname: defaultValues.nickname ?? '',
    fullName: defaultValues.fullName ?? '',
    isActive: defaultValues.isActive ?? true,

  });
  const history = useHistory();

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const { ...data } = state;
        return onSubmit({ ...data } as User);
      }}
    >
      <Stack space={Space.Space150}>
        <UserStatusForm
          user={state}
          onChange={(updatedStatus) => setFormState({ ...state, isActive: updatedStatus })}
          setStatus={setFormState}
        />
      </Stack>
      <Stack space={Space.Space150}>
        <div style={state.isActive ? {} : { pointerEvents: 'none', opacity: '0.4' }}>
          <PrivilegeCheckboxGroup
            onChange={(newPrivileges) => setFormState({ ...state, privileges: newPrivileges })}
            defaultChecked={false}
            validation={errorsForField(errors, 'user')}
            selectedPrivilege={state.privileges}
          />
        </div>
      </Stack>
      <Stack space={Space.Space200}>
        <Inline space={Space.Space150}>
          <Button text="Cancel" htmlType="button" onClick={() => history.goBack()} />
          <Button text="Save" htmlType="submit" type="emphasised" loading={isLoading} />
        </Inline>
      </Stack>
    </form>
  );
}

UserForm.defaultProps = {
  defaultValues: {},
  isLoading: false,
};
