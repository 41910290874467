/**
 * Map array values matching the given predicate function. Leave the rest as
 * is.
 *
 * @Example
 *
 *   const array = [1, 2, 3, 4, 5];
 *   const isOdd = (num) => num % 2 !== 0;
 *   const multiply = (num) => num * 2;
 *
 *   mapMatching(array, isOdd, multiply);
 *   //=> [2, 2, 6, 4, 10];
 */
export default function mapMatching<SourceType, TargetType>(
  array: Array<SourceType>,
  isMatch: (item: SourceType) => boolean,
  callback: (item: SourceType) => TargetType,
): Array<TargetType | SourceType> {
  return array.map((item) => {
    if (isMatch(item)) {
      return callback(item);
    }

    return item;
  });
}
