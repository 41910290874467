import React from 'react';
import useBackendService from 'services/useBackendService';
import UserService from 'users/services/UserService';
import ErrorPage from 'errors/components/ErrorPage';
import Loading, { LoadingBall } from '@ingka/loading';
import useAsyncResource from 'http/useAsyncResource';
import { useHistory } from 'react-router-dom';
import Button from '@ingka/button';
import arrowLeftIconPath from '@ingka/ssr-icon/paths/arrow-left';
import { Space, Stack } from 'ui';
import Profile from './Profile';

export default function ShowProfile() {
  const userService = useBackendService(UserService);
  const [user, error] = useAsyncResource(() => userService.findCurrentUser());
  const history = useHistory();

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (user) {
    return (
      <Stack space={Space.Space400}>
        <Button onClick={() => history.goBack()} type="tertiary" ssrIcon={arrowLeftIconPath} />
        <Profile user={user} />
      </Stack>
    );
  }

  return (
    <Loading text="Loading...">
      <LoadingBall />
    </Loading>
  );
}
