import React from 'react';
import NotificationModel from 'notifications/models/Notification';
import _ from 'lodash';
import Timeline from 'timeline/components/Timeline';
import { format } from 'date-fns';
import TimelineSeparator from 'timeline/components/TimelineSeparator';
import Notification from './Notification';

type Props = {
  notifications: NotificationModel[];
};

export default function ChannelNotifications({ notifications }: Props) {
  if (_.isEmpty(notifications)) {
    return <p>Nothing to see here yet! When new notifications are sent they will be shown here.</p>;
  }

  const getMonthAndYear = ({ createdAt }: NotificationModel) => format(createdAt, 'LLLL yyyy');
  const notificationsByMonth = _.groupBy(notifications, getMonthAndYear);

  return (
    <Timeline>
      {Object.entries(notificationsByMonth).map(([heading, groupNotifications]) => (
        <React.Fragment key={heading}>
          <TimelineSeparator text={heading} />
          {groupNotifications.map((notification) => (
            <Notification notification={notification} key={notification.id} />
            // <div dangerouslySetInnerHTML={{ __html: notification.description }} />
          ))}
        </React.Fragment>
      ))}
    </Timeline>
  );
}
