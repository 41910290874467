import Button from '@ingka/button';
import arrowLeftIconPath from '@ingka/ssr-icon/paths/arrow-left';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import Toast from '@ingka/toast';
import channelNotificationsPath from 'channels/channelNotificationsPath';
import CreateNotification from 'channels/models/CreateNotification';
import ShowChannel from 'channels/models/ShowChannel';
import ErrorPage from 'errors/components/ErrorPage';
import HttpStatusCode from 'http/HttpStatusCode';
import JSONAPISerializer from 'json-api-serializer';
import _ from 'lodash';
import NotificationService from 'notifications/services/NotificationService';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useBackendService from 'services/useBackendService';
import { Space, Stack } from 'ui';
import Inline from 'ui/components/Inline';
import IssueNotificationForm from './IssueNotificationForm';
import OtherNotificationForm from './OtherNotificationForm';
import ReleaseNotificationForm from './ReleaseNotificationForm';
import ServiceWindowNotificationForm from './ServiceWindowNotificationForm';

type Props = {
  channel: ShowChannel;
};

export default function NewChannelNotification({ channel }: Props) {
  const history = useHistory();
  const [validationErrors, setValidationErrors] = useState<JSONAPISerializer.ErrorObject[]>([]);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [toast, setToast] = useState('');
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [state, setState] = useState<'IDLE' | 'SENDING'>('IDLE');
  const isLoading = state === 'SENDING';

  const notificationService = useBackendService(NotificationService);
  const createAndSend = (notification: CreateNotification) => {
    setState('SENDING');
    notificationService
      .createAndSend(notification)
      .then(() => {
        setState('IDLE');
        setRedirectPath(channelNotificationsPath(channel));
      })
      .catch((err) => {
        setState('IDLE');

        if (err.response.status === HttpStatusCode.BadRequest) {
          setToast('Notification could not be sent.');
          setValidationErrors(_.get(err.response, ['data', 'errors']) ?? []);
          return;
        }

        setError(err);
      });
  };

  if (redirectPath) {
    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { toastMessage: 'Notification sent to subscribers!' },
        }}
      />
    );
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  const tabs = [
    <Tab key="release" text="Release" className="tabs__tab--release" tabPanelId="release" active />,
    <Tab
      key="serviceWindow"
      text="Service Window"
      className="tabs__tab--service-window"
      tabPanelId="service-window"
    />,
    <Tab key="issue" text="Issue" className="tabs__tab--issue" tabPanelId="issue" />,
    <Tab key="other" text="Other" className="tabs__tab--other" tabPanelId="other" />,
  ];

  const tabPanels = [
    <TabPanel key="release" tabPanelId="release" data-testid="release-panel">
      <ReleaseNotificationForm
        channel={channel}
        errors={validationErrors}
        onSubmit={createAndSend}
        defaultValues={{ countries: channel.countries }}
        isLoading={isLoading}
      />
    </TabPanel>,
    <TabPanel key="serviceWindow" tabPanelId="service-window" data-testid="service-window-panel">
      <ServiceWindowNotificationForm
        channel={channel}
        errors={validationErrors}
        onSubmit={createAndSend}
        defaultValues={{ countries: channel.countries }}
      />
    </TabPanel>,
    <TabPanel key="issue" tabPanelId="issue" data-testid="issue-panel">
      <IssueNotificationForm
        channel={channel}
        errors={validationErrors}
        onSubmit={createAndSend}
        defaultValues={{ countries: channel.countries }}
      />
    </TabPanel>,
    <TabPanel key="other" tabPanelId="other" data-testid="other-panel">
      <OtherNotificationForm
        channel={channel}
        errors={validationErrors}
        onSubmit={createAndSend}
        defaultValues={{ countries: channel.countries }}
      />
    </TabPanel>,
  ];

  return (
    <>
      <Stack space={Space.Space300}>
        <Inline>
          <Button onClick={() => history.goBack()} type="tertiary" ssrIcon={arrowLeftIconPath} />
        </Inline>

        <h1>
          {channel.name}
          {' '}
          notification form
        </h1>

        <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="release" />
      </Stack>

      <Toast
        isOpen={!_.isEmpty(toast)}
        text={toast}
        onTransitionEnd={() => {}}
        onCloseRequest={() => setToast('')}
      />
    </>
  );
}
