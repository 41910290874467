import Button from '@ingka/button';
import React, { useState } from 'react';
import {
  Inline, Space, Stack,
} from 'ui';
import { useHistory } from 'react-router-dom';
import Country from 'regions/models/Country';
import { TextField } from '@material-ui/core';

type Props = {
  onSubmit: (country: Country) => unknown;
  defaultValues?: Partial<
  Pick< Country,
  | 'name'
  | 'id'
  | 'code'
  | 'regionId'
  >
  >;
};

type FormState = Country;

export default function CountryForm({
  onSubmit,
  defaultValues = {},
}: Props) {
  const [state, setFormState] = useState<FormState>({
    name: defaultValues.name ?? '',
    id: defaultValues.id ?? '',
    code: defaultValues.code ?? '',
    regionId: defaultValues.regionId ?? '',
  });
  const history = useHistory();

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const { ...data } = state;
        return onSubmit({ ...data } as Country);
      }}
    >

      <Stack space={Space.Space300}>
        <TextField
          id="country-name"
          name="name"
          label="Country name"
          value={state.name}
          onChange={(ev) => setFormState({ ...state, name: ev.target.value })}
        />
        <TextField
          id="country-code"
          name="code"
          label="Country code"
          value={state.code}
          onChange={(ev) => setFormState({ ...state, code: ev.target.value })}
        />

        <Inline space={Space.Space100}>
          <Button text="Cancel" htmlType="button" onClick={() => history.goBack()} />
          <Button text="Save" htmlType="submit" type="emphasised" />
        </Inline>
      </Stack>
    </form>
  );
}

CountryForm.defaultProps = {
  defaultValues: {},
};
