import NotificationStatus from './models/NotificationStatus';

export default function toFriendlyNotificationStatus(status: NotificationStatus): string {
  let neverStatus: never;
  switch (status) {
    case NotificationStatus.Solved:
      return 'Solved';
    case NotificationStatus.InProgress:
      return 'In progress';
    default:
      neverStatus = status;
      throw new Error(`${neverStatus} is not a valid status.`);
  }
}
