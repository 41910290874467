import React from 'react';
import arrowForward from '@ingka/ssr-icon/paths/arrow-right';
import SSRIcon from '@ingka/ssr-icon';
import AspectRatioImage, { AspectRatioImageProps } from '@ingka/aspect-ratio-image';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  body: string;
  href: string;
  ssrIcon?: (...args: any[]) => any;
  aspectRatioImageProps?: AspectRatioImageProps;
  backgroundColor: string;
};

/**
 * The Card component from Ingka does not provide possiblity to use react router links.
 * This simplified component acts as a substitute.
 */
export default function EmphasisedCard({
  title,
  body,
  href,
  aspectRatioImageProps,
  backgroundColor,
  ssrIcon,
}: Props) {
  return (
    <Link
      className="card card--emphasised card--compact card--trailing-image height--100"
      to={href}
      style={{ backgroundColor }}
    >
      <div className="card__image">
        {aspectRatioImageProps ? (
          <AspectRatioImage src={aspectRatioImageProps.src} alt={aspectRatioImageProps.alt} />
        ) : (
          ''
        )}
      </div>

      <div className="card__info">
        <div className="card__text-wrapper">
          <h2 className="card__title">{title}</h2>
          <p className="card__body">{body}</p>
        </div>

        <span className="card__cta">
          <span className="card__btn btn btn--icon-primary-inverse">
            <span className="btn__inner">
              <SSRIcon paths={ssrIcon} />
            </span>
          </span>
        </span>
      </div>
    </Link>
  );
}

EmphasisedCard.defaultProps = {
  aspectRatioImageProps: null,
  ssrIcon: arrowForward,
};
