import Device from 'devices/models/Device';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import useEffectOnce from 'hooks/useEffectOnce';
import { useState } from 'react';
import FIREBASE_CONFIG from './FIREBASE_CONFIG';
import PUBLIC_VAPID_KEY from './PUBLIC_VAPID_KEY';

type ReturnType =
  | { state: 'LOADING'; registrationToken: undefined }
  | { state: 'GRANTED'; registrationToken: string }
  | { state: 'NOT_DESIRED'; registrationToken: undefined }
  | { state: 'FAILED'; registrationToken: undefined };

export default function usePushNotifications(device = new Device()): ReturnType {
  const app = initializeApp(FIREBASE_CONFIG);
  const messaging = getMessaging(app);
  const [returnValue, setReturnValue] = useState<ReturnType>({
    state: 'LOADING',
    registrationToken: undefined,
  });

  useEffectOnce(() => {
    if (device.isMobile) {
      getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY })
        .then((registrationToken) => setReturnValue({ registrationToken, state: 'GRANTED' }))
        .catch(() => setReturnValue({ registrationToken: undefined, state: 'FAILED' }));
    }

    return setReturnValue({ state: 'NOT_DESIRED', registrationToken: undefined });
  });

  return returnValue;
}
