import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ShowChannel from 'channels/models/ShowChannel';
import AdminNotificationRouter from './AdminNotificationRouter';
import NotificationTypeTabs from './NotificationTypeTabs';

type Props = {
  channel: ShowChannel;
};

export default function AdminNotificationsRouter({ channel }: Props) {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match?.path}/:id`}>
        <AdminNotificationRouter
          channel={channel}
        />
      </Route>
      <Route path={`${match?.path}`}>
        <NotificationTypeTabs
          channel={channel}
        />
      </Route>
    </Switch>
  );
}
