import _ from 'lodash';
import Category from './models/Category';

export default function parseCategories(json: string | null): Category[] {
  const categories = JSON.parse(json || '[]');
  const validCategories = Object.values(Category);

  if (_.isArray(categories)) {
    return _.intersection(categories, validCategories);
  }

  return [];
}
