import { User } from '@auth0/auth0-react/dist/auth-state';
import { AxiosResponse } from 'axios';
import Configuration from 'configuration/models/Configuration';
import BackendClient from 'http/services/BackendClient';
import { deserialize, serialize } from 'json-api';
import { JSONAPIDocument } from 'json-api-serializer';
import compact from 'objects/compact';
import Country from 'regions/models/Country';
import ShowUser from 'users/models/ShowUser';
import UserIndexResponse from 'users/models/UserIndexResponse';
import UserSearchParameters from 'users/models/UserSearchParameters';
import UserSearchResult from 'users/models/UserSearchResult';

export default class UserService {
  constructor(private client: BackendClient) {}

  async findCurrentUser(): Promise<ShowUser> {
    const url = `${Configuration.get('API_BASE_URL')}/user/get-user`;

    return this.client
      .get<JSONAPIDocument>(url, {})
      .then((response) => deserialize<ShowUser>('user', response.data));
  }

  async findById(id: string): Promise<ShowUser> {
    const url = `${Configuration.get('API_BASE_URL')}/user/${id}`;

    return this.client
      .get<JSONAPIDocument>(url)
      .then((response) => deserialize<ShowUser>('user', response.data));
  }

  async search(params: UserSearchParameters): Promise<UserSearchResult> {
    if (params.page) {
      return this.client
        .get<UserIndexResponse>(params.page)
        .then(UserService.deserializeSearchResult);
    }

    const url = `${Configuration.get('API_BASE_URL')}/user/get-all-users`;
    const queryParams = {
      query: params.userQuery,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
    };
    return this.client
      .get<UserIndexResponse>(url, { params: queryParams })
      .then(UserService.deserializeSearchResult);
  }

  async patch(properties: {
    subscribedCountries?: Country[]; deviceToken?: string
  }) {
    const url = `${Configuration.get('API_BASE_URL')}/user`;

    return this.client.patch(url, serialize('user', compact(properties)));
  }

  async synchronizeInfo(user: User) {
    const url = `${Configuration.get('API_BASE_URL')}/user`;

    return this.client.put(url, serialize('user', user));
  }

  async delete(id: string): Promise<ShowUser> {
    const url = `${Configuration.get('API_BASE_URL')}/user/${id}`;

    return this.client
      .delete<JSONAPIDocument>(url)
      .then((response) => deserialize('user', response.data));
  }

  async update(user: User & { id: string }): Promise<ShowUser> {
    const url = `${Configuration.get('API_BASE_URL')}/user/${user.id}`;

    return this.client
      .put<JSONAPIDocument>(url, serialize('user', user))
      .then((response) => deserialize('user', response.data));
  }

  static deserializeSearchResult = (response: AxiosResponse<UserIndexResponse>) => ({
    nextPage: response.data.links.next,
    users: deserialize<User[]>('user', response.data),
  });
}
