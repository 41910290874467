import INITIAL_APPROVAL_FORM_STATE from './INITIAL_APPROVAL_FORM_STATE';
import ApprovalFormState from './models/ApprovalFormState';
import ShowChannel from './models/ShowChannel';

export default function getInitialApprovalFormState(
  channel?: Partial<ShowChannel>,
): ApprovalFormState {
  if (channel) {
    return {
      ...INITIAL_APPROVAL_FORM_STATE,
      ...channel,
      ownersText: channel?.owners?.map((owner) => owner.email).join(', ') ?? '',
      publishersText: channel?.publishers?.map((publisher) => publisher.email).join(', ') ?? '',
      thumbnailUrl: '',
    };
  }

  return INITIAL_APPROVAL_FORM_STATE;
}
