import ShowChannel from 'channels/models/ShowChannel';
import Notification from 'notifications/models/Notification';
import Country from 'regions/models/Country';
import NotificationSeverity from './NotificationSeverity';
import NotificationStatus from './NotificationStatus';
import NotificationType from './NotificationType';

export default class NotificationDto {
  id: string;

  title: string;

  status?: NotificationStatus;

  notificationType: NotificationType;

  impact?: string;

  channel: ShowChannel;

  headline: string;

  notifications: NotificationDto[];

  attachmentName?: string;

  attachmentUrl?: string;

  description: string;

  signature: string;

  severity?: NotificationSeverity;

  createdAt: string;

  releasedAt?: string;

  startsAt?: string;

  endsAt?: string;

  url?: string;

  countries: Country[];

  constructor(properties: NotificationDto) {
    this.title = properties.title;

    this.createdAt = properties.createdAt;

    this.severity = properties.severity;

    this.attachmentUrl = properties.attachmentUrl;

    this.attachmentName = properties.attachmentName;

    this.signature = properties.signature;

    this.description = properties.description;

    this.attachmentUrl = properties.attachmentUrl;

    this.impact = properties.impact;

    this.status = properties.status;

    this.notificationType = properties.notificationType;

    this.channel = properties.channel;

    this.id = properties.id;

    this.headline = properties.headline;

    this.notifications = properties.notifications ?? [];

    this.url = properties.url;

    this.releasedAt = properties.releasedAt;

    this.startsAt = properties.startsAt;

    this.endsAt = properties.endsAt;

    this.countries = properties.countries;
  }

  deserialize(): Notification {
    return new Notification({
      ...this,
      createdAt: new Date(this.createdAt),
      releasedAt: this.releasedAt ? new Date(this.releasedAt) : undefined,
      startsAt: this.startsAt ? new Date(this.startsAt) : undefined,
      endsAt: this.endsAt ? new Date(this.endsAt) : undefined,
      notifications: this.notifications.map((dto) => new NotificationDto(dto).deserialize()),
    });
  }
}
