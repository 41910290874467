import React from 'react';
import _ from 'lodash';
import { User } from '@auth0/auth0-react/dist/auth-state';
import ShowChannel from 'channels/models/ShowChannel';
import { Link } from 'react-router-dom';
import InlineMessage from '@ingka/inline-message';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import editChannePath from 'channels/editChannePath';

export default function OwnerBanner({
  owners,
  channel,
  user,
}: {
  user: User;
  channel: ShowChannel;
  owners: User[];
}) {
  if (_.some(owners, (owner) => owner.id.toLowerCase() === user.id.toLowerCase())) {
    return (
      <InlineMessage
        ssrIcon={informationCircle}
        body={(
          <span>
            {' '}
            You are the owner of this channel.
            {' '}
            <Link to={editChannePath(channel)}>Click here to edit this channel</Link>
          </span>
        )}
      />
    );
  }

  return <></>;
}
