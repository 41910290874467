enum ColumnSize {
  Full = 12,
  FiveSixths = 10,
  ThreeFourths = 9,
  TwoThirds = 8,
  Half = 6,
  Third = 4,
}

export default ColumnSize;
