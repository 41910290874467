import Loading, { LoadingBall } from '@ingka/loading';
import { CheckboxGroup } from 'ui';
import React, { useEffect, useState } from 'react';
import Country from 'regions/models/Country';
import Region from 'regions/models/Region';
import RegionService from 'regions/services/RegionService';
import useBackendService from 'services/useBackendService';
import ErrorPage from 'errors/components/ErrorPage';
import { CheckboxGroupProps } from 'ui/components/CheckboxGroup';

type Props = {
  selectedCountries: Pick<Country, 'id'>[];
  onChange: (countries: Country[]) => unknown;
  defaultChecked?: boolean;
  valid?: CheckboxGroupProps['valid'];
  validation?: CheckboxGroupProps['validation'];
  shouldValidate?: CheckboxGroupProps['shouldValidate'];
};

export default function CountryCheckboxGroup({
  selectedCountries,
  defaultChecked = true,
  valid = true,
  shouldValidate = false,
  validation,
  onChange = () => {},
}: Props) {
  const [regions, setRegions] = useState<Region[]>([]);
  const [state, setState] = useState('INITIAL');
  const [error, setError] = useState<Error | undefined>(undefined);
  const regionService = useBackendService(RegionService);
  const countries = regions.flatMap((region) => region.countries);

  const getCheckedIds = (list: CheckboxGroupProps['list'], ids: string[] = []): string[] => list.flatMap((item: any) => {
    if (item.list) {
      return [...ids, ...getCheckedIds(item.list, ids)];
    }

    if (item.checked) {
      return [...ids, item.id];
    }

    return [];
  });

  useEffect(() => {
    if (state === 'FETCHED_REGIONS' && defaultChecked) {
      onChange(countries);
      setState('DEFAULTED_VALUES');
    }
  }, [defaultChecked, onChange, regions, state, countries]);

  useEffect(() => {
    if (state === 'INITIAL') {
      setState('FETCHING_REGIONS');
      regionService
        .findAll()
        .then((newRegions) => {
          setRegions(newRegions);
          setState('FETCHED_REGIONS');
        })
        .catch((err) => {
          setError(err);
          setState('FAILED_FETCHING_REGIONS');
        });
    }
  }, [regionService, state]);

  if (state === 'FETCHING_REGIONS') {
    return (
      <Loading text="Loading regions...">
        <LoadingBall />
      </Loading>
    );
  }

  if (state === 'FAILED_FETCHING_REGIONS') {
    return <ErrorPage error={error ?? new Error()} />;
  }

  return (
    <CheckboxGroup
      valid={valid}
      validation={validation}
      shouldValidate={shouldValidate}
      onChange={(list) => {
        const selectedIds = getCheckedIds(list);
        const newCountries = countries.filter((country) => selectedIds.includes(country.id));
        onChange(newCountries);
      }}
      list={[
        {
          id: 'global',
          label: 'Global',
          list: regions.map((region) => ({
            id: region.id,
            label: region.name,
            list: region.countries.map((country) => ({
              id: country.id,
              label: country.name,
              checked: selectedCountries.some((c) => c.id === country.id),
              onChange: () => {},
            })),
          })),
        },
      ]}
    />
  );
}

CountryCheckboxGroup.defaultProps = {
  defaultChecked: true,
  valid: true,
  shouldValidate: false,
  validation: {},
};
