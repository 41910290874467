import {
  useRouteMatch, Switch, Route, useLocation,
} from 'react-router-dom';
import React, { useReducer } from 'react';
import withAuthenticationRequired from 'authentication/withAuthenticationRequired';
import Channels from './Channels';
import channelsReducer from '../channelsReducer';
import ChannelsState from '../models/ChannelsState';
import ChannelAction from '../models/ChannelAction';
import getInitialState from '../getInitialState';
import parseCategories from '../parseCategories';
import NewChannel from './NewChannel';
import ChannelRouter from './ChannelRouter';

function ChannelsRouter() {
  const match = useRouteMatch();
  const queryParams = new URLSearchParams(useLocation().search);
  const query = queryParams.get('query') || '';
  const categories = parseCategories(queryParams.get('filter[categories]'));
  const [state, dispatch] = useReducer(
    (prevState: ChannelsState, action: ChannelAction) => channelsReducer(prevState, action),
    getInitialState(query, categories, []),
  );

  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        <NewChannel />
      </Route>

      <Route path={`${match.path}/:id`}>
        <ChannelRouter />
      </Route>

      <Route path={match.path}>
        <Channels state={state} dispatch={dispatch} />
      </Route>
    </Switch>
  );
}

export default withAuthenticationRequired(ChannelsRouter);
