import React from 'react';
import NotificationModel from 'notifications/models/Notification';
import Accordion, { AccordionItem } from '@ingka/accordion';
import {
  Column, ColumnSize, Row, Space, Stack,
} from 'ui';
import { format } from 'date-fns';
import ReactHtmlParser from 'react-html-parser';
import TimelineEntry from 'timeline/components/TimelineEntry';
import ColorName from 'ui/models/ColorName';
import NotificationAttribute from './NotificationAttribute';
import AttachmentNotificationAttribute from './AttachmentNotificationAttribute';

type Props = {
  notification: NotificationModel;
};

export default function ServiceWindowNotification({ notification }: Props) {
  return (
    <TimelineEntry color={ColorName.NotificationTypeServiceWindow}>
      <Accordion>
        <AccordionItem
          id={notification.id}
          title={notification.title}
          caption={format(notification.createdAt, 'yyyy-MM-dd HH:mm')}
        >
          <Stack space={Space.Space300}>
            <Row>
              <Column size={{ s: ColumnSize.Half }}>
                <NotificationAttribute label="Title">{notification.title}</NotificationAttribute>
              </Column>

              <Column size={{ s: ColumnSize.Half }}>
                <NotificationAttribute label="Headline">
                  {notification.headline}
                </NotificationAttribute>
              </Column>
            </Row>

            <Row>
              <Column size={{ s: ColumnSize.Full }}>
                <NotificationAttribute label="Description">
                  <p
                    style={{
                      margin: '30px auto', lineHeight: '1.5rem', border: '2px solid lightGray', borderRadius: '5px', padding: '20px',
                    }}
                  >
                    {ReactHtmlParser(notification.description)}
                  </p>
                </NotificationAttribute>
              </Column>

              <Column size={{ s: ColumnSize.Half }}>
                <AttachmentNotificationAttribute notification={notification} />
              </Column>
            </Row>

            <Row>
              <Column size={{ s: ColumnSize.Half }}>
                <NotificationAttribute label="Start date">
                  {notification.startsAt && format(notification.startsAt, 'yyyy-MM-dd HH:mm')}
                </NotificationAttribute>
              </Column>

              <Column size={{ s: ColumnSize.Half }}>
                <NotificationAttribute label="End date">
                  {notification.endsAt && format(notification.endsAt, 'yyyy-MM-dd HH:mm')}
                </NotificationAttribute>
              </Column>
            </Row>

            <Row>
              <Column size={{ s: ColumnSize.Half }}>
                <NotificationAttribute label="Impact">{notification.impact}</NotificationAttribute>
              </Column>

              <Column size={{ s: ColumnSize.Half }}>
                <NotificationAttribute label="Signature">
                  {notification.signature}
                </NotificationAttribute>
              </Column>
            </Row>
          </Stack>
        </AccordionItem>
      </Accordion>
    </TimelineEntry>
  );
}
