import JSONAPISerializer from 'json-api-serializer';
import { Space, Stack } from 'ui';
import _ from 'lodash';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import adminPagePath from 'admin/services/adminPagePath';
import useBackendService from 'services/useBackendService';
import ShowUser from 'users/models/ShowUser';
import UserService from 'users/services/UserService';
import Toast from '@ingka/toast';
import UserForm from './UserForm';

export default function EditUser({
  user,
  editedUser,
  setUser,
}: {
  user: ShowUser;
  editedUser: ShowUser | undefined;
  setUser: React.Dispatch<React.SetStateAction<ShowUser | undefined>>;
}) {
  const userService = useBackendService(UserService);
  const [errors, setErrors] = useState<JSONAPISerializer.ErrorObject[]>([]);
  const { id } = user;
  const [toastText, setToastText] = useState('');
  const [redirectPath, setRedirectPath] = useState<string | any>();
  const [state, setState] = useState<'IDLE' | 'SENDING'>('IDLE');
  const isLoading = state === 'SENDING';

  if (redirectPath && editedUser) {
    return (
      <Redirect
        to={{ pathname: redirectPath, state: { toastMessage: ' User updated successfully!' } }}
      />
    );
  }

  return (
    <>
      <Stack space={Space.Space200}>
        <Stack space={Space.Space50}>
          <h2>{`Updating the status and privileges for '${user.fullName}'`}</h2>
        </Stack>
        <UserForm
          errors={errors}
          isLoading={isLoading}
          onSubmit={(formUser) => {
            setState('SENDING');
            userService
              .update({ ...formUser, id })
              .then((updatedUser) => {
                setState('IDLE');
                setUser(updatedUser);
                setRedirectPath(adminPagePath());
              })
              .catch((error) => {
                setState('IDLE');
                setErrors(_.get(error.response, ['data', 'errors']) ?? []);
                setToastText('Could not update user.');
              });
          }}
          defaultValues={{
            privileges: user.privileges,
            isActive: user.isActive,
          }}
        />
      </Stack>
      <Toast
        isOpen={!_.isEmpty(toastText)}
        text={toastText}
        onTransitionEnd={() => { }}
        onCloseRequest={() => setToastText('')}
      />
    </>

  );
}
