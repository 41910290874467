import SSRIcon from '@ingka/ssr-icon';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  text: string;

  to: string;

  icon: (...args: any[]) => any;
};

export default function IconLink({ icon, text, to }: Props) {
  return (
    <Link to={to} role="button" className="btn btn--icon-tertiary">
      <span className="btn__inner" title={text}>
        <SSRIcon paths={icon} colour="grey" />
        <span className="btn__label">{text}</span>
      </span>
    </Link>
  );
}
