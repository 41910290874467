import React from 'react';
import useAsyncResource from 'http/useAsyncResource';
import useBackendService from 'services/useBackendService';
import TemplateService from 'notifications/services/TemplateService';
import Stack from 'ui/components/Stack';
import { Space } from 'ui';
// import IFrame from 'dom/components/IFrame';
import Loading, { LoadingBall } from '@ingka/loading';
import ErrorPage from 'errors/components/ErrorPage';
import PreviewNotification from 'notifications/models/PreviewNotification';
// import DOMPurify, { sanitize } from 'dompurify';
import reactHtmlParser from 'react-html-parser';
import 'react-quill/dist/quill.snow.css';

type Props = {
  notification: PreviewNotification;
};

export default function EmailPreview({ notification }: Props) {
  const service = useBackendService(TemplateService);
  const [preview, error] = useAsyncResource(async () => service.getEmailPreview(notification));

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (preview) {
    return (
      <Stack space={Space.Space100}>
        <p className="text--align-center">Email</p>
        <div className="background--grey-200 border-radius--200 padding--200">
          <div className="background--white">
            {/* <IFrame title="Email preview">{(preview.htmlContent)}</IFrame> */}
            <div title="Email preview">{reactHtmlParser(preview.htmlContent)}</div>
          </div>
        </div>
      </Stack>
    );
  }

  return (
    <Stack space={Space.Space100}>
      <p className="text--align-center">Email</p>
      <div className="background--grey-200 border-radius--200 padding--200">
        <Loading>
          <LoadingBall />
        </Loading>
      </div>
    </Stack>
  );
}
