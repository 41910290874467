import serialize from 'json-api/serialize';
import ApplicationSerializer from './ApplicationSerializer';

const SERIALIZERS = {
  application: ApplicationSerializer,
  notification: ApplicationSerializer.extend({
    include: ['channel'],
    embed: true,
  }),
  user: ApplicationSerializer.extend({
    serialize(this: any, object) {
      const subscribedChannels = (object.subscriptions.models ?? []).map(
        (sub: any) => sub.channel.attrs,
      );
      const ownedChannels = object.ownedChannels.models.map((c: any) => c.attrs);
      const publishingChannels = object.publishingChannels.models.map((c: any) => c.attrs);
      const subscribedCountries = object.subscribedCountries.models.map((c: any) => c.attrs);
      return serialize('user', {
        ...object.attrs,
        termsOfUseAgreements: object.termsOfUseAgreements.models.map((t: any) => t.attrs),
        ownedChannels,
        publishingChannels,
        subscribedChannels,
        subscribedCountries,
      });
    },
  }),
  channel: ApplicationSerializer.extend({
    include: ['subscription', 'owners', 'publishers'],
    embed: true,
  }),
  region: ApplicationSerializer.extend({
    include: ['countries'],
    embed: true,
  }),
};

export default SERIALIZERS;
