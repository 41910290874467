import Loading, { LoadingBall } from '@ingka/loading';
import { CheckboxGroup } from 'ui';
import React, { useEffect, useState } from 'react';
import useBackendService from 'services/useBackendService';
import ErrorPage from 'errors/components/ErrorPage';
import { CheckboxGroupProps } from 'ui/components/CheckboxGroup';
import Privilege from 'admin/models/Privilege';
import PrivilegeService from 'admin/services/PrivilegeService';

type Props = {
  selectedPrivilege: Pick<Privilege, 'id'>[];
  onChange: (countries: Privilege[]) => unknown;
  defaultChecked?: boolean;
  valid?: CheckboxGroupProps['valid'];
  validation?: CheckboxGroupProps['validation'];
  shouldValidate?: CheckboxGroupProps['shouldValidate'];
};

export default function PrivilegeCheckboxGroup({
  selectedPrivilege,
  defaultChecked = true,
  valid = true,
  shouldValidate = false,
  validation,
  onChange = () => {},
}: Props) {
  const [privileges, setPrivileges] = useState<Privilege[]>([]);
  const [state, setState] = useState('INITIAL');
  const [error, setError] = useState<Error | undefined>(undefined);
  const privilegeService = useBackendService(PrivilegeService);

  const getCheckedIds = (list: CheckboxGroupProps['list'], ids: string[] = []): string[] => list.flatMap((item: any) => {
    if (item.list) {
      return [...ids, ...getCheckedIds(item.list, ids)];
    }

    if (item.checked) {
      return [...ids, item.id];
    }

    return [];
  });

  useEffect(() => {
    if (state === 'FETCHED_PRIVILEGES' && defaultChecked) {
      onChange(privileges);
      setState('DEFAULTED_VALUES');
    }
  }, [defaultChecked, onChange, privileges, state]);

  useEffect(() => {
    if (state === 'INITIAL') {
      setState('FETCHING_PRIVILEGES');
      privilegeService
        .findAll()
        .then((newPrivileges) => {
          setPrivileges(newPrivileges);
          setState('FETCHED_PRIVILEGES');
        })
        .catch((err) => {
          setError(err);
          setState('FAILED_FETCHING_PRIVILEGES');
        });
    }
  }, [privilegeService, state]);

  if (state === 'FETCHING_PRIVILEGES') {
    return (
      <Loading text="Loading ...">
        <LoadingBall />
      </Loading>
    );
  }

  if (state === 'FAILED_FETCHING_PRIVILEGES') {
    return <ErrorPage error={error ?? new Error()} />;
  }

  return (
    <CheckboxGroup
      valid={valid}
      validation={validation}
      shouldValidate={shouldValidate}
      onChange={(list) => {
        const selectedIds = getCheckedIds(list);
        const newPrivileges = privileges.filter((p) => selectedIds.includes(p.id));
        onChange(newPrivileges);
      }}
      list={[
        {
          id: 'All',
          label: 'All',

          list: privileges.map((p) => ({
            id: p.id,
            label: p.userContextType,
            checked: selectedPrivilege.some((c) => c.id === p.id),
            onChange: () => {},
          })),

        },
      ]}
    />
  );
}

PrivilegeCheckboxGroup.defaultProps = {
  defaultChecked: true,
  valid: true,
  shouldValidate: false,
  validation: {},
};
