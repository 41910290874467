import Channel from 'channels/models/Channel';
import ChannelVisibility from 'channels/models/ChannelVisibility';
import { Factory } from 'fishery';

export default Factory.define<Channel>(({ sequence }) => ({
  id: sequence.toString(),
  name: 'iSubscribe',
  description: 'Get the latest news about iSubscribe',
  thumbnailUrl: 'https://via.placeholder.com/200x100',
  createdAt: new Date('2022-03-18T08:51:53.9844748+01:00'),
  updatedAt: new Date('2022-03-18T08:51:53.9844748+01:00'),
  subscriberCount: 100,
  categories: [],
  visibility: ChannelVisibility.Visible,
  approvalStatus: 'Approved',
  HubName: '1',
}));
