import React from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import ErrorPage from 'errors/components/ErrorPage';
import useAsyncResource from 'http/useAsyncResource';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import HttpStatusCode from 'http/HttpStatusCode';
import useBackendService from 'services/useBackendService';
import CountryService from 'regions/services/CountryService';
import AccessForbidden from '../AccessForbidden';
import DeleteCountry from './DeleteCountry';

export default function CountryRouter() {
  const match = useRouteMatch<{ id: string }>('/admin/regions/:regionId/countries/:id');
  const { id } = match?.params ?? { id: '' };
  const countryService = useBackendService(CountryService);
  const [country, error] = useAsyncResource(async () => countryService.findById(id));

  if (error) {
    if (_.get(error, ['response', 'status']) === HttpStatusCode.Forbidden) {
      return <AccessForbidden />;
    }
    return <ErrorPage error={error} />;
  }
  if (country) {
    return (
      <Switch>

        <Route path={`${match?.path}/delete`}>
          <DeleteCountry country={country} />
        </Route>

      </Switch>
    );
  }

  return (
    <Loading text="Loading...">
      <LoadingBall />
    </Loading>
  );
}
