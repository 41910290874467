import { ButtonProps } from '@ingka/button';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  text: string;
  to: string;
  type: ButtonProps['type'];
};

export default function ButtonLink({ to, text, type }: Props) {
  const className = `btn btn--${type}`;

  return (
    <Link to={to} role="button" className={className}>
      <span className="btn__inner">
        <span className="btn__label">{text}</span>
      </span>
    </Link>
  );
}
