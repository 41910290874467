import React, { useEffect, useState } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import ChannelService from 'channels/services/ChannelService';
import ErrorPage from 'errors/components/ErrorPage';
import useAsyncResource from 'http/useAsyncResource';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import useBackendService from 'services/useBackendService';
import EditChannel from 'channels/components/EditChannel';
import DeleteChannel from 'admin/components/channelManagement/DeleteChannel';
import UserService from 'users/services/UserService';
import UserContextType from 'admin/models/UserContextType';
import AdminNotificationsRouter from '../notificationManegement/AdminNotificationsRouter';
import AccessForbidden from '../AccessForbidden';

export default function AdminChannelsRouter() {
  const isAdmin = true;
  const match = useRouteMatch<{ id: string }>('/admin/channels/:id');
  const { id } = match?.params ?? { id: '' };
  const service = useBackendService(ChannelService);
  const [channel, error] = useAsyncResource(async () => service.findById(id));
  const [state, setChannel] = useState(channel);
  const userService = useBackendService(UserService);
  const [user, userError] = useAsyncResource(() => userService.findCurrentUser());
  const hasPermissionForChannels = user?.privileges
    .some((p) => p.userContextType === UserContextType.Channels);
  const hasPermissionForNotification = user?.privileges
    .some((p) => p.userContextType === UserContextType.Notifications);

  useEffect(() => {
    if (state) {
      setChannel(state);
    } else {
      setChannel(channel);
    }
  }, [state, channel]);

  if (error) {
    return <ErrorPage error={error} />;
  }
  if (userError) {
    return <ErrorPage error={userError} />;
  }
  if (state && user) {
    return (
      <Switch>

        <Route exact path={`${match?.path}/edit`}>
          {hasPermissionForChannels
            ? (
              <EditChannel
                channel={state}
                editedChannel={state}
                setChannel={setChannel}
                isAdmin={isAdmin}
              />
            ) : <AccessForbidden />}
        </Route>

        <Route exact path={`${match?.path}/delete`}>
          {hasPermissionForChannels
            ? (
              <DeleteChannel
                channel={state}
              />
            ) : <AccessForbidden />}
        </Route>

        {hasPermissionForNotification
          ? (
            <Route path={`${match?.path}/notifications`}>
              <AdminNotificationsRouter
                channel={state}
              />
            </Route>
          ) : <AccessForbidden />}
      </Switch>
    );
  }
  return (
    <Loading text="Loading...">
      <LoadingBall />
    </Loading>
  );
}
