import Switch from '@ingka/switch';
import React, { Dispatch, SetStateAction } from 'react';
import User from 'users/models/User';

type Props = {
  user: User
  setStatus: Dispatch<SetStateAction<User>>;
  onChange: (active: boolean) => unknown;
};

export default function UserStatusForm({
  user,
  setStatus,
  onChange = () => {},
}: Props) {
  const { isActive } = user;
  return (

    <Switch
      id="user-status"
      checked={isActive}
      label={isActive ? 'Active' : 'Inactive'}
      onChange={() => {
        onChange(isActive);
        const updatedStatus = { ...user, isActive: !isActive };
        setStatus(updatedStatus);
      }}
    />
  );
}
