import React from 'react';
import Status from '@ingka/status';

export type SaveState = 'UNSAVED' | 'SAVING' | 'SAVED' | 'ERROR';

export default function SaveIndicator({ state }: { state: SaveState }) {
  switch (state) {
    case 'UNSAVED':
      return <Status label="Not saved" statusColor="red" statusDotPosition="trailing" />;
    case 'SAVING':
      return <Status label="Saving." statusColor="orange" statusDotPosition="trailing" />;
    case 'ERROR':
      return <Status label="Could not save." statusColor="red" statusDotPosition="trailing" />;
    case 'SAVED':
    default:
      return <Status label="Saved." statusColor="green" statusDotPosition="trailing" />;
  }
}
