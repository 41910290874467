import React, { useEffect } from 'react';
import Banner from '@ingka/banner';
import usePushNotifications from 'pushNotifications/usePushNotifications';
import useBackendService from 'services/useBackendService';
import UserService from 'users/services/UserService';
import Device from 'devices/models/Device';

export default function PushNotificationsPermissionBanner() {
  const { state, registrationToken } = usePushNotifications();
  const userService = useBackendService(UserService);

  useEffect(() => {
    if (registrationToken) {
      userService.patch({ deviceToken: registrationToken });
    }
  }, [userService, registrationToken]);

  if (state === 'FAILED') {
    const body = 'Please allow push notifications to receive critical updates from subscribed channels.';
    return <Banner body={body} dismissable />;
  }

  return <></>;
}

PushNotificationsPermissionBanner.defaultProps = {
  device: new Device(),
};
