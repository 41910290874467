import _ from 'lodash';
import JSONAPISerializer from 'json-api-serializer';

type ErrorObject = JSONAPISerializer.ErrorObject;

export default function errorsForField(errors: Array<ErrorObject>, field: string) {
  const jsonPointer = `/data/attributes/${field}`;

  return errors
    .filter((err: ErrorObject) => _.get(err, ['source', 'pointer']) === jsonPointer)
    .reduce((acc, err: ErrorObject) => ({ ...acc, body: `${err.detail} ${acc.body}` }), {
      body: '',
    });
}
