import Button from '@ingka/button';
import arrowLeftIconPath from '@ingka/ssr-icon/paths/arrow-left';
import Footer from 'layouts/components/Footer';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Stack } from 'ui';

export default function NewChannelApproval() {
  const history = useHistory();
  return (
    <>
      <main className="container">
        <Stack space={Space.Space200}>
          <Button onClick={() => history.goBack()} type="tertiary" ssrIcon={arrowLeftIconPath} />
          <h1>Waiting for approval</h1>
          <p>
            You channel creation request is being processed.
            <br />
            A request validation email will be provided by the iSubscribe support team shortly.
          </p>
        </Stack>
      </main>
      <Footer />
    </>
  );
}
