import { Space, Stack } from 'ui';
import React, { useState } from 'react';
import useBackendService from 'services/useBackendService';
import CountryForm from 'admin/components/countryManagement/CountryForm';
import ShowRegion from 'regions/models/ShowRegion';
import CountryService from 'regions/services/CountryService';
import { Redirect } from 'react-router-dom';
import adminPagePath from 'admin/services/adminPagePath';

export default function AddCountry({
  region,

}: {
  region: ShowRegion;
}) {
  const service = useBackendService(CountryService);
  const [redirectPath, setRedirectPath] = useState<string | undefined>();

  if (redirectPath) {
    return (
      <Redirect
        to={{ pathname: redirectPath, state: { toastMessage: ' Country created successfully!' } }}
      />
    );
  }
  return (

    <Stack space={Space.Space300}>
      <h3>Add new country</h3>
      <CountryForm
        onSubmit={(newCountry) => {
          service
            .create({ ...newCountry })
            .then(() => {
              setRedirectPath(adminPagePath());
            });
        }}
        defaultValues={{
          regionId: region.id,
        }}
      />

    </Stack>
  );
}
