import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import newChannelPath from 'channels/newChannelPath';
import plus from '@ingka/ssr-icon/paths/plus';
import React from 'react';
import {
  Color, Column, ColumnSize, EmphasisedCard, Row, Space, Stack,
} from 'ui';
import ShowUser from 'users/models/ShowUser';
import channelPlaceholderThumbnail from 'assets/channel-placeholder-thumbnail.png';
import ChannelListItem from 'channels/components/ChannelListItem';
import _ from 'lodash';
import User from './User';
import Channels from './Channels';

type Props = { user: ShowUser };

export default function Profile({ user }: Props) {
  const tabs = [
    <Tab key="all" text="All" tabPanelId="all" active />,
    <Tab key="owner" text="Owner" tabPanelId="owner" />,
    <Tab key="publisher" text="Publisher" tabPanelId="publisher" />,
    <Tab key="subscriber" text="Subscriber" tabPanelId="subscriber" />,
  ];
  const tabPanels = [
    <TabPanel key="all" tabPanelId="all" data-testid="all-panel">
      <Row space={Space.Space200}>
        <Column size={{ m: ColumnSize.Third }}>
          <EmphasisedCard
            title="Add a new channel"
            body="Create a subscription channel and become a channel owner"
            href={newChannelPath()}
            backgroundColor={Color.Grey100}
            ssrIcon={plus}
            aspectRatioImageProps={{
              src: channelPlaceholderThumbnail,
            }}
          />
        </Column>
        {_.uniqBy(
          [...user.ownedChannels, ...user.subscribedChannels, ...user.publishingChannels],
          (x) => x.id,
        ).map((c) => (
          <Column size={{ m: ColumnSize.Third }} key={c.id}>
            <ChannelListItem channel={c} />
          </Column>
        ))}
      </Row>
    </TabPanel>,
    <TabPanel key="owner" tabPanelId="owner" data-testid="owned-panel">
      <Channels channels={user.ownedChannels} />
    </TabPanel>,
    <TabPanel key="publisher" tabPanelId="publisher">
      <Channels channels={user.publishingChannels} />
    </TabPanel>,
    <TabPanel key="subscriber" tabPanelId="subscriber">
      <Channels channels={user.subscribedChannels} />
    </TabPanel>,
  ];

  return (
    <Stack space={Space.Space300}>
      <Row>
        <Column size={{ m: ColumnSize.ThreeFourths }}>
          <Stack space={Space.Space100}>
            <User user={user} />
          </Stack>
        </Column>
      </Row>

      <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="all" />
    </Stack>
  );
}
