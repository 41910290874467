import ShowChannel from 'channels/models/ShowChannel';
import _ from 'lodash';
import PreviewNotification from 'notifications/models/PreviewNotification';
import React from 'react';

type Props = {
  notification: PreviewNotification;
  channel: ShowChannel;
};

export default function SmsPreview({ notification, channel }: Props) {
  function toFriendlyString(value: any): string {
    if (_.isNil(value)) {
      return '';
    }
    return value;
  }

  return (
    <>
      <p className="text--align-center">SMS</p>
      <p className="background--grey-200 border-radius--200 padding--200">
        <span>{channel.name}</span>
        <br />
        <br />
        <span>
          {`${toFriendlyString(notification.title)} - ${toFriendlyString(notification.status)}`}
        </span>
        <br />
        <br />
        <span>{`${toFriendlyString(notification.headline)}`}</span>
        <br />
        <br />
        <span>{`Impact: ${toFriendlyString(notification.impact)}`}</span>
        <br />
        <br />
        <span>
          Affected Regions and Countries:
          {' '}
          {notification && notification.countryCodes
          && notification.countryCodes.map((country, index) => (
            <React.Fragment key={country}>
              {toFriendlyString(country)}
              {index < notification.countryCodes.length - 1 && ', '}
            </React.Fragment>
          ))}
        </span>
        <br />
        <br />
        <span>Detailed information is provided in the notification email.</span>
      </p>
    </>
  );
}
