import React from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import ErrorPage from 'errors/components/ErrorPage';
import HttpStatusCode from 'http/HttpStatusCode';
import useAsyncResource from 'http/useAsyncResource';
import _ from 'lodash';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import useBackendService from 'services/useBackendService';
import NotificationService from 'notifications/services/NotificationService';
import EditNotification from 'notifications/components/EditNotification';
import ShowChannel from 'channels/models/ShowChannel';
import DeleteNotification from './DeleteNotification';

type Props = {
  channel: ShowChannel;
};

export default function AdminNotificationRouter({ channel }: Props) {
  const match = useRouteMatch<{ id: string }>('/notifications/:id');
  const { id } = match?.params || { id: '' };
  const service = useBackendService(NotificationService);
  const [notification, notificationError] = useAsyncResource(async () => service.findOne(id));

  if (notification) {
    return (
      <Switch>
        <Route path={`${match?.path}/edit`}>
          <EditNotification notification={notification} channel={channel} />
        </Route>
        <Route path={`${match?.path}/delete`}>
          <DeleteNotification notification={notification} />
        </Route>

      </Switch>
    );
  }

  if (notificationError) {
    if (_.get(notificationError, ['response', 'status']) === HttpStatusCode.NotFound) {
      return <h1>Notification not found</h1>;
    }

    return <ErrorPage error={notificationError} />;
  }

  return (
    <Loading>
      <LoadingBall />
    </Loading>
  );
}
