import TermsOfUseAgreement from './TermsOfUseAgreement';

export default class TermsOfUseAgreementDto {
  id: string;

  decision: 'accepted' | 'declined';

  createdAt: string;

  constructor({ id, decision, createdAt }: TermsOfUseAgreementDto) {
    this.id = id;

    this.decision = decision;

    this.createdAt = createdAt;
  }

  deserialize(): TermsOfUseAgreement {
    return new TermsOfUseAgreement({
      id: this.id,
      decision: this.decision,
      createdAt: new Date(this.createdAt),
    });
  }
}
