import Button from '@ingka/button';
import React from 'react';
import {
  Row, Column, Space, Alignment, Stack, IconLink,
} from 'ui';
import Table, { TableBody, TableHeader } from '@ingka/table';
import pencil from '@ingka/ssr-icon/paths/pencil';
import cross from '@ingka/ssr-icon/paths/cross';
import User from 'users/models/User';
import Status from '@ingka/status';
import editUserPath from 'admin/services/editUserPath';
import deleteUserPath from 'admin/services/deleteUserPath';

type Props = {
  users: User[];

  onLoadMore: () => void;

  isAllLoaded: boolean;

  searchText: string;
};

export default function UserSearchResult({
  searchText, users, isAllLoaded, onLoadMore,
}: Props) {
  return (
    <section>
      <Stack space={Space.Space50}>
        <h3>{searchText}</h3>

        <Table
          className="demo-sticky-table"
          inset
        >
          <TableHeader sticky>
            <tr>
              <th>
                <div className="tabel__header">Status</div>
              </th>
              <th>
                <div className="tabel__header"> Users</div>
              </th>
              <th>
                <div className="tabel__header">Actions</div>
              </th>
            </tr>
          </TableHeader>
          <TableBody striped>
            {users.map((user) => (
              <tr key={user.id}>
                <td>
                  {user.isActive ? <Status label="      " statusColor="green" statusDotPosition="trailing" />
                    : <Status label="      " statusColor="red" statusDotPosition="trailing" />}
                </td>
                <td>
                  <div className="tabel__body">
                    {user.fullName}
                  </div>
                </td>
                <td>
                  <IconLink icon={pencil} text="Edit user" to={editUserPath(user)} />
                  <IconLink icon={cross} text="Delete user" to={deleteUserPath(user)} />
                </td>
              </tr>
            ))}
          </TableBody>
        </Table>

        {isAllLoaded ? (
          <p>Search for users to get more results</p>
        ) : (
          <Row align={Alignment.Center}>
            <Column>
              <Button text="Load more" onClick={onLoadMore} />
            </Column>
          </Row>
        )}
      </Stack>
    </section>
  );
}
