import Accordion, { AccordionItem } from '@ingka/accordion';
import InlineMessage from '@ingka/inline-message';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CountryCheckboxGroup from 'regions/components/CountryCheckboxGroup';
import useBackendService from 'services/useBackendService';
import { Space, Stack } from 'ui';
import SaveIndicator, { SaveState } from 'ui/components/SaveIndicator';
import ShowUser from 'users/models/ShowUser';
import UserService from 'users/services/UserService';

export default function User({ user }: { user: ShowUser }) {
  const userService = useBackendService(UserService);
  const [subscribedCountries, setSubscribedCountries] = useState(user.subscribedCountries);
  const [saveState, setSaveState] = useState<SaveState>('SAVED');
  useEffect(() => {
    if (saveState === 'UNSAVED') {
      setSaveState('SAVING');
      userService
        .patch({ subscribedCountries })
        .then(() => setSaveState('SAVED'))
        .catch(() => setSaveState('ERROR'));
    }
  }, [userService, subscribedCountries, saveState]);
  if (_.isNil(user)) {
    return (
      <InlineMessage
        variant="negative"
        title="Could not find user information"
        body={(
          <p>
            Your user information could not be found. This should not be possible. Please contact
            support to get help.
          </p>
        )}
      />
    );
  }

  return (
    <Stack space={Space.Space300}>
      <Stack space={Space.Space100}>
        <Stack space={Space.Space50}>
          <p>Personal information</p>
          <h1>
            {user.fullName}
            {' '}
            (
            {user.nickname}
            )
          </h1>
        </Stack>

        <span className="text--grey-700">{user.email}</span>
      </Stack>
      <Stack space={Space.Space100}>
        <p>Select region and country specific notifications you want to receive</p>

        <Accordion>
          <AccordionItem id="regions-and-countries" title="Regions and Countries">
            <SaveIndicator state={saveState} />
            <CountryCheckboxGroup
              onChange={(countries) => {
                setSubscribedCountries(countries);
                setSaveState('UNSAVED');
              }}
              defaultChecked={false}
              selectedCountries={subscribedCountries}
            />
          </AccordionItem>
        </Accordion>
      </Stack>
    </Stack>
  );
}
