import Table, { TableBody, TableHeader } from '@ingka/table';
import React from 'react';
import List from '@ingka/list';
import howToCreateAChannel from 'assets/how-to-create-a-channel.jpg';
import { Space, Stack } from 'ui';

const FIELD_DESCRIPTIONS = [
  ['Name', 'Use a name that the users can relate to or is used commonly for the object.'],
  ['URL', 'Use application url or similar.'],
  [
    'Description',
    'A well described information channel helps the users to find the specific channel.',
  ],
  [
    'Channel owner',
    'Add the person or persons that should be able to edit the information channel information, add/remove channel owners, add/remove publishers.  ',
  ],
  [
    'Publishers',
    'Person(s) added as publishers will have the rights to publish information for the information channel.',
  ],
  [
    'Select the categories for this channel',
    'The categories help the users to search for the information channel.',
  ],
  [
    'Regions and Countries',
    "With the region and countries option it's possible to steer notifications to a specific area. The notification will only be sent to the people subscribing to that information channel and have chosen the country of interest.",
  ],
  [
    'Thumbnail image',
    'The information channel will be presented as a card in iSubscribe where it’s possible to customize the picture.',
  ],
  [
    'Hide channel',
    'The hide channel is used when You would like to prepare the users for the information channel or plan to start it based on a certain need. The option can only be un-ticked by the person(s) that are set as channel owners.',
  ],
];

export default function HowToCreateAChannel() {
  return (
    <Stack space={Space.Space300}>
      <img
        className="width--100"
        src={howToCreateAChannel}
        alt="Screenshot showing the link to create a channel"
      />

      <List ordered>
        <li>
          At the iSubscribe main page, click on the option
          {' '}
          <b>
            <i>Create a new one here.</i>
          </b>
        </li>
        <li>
          <Stack space={Space.Space100}>
            Fill in the form - Creating a new channel.
            <Table>
              <TableHeader>
                <tr>
                  <th>Field name</th>
                  <th>Description</th>
                </tr>
              </TableHeader>

              <TableBody>
                {FIELD_DESCRIPTIONS.map(([name, description]) => (
                  <tr key={name}>
                    <td>{name}</td>
                    <td>{description}</td>
                  </tr>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </li>
        <li>Press save to register the request.</li>
        <li>
          An iSubscribe administrator will approve the channel.
          Once approved or rejected an email will be sent to You as a requestor.
        </li>
      </List>
    </Stack>
  );
}
