import React, { useState } from 'react';
import { Inline, Space } from 'ui';
import useBackendService from 'services/useBackendService';
import { Redirect, useHistory } from 'react-router-dom';
import Button from '@ingka/button';
import CountryService from 'regions/services/CountryService';
import Country from 'regions/models/Country';
import adminPagePath from 'admin/services/adminPagePath';

export default function DeleteCountry({
  country,
}: {
  country: Country;
}) {
  const service = useBackendService(CountryService);
  const history = useHistory();
  const [redirectPath, setRedirectPath] = useState<undefined | string>();

  if (redirectPath) {
    return (
      <Redirect
        to={{ pathname: redirectPath, state: { toastMessage: 'Country deleted successfully!' } }}
      />
    );
  }

  return (
    <>
      <div>
        <h1>
          Deleting country &quot;
          {country.name}
          &quot;
        </h1>
        <p>Are you sure that you want to delete this country? </p>
        <hr />
      </div>
      <Inline space={Space.Space100}>
        <Button text="Cancel" htmlType="button" onClick={() => history.goBack()} />
        <Button
          type="danger"
          text="Delete"
          onClick={() => service.delete(country.id).then(() => {
            setRedirectPath(adminPagePath());
          })}
        />
      </Inline>
    </>
  );
}
