import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index.scss';
import { Auth0Provider } from '@auth0/auth0-react';
import Configuration from 'configuration/models/Configuration';
import { BrowserRouter as Router } from 'react-router-dom';
import createServer from 'server/createServer';
import App from './App';

if (process.env.REACT_APP_START_BACKEND_MOCK) {
  createServer('development');
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={Configuration.get('AUTH0_DOMAIN')}
      audience={Configuration.get('AUTH0_AUDIENCE')}
      scope={Configuration.get('AUTH0_SCOPE')}
      clientId={Configuration.get('AUTH0_CLIENT_ID')}
      redirectUri={Configuration.get('AUTH0_REDIRECT_URI')}
    >
      <Router>
        <App />
      </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
