import UserState from 'profile/models/UserState';

export default function buildSearchText(
  query: string,
  isSearchPerformed: boolean,
  user?: UserState,
): string | any {
  if (!isSearchPerformed) {
    if (user) return 'All searched users';
    return 'New channels to subscribe to';
  }
  if (query.length > 0) {
    return `Matching search results for "${query}"`;
  }
  if (user && query.length > 0) {
    return `Matching search results for "${user.userQuery}"`;
  }
  return 'Matching search results';
}
