import _ from 'lodash';
import ChannelFormState from './models/ChannelFormState';
import ChannelVisibility from './models/ChannelVisibility';

type SetPropertyAction<K extends keyof ChannelFormState = keyof ChannelFormState> = {
  type: 'SET_PROPERTY';
  name: K;
  value: ChannelFormState[K];
};
type SetPublishersAction = { type: 'SET_PUBLISHERS'; value: string };
type SetOwnersAction = { type: 'SET_OWNERS'; value: string };
type SetVisibilityAction = { type: 'SET_VISIBILITY'; isHidden: boolean };
type SetHubAction = { type: 'SET_HUB'; value: string };
type Action = SetPropertyAction | SetPublishersAction | SetVisibilityAction | SetOwnersAction
| SetHubAction;

export default function channelFormReducer(
  state: ChannelFormState,
  action: Action,
): ChannelFormState {
  function stringToUsers(string: string) {
    return _.reject(
      string.split(',').map((str) => str.trim()),
      (str) => _.isEmpty(str),
    ).map((str: string) => ({
      id: str,
      email: str,
    }));
  }

  switch (action.type) {
    case 'SET_PROPERTY':
      return { ...state, [action.name]: action.value };
    case 'SET_PUBLISHERS':
      return { ...state, publishers: stringToUsers(action.value), publishersText: action.value };
    case 'SET_OWNERS':
      return { ...state, owners: stringToUsers(action.value), ownersText: action.value };
    case 'SET_VISIBILITY':
      if (action.isHidden) {
        return { ...state, visibility: ChannelVisibility.Hidden };
      }

      return { ...state, visibility: ChannelVisibility.Visible };
    case 'SET_HUB':
      return { ...state, hubs: action.value };
    default:
      return state;
  }
}
