import React from 'react';
import Toast from '@ingka/toast';
import AppRouter from 'routing/AppRouter';
import TermsOfUseGuard from 'termsOfUse/components/TermsOfUseGuard';
import AuthenticationGuard from 'authentication/components/AuthenticationGuard';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import DefaultLayout from 'layouts/components/DefaultLayout';
import PushNotificationsPermissionBanner from 'pushNotifications/components/PushNotificationsPermissionBanner';
import UserSynchronizationBanner from 'users/components/UserSynchronizationBanner';
import UserStatus from 'admin/components/userManagement/UserStatus';

function App() {
  const location = useLocation();
  const history = useHistory();
  const toastMessage = _.get(location, ['state', 'toastMessage']);

  return (
    <>
      <Toast
        text={toastMessage}
        isOpen={!_.isNil(toastMessage)}
        onTransitionEnd={() => {}}
        onCloseRequest={() => history.replace(location.pathname)}
      />
      <AuthenticationGuard>
        <TermsOfUseGuard>
          <UserStatus>
            <PushNotificationsPermissionBanner />
            <UserSynchronizationBanner />

            <DefaultLayout>
              <AppRouter />
            </DefaultLayout>
          </UserStatus>
        </TermsOfUseGuard>
      </AuthenticationGuard>
    </>
  );
}

export default App;
