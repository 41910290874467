import ConfigurationKey from './ConfigurationKey';

export default class Configuration {
  static get(key: ConfigurationKey): string {
    const fullKey = `REACT_APP_${key}`;
    const value = process.env[fullKey];

    if (value) {
      return value;
    }

    throw new Error(
      `Could not get configuration for ${fullKey}. Did you set the environment variable?`,
    );
  }
}
