import React from 'react';
import NotificationType from './models/NotificationType';
import Notification from './models/Notification';
import IssueNotificationForm from './components/IssueNotificationForm';
import ServiceWindowNotificationForm from './components/ServiceWindowNotificationForm';
import OtherNotificationForm from './components/OtherNotificationForm';
import ReleaseNotificationForm from './components/ReleaseNotificationForm';

export default function getSubnotificationFormClass(parentNotification: Notification) {
  const { notificationType } = parentNotification;

  switch (notificationType) {
    case NotificationType.Issue:
      return IssueNotificationForm;
    case NotificationType.Release:
      return ReleaseNotificationForm;
    case NotificationType.ServiceWindow:
      return ServiceWindowNotificationForm;
    case NotificationType.OtherInformation:
      return OtherNotificationForm;
    default:
      return () => {
        const invalidNotificationType: never = notificationType;
        return (
          <p>
            {invalidNotificationType}
            {' '}
            is not a valid notification type. Please contact support to
            resolve this issue.
          </p>
        );
      };
  }
}
