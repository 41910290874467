import SearchParameters from 'channels/models/SearchParameters';

export default function channelsPath({
  query,
  categories,
}: Partial<SearchParameters> = {}): string {
  const params: { query?: string; categories?: string } = {};

  if (query) {
    params.query = query;
  }

  if (categories && categories.length > 0) {
    params.categories = JSON.stringify(categories);
  }

  if (Object.keys(params).length > 0) {
    return `/channels?${new URLSearchParams(params)}`;
  }

  return '/channels';
}
