import React from 'react';
import TimelineEntry from './TimelineEntry';

type Props = {
  children: Array<ReturnType<typeof TimelineEntry>>;
};

export default function Timeline({ children }: Props) {
  return <div className="timeline">{children}</div>;
}
