import Configuration from 'configuration/models/Configuration';
import { createServer as mirageCreateServer } from 'miragejs';
import Server from './models/Server';
import seeds from './seeds';
import declareTermsOfUseRoutes from './declareTermsOfUseRoutes';
import declareChannelRoutes from './declareChannelRoutes';
import declareUserRoutes from './declareUserRoutes';
import declareRegionRoutes from './declareRegionRoutes';
import SERVER_REGISTRY from './SERVER_REGISTRY';
import declareNotificationRoutes from './declareNotificationRoutes';
import declareTemplateRoutes from './declareTemplateRoutes';
import declarePrivilegeRoutes from './declarePrivilegeRoutes';
import declareCountryRoutes from './declareCountryRoutes';

export default function createServer(environment = 'development') {
  return mirageCreateServer({
    ...SERVER_REGISTRY,
    environment,
    seeds,
    routes(this: Server) {
      this.urlPrefix = Configuration.get('API_BASE_URL');

      declareNotificationRoutes.bind(this)();
      declareRegionRoutes.bind(this)();
      declareCountryRoutes.bind(this)();
      declarePrivilegeRoutes.bind(this)();
      declareUserRoutes.bind(this)();
      declareChannelRoutes.bind(this)();
      declareTermsOfUseRoutes.bind(this)();
      declareTemplateRoutes.bind(this)();

      this.passthrough(`https://${Configuration.get('AUTH0_DOMAIN')}/**/*`);
      this.passthrough('http://localhost:3000/static/**/*');
    },
  });
}
