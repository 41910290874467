import newNotificationPath from 'notifications/newNotificationPath';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NewNotification from './NewNotification';

export default function NotificationsRouter() {
  return (
    <Switch>
      <Route path={newNotificationPath()}>
        <NewNotification />
      </Route>
    </Switch>
  );
}
