import { RouteHandler } from 'miragejs/server';
import Server from './models/Server';

export default function declareTermsOfUseRoutes(this: Server) {
  this.get('/terms-of-use-agreements', (schema) => schema.all('termsOfUseAgreement'));
  this.post(
    '/terms-of-use-agreements',
    function handle(
      this: RouteHandler<any> & { normalizedRequestAttrs: (type: string) => any },
      schema,
    ) {
      const attributes = this.normalizedRequestAttrs('terms-of-use-agreement');
      return schema.create('termsOfUseAgreement', attributes);
    },
  );
}
