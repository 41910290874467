import { belongsTo, hasMany, Model } from 'miragejs';

const MODELS = {
  termsOfUseAgreement: Model.extend({
    user: belongsTo('user'),
  }),
  user: Model.extend({
    termsOfUseAgreements: hasMany('termsOfUseAgreement'),
    ownedChannels: hasMany('channel', { inverse: 'owners' }),
    publishingChannels: hasMany('channel', { inverse: 'publishers' }),
    subscriptions: hasMany('subscription'),
    subscribedCountries: hasMany('country', { inverse: 'subscribers' }),
    privileges: hasMany('privilege', { inverse: 'admin' }),
  }),
  privilege: Model.extend({}),
  notification: Model.extend({
    channel: belongsTo('channel'),
    countries: hasMany('country'),
  }),
  region: Model.extend({
    countries: hasMany('country'),
  }),
  country: Model.extend({
    region: belongsTo('region'),
    subscribers: hasMany('user', { inverse: 'subscribedCountries' }),
  }),
  channel: Model.extend({
    subscription: belongsTo('subscription'),
    owners: hasMany('user', { inverse: 'ownedChannels' }),
    publishers: hasMany('user', { inverse: 'publishingChannels' }),
    countries: hasMany('country'),
    notifications: hasMany('notification'),
  }),
  subscription: Model.extend({
    channel: belongsTo(),
    user: belongsTo(),
  }),
};

export default MODELS;
