import arrowLeftIconPath from '@ingka/ssr-icon/paths/arrow-left';
import Button from '@ingka/button';
import React, { useState } from 'react';
import { Inline, Space, Stack } from 'ui';
import { Redirect, useHistory } from 'react-router-dom';
import Toast from '@ingka/toast';
import _ from 'lodash';
import useBackendService from 'services/useBackendService';
import NotificationService from 'notifications/services/NotificationService';
import JSONAPISerializer from 'json-api-serializer';
import ShowChannel from 'channels/models/ShowChannel';
import channelNotificationsPath from 'channels/channelNotificationsPath';
import getSubnotificationFormClass from '../getSubnotificationFormClass';
import Notification from '../models/Notification';

type Props = {
  parentNotification: Notification;
  channel: ShowChannel;
};

export default function NewSubNotification({ parentNotification, channel }: Props) {
  const history = useHistory();
  const Form = getSubnotificationFormClass(parentNotification);
  const [toast, setToast] = useState('');
  const [errors, setErrors] = useState<JSONAPISerializer.ErrorObject[]>([]);
  const [state, setState] = useState<'IDLE' | 'SENDING'>('IDLE');
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const service = useBackendService(NotificationService);
  const isLoading = state === 'SENDING';

  if (redirectPath) {
    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { toastMessage: 'Notification sent to subscribers!' },
        }}
      />
    );
  }

  return (
    <Stack space={Space.Space300}>
      <Inline>
        <Button onClick={() => history.goBack()} type="tertiary" ssrIcon={arrowLeftIconPath} />
      </Inline>

      <Stack space={Space.Space100}>
        <h1>
          {channel.name}
          {' '}
          notification form
        </h1>

        <h2>{`Posting update for '${parentNotification.title}'`}</h2>
      </Stack>

      <Form
        isLoading={isLoading}
        onSubmit={(notification) => {
          setState('SENDING');
          service
            .createAndSend(notification)
            .then(() => {
              setState('IDLE');
              setRedirectPath(channelNotificationsPath(channel));
            })
            .catch((error) => {
              setState('IDLE');
              setToast('Could not send notification.');
              setErrors(_.get(error.response, ['data', 'errors']) ?? []);
            });
        }}
        errors={errors}
        channel={channel}
        defaultValues={{
          parentId: parentNotification.id,
          title: parentNotification.title,
          headline: parentNotification.headline,
          description: parentNotification.description,
          impact: parentNotification.impact,
          severity: parentNotification.severity,
          countries: parentNotification.countries,
        }}
      />

      <Toast
        isOpen={!_.isEmpty(toast)}
        text={toast}
        onTransitionEnd={() => {}}
        onCloseRequest={() => setToast('')}
      />
    </Stack>
  );
}
