import Configuration from 'configuration/models/Configuration';
import BackendClient from 'http/services/BackendClient';

// Define the expected response type from the API
interface HubResponse {
  value: {
    HubId: number;
    HubName: string;
    ChannelInfo: string;
  }[];
  isSuccess: boolean;
}

export default class HubService {
  constructor(private client: BackendClient) {}

  async findAllHubNames(): Promise<string[]> {
    const url = `${Configuration.get('API_BASE_URL')}/Hub`;
    const response = await this.client.get<HubResponse>(url);
    const { isSuccess, value } = response.data;
    if (isSuccess && value) {
      return value.map((hub) => hub.HubName);
    }
    throw new Error('Failed to fetch hub names');
  }
}
