import Button from '@ingka/button';
import adminPagePath from 'admin/services/adminPagePath';
import Notification from 'notifications/models/Notification';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useBackendService from 'services/useBackendService';
import { Inline } from 'ui';
import Space from 'ui/models/Space';
import NotificationService from 'notifications/services/NotificationService';

export default function DeleteNotification({
  notification,
}: {
  notification: Notification;
}) {
  const notificationService = useBackendService(NotificationService);
  const { id } = notification;
  const history = useHistory();
  const [redirectPath, setRedirectPath] = useState<undefined | string>();
  if (redirectPath) {
    return (
      <Redirect
        to={{ pathname: redirectPath, state: { toastMessage: 'Notification deleted successfully!' } }}
      />
    );
  }

  return (

    <>
      <div>
        <h1>
          Deleting notification &quot;
          {notification.title}
          &quot;
        </h1>
        <p>Are you sure that you want to delete this notification?</p>
        <hr />
      </div>
      <Inline space={Space.Space100}>
        <Button text="Cancel" htmlType="button" onClick={() => history.goBack()} />
        <Button
          type="danger"
          text="Delete"
          onClick={() => notificationService.delete(id).then(() => {
            setRedirectPath(adminPagePath());
          })}
        />
      </Inline>
    </>
  );
}
