import ChannelSubscription from 'channels/models/ChannelSubscription';
import Configuration from 'configuration/models/Configuration';
import BackendClient from 'http/services/BackendClient';
import deserialize from 'json-api/deserialize';
import serialize from 'json-api/serialize';
import ShowChannelSubscriptionResponse from '../models/ShowChannelSubscriptionResponse';

export default class SubscriptionService {
  constructor(private client: BackendClient) {}

  async update(channelId: string, subscription: ChannelSubscription): Promise<ChannelSubscription> {
    const url = `${Configuration.get('API_BASE_URL')}/channels/${channelId}/subscription`;
    return this.client
      .put<ShowChannelSubscriptionResponse>(url, serialize('subscription', subscription))
      .then((response) => deserialize('subscription', response.data));
  }
}
