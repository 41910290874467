import Button from '@ingka/button';
import Loading, { LoadingBall } from '@ingka/loading';
import ShowChannel from 'channels/models/ShowChannel';
import ErrorPage from 'errors/components/ErrorPage';
import NotificationService from 'notifications/services/NotificationService';
import React, { useState } from 'react';
import useBackendService from 'services/useBackendService';
import useEffectOnce from 'hooks/useEffectOnce';
import PaginatedNotifications from 'notifications/models/PaginatedNotifications';
import { Space, Stack } from 'ui';
import BackButton from 'navigation/components/BackButton';
import ChannelNotifications from './ChannelNotifications';

type Props = {
  channel: ShowChannel;
};

export default function ShowChannelNotifications({ channel }: Props) {
  const service = useBackendService(NotificationService);
  const [result, setResult] = useState<PaginatedNotifications | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const notifications = result?.notifications ?? [];
  const fetchNotifications = () => service
    .findForChannel(channel, result?.nextPage)
    .then((res) => setResult({ ...res, notifications: [...notifications, ...res.notifications] }))
    .catch((err) => setError(err));

  useEffectOnce(fetchNotifications);
  const renderNextPageButton = () => {
    if (result?.nextPage) {
      return <Button type="primary" text="Load more" onClick={fetchNotifications} />;
    }

    return '';
  };

  if (error) {
    return <ErrorPage error={error} />;
  }
  if (result) {
    return (
      <Stack space={Space.Space150}>
        <BackButton />
        <h1>{`Notification Timeline ${channel.name}`}</h1>
        <ChannelNotifications notifications={result.notifications} />
        {renderNextPageButton()}
      </Stack>
    );
  }

  return (
    <Loading>
      <LoadingBall />
    </Loading>
  );
}
