import Country from 'regions/models/Country';
import { Factory } from 'fishery';
import region from './region';

export default Factory.define<Country>(({ sequence }) => ({
  id: sequence.toString(),
  name: 'Sweden',
  code: 'SE',
  regionId: region.build().id,
}));
