import UserState from 'profile/models/UserState';

export default function getInitialUserState(userQuery: string): UserState {
  return {
    nextPage: null,
    userQuery,
    isSearchPerformed: userQuery.length > 0,
    users: [],
    latestAction: 'INIT',
    selectedCountries: [],
    saveState: 'UNSAVED',
  };
}
