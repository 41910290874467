const REQUIRED_ROLES = [
  ['InterIkea_Users', 'Users employed by Inter IKEA.'],
  ['OtherExternals_InterIKEA_Users', 'External Users hired by Inter IKEA.'],
  ['INGKA_Users', 'Users employed by INGKA franchisee organisation.'],
  ['INGKA_Consultants', 'Users hired by INGKA franchisee organisation.'],
  [
    'NonIngkaRetailer_User',
    'User connected to Non INGKA franchisee organisation such as IKANO or Housemarket.',
  ],
];

export default REQUIRED_ROLES;
