import Loading, { LoadingBall } from '@ingka/loading';
import ChannelService from 'channels/services/ChannelService';
import ErrorPage from 'errors/components/ErrorPage';
import useAsyncResource from 'http/useAsyncResource';
import useBackendService from 'services/useBackendService';
import React, { ReactChild, useState } from 'react';
import Stack from 'ui/components/Stack';
import Choice, { ChoiceItem } from '@ingka/choice';
import {
  Alignment, Column, ColumnSize, Row, Space,
} from 'ui';
import { Link, Redirect } from 'react-router-dom';
import newChannelNotificationPath from 'notifications/newChannelNotificationPath';
import newChannelPath from 'channels/newChannelPath';

export default function NewNotificationChoices() {
  const channelService = useBackendService(ChannelService);
  const [channels, error] = useAsyncResource(() => channelService.findAllPublishable());
  const [redirectPath, setRedirectPath] = useState<undefined | string>();
  const helpText: ReactChild = (
    <span>
      You do not have publishing rights to a channel. To get publishing rights either
      {' '}
      <Link className="link" to={newChannelPath()}>
        create a channel
      </Link>
      {' '}
      or ask an owner of a channel for you to become a publisher.
    </span>
  );

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (channels) {
    const isPublishableChannelsPresent = channels.length > 0;
    const channelElements = (
      <Choice>
        {channels.map((channel) => (
          <ChoiceItem
            onClick={() => setRedirectPath(newChannelNotificationPath(channel.id))}
            id={channel.id}
            key={channel.id}
            title="New notification"
            caption={channel.name}
            image={{ src: channel.thumbnailUrl }}
          />
        ))}
      </Choice>
    );

    return (
      <>
        <Stack space={Space.Space200}>
          <div>
            <p>Choose a channel to create a new notification</p>
            <hr />
          </div>

          <Row align={Alignment.Center}>
            <Column size={{ m: ColumnSize.FiveSixths }}>
              {isPublishableChannelsPresent ? channelElements : helpText}
            </Column>
          </Row>
        </Stack>
      </>
    );
  }

  return (
    <>
      <p>Choose a channel to create a new notification</p>
      <hr />

      <Loading text="Loading...">
        <LoadingBall />
      </Loading>
    </>
  );
}
