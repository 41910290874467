import UserAction from 'profile/models/UserAction';
import UserState from 'profile/models/UserState';

export default function UserReducer(
  state: UserState,
  action: UserAction,
): UserState {
  switch (action.type) {
    case 'SET_User_QUERY':
      return { ...state, userQuery: action.userQuery, latestAction: action.type };
    case 'SEARCH':
      return {
        ...state,
        isSearchPerformed: true,
        nextPage: null,
        latestAction: action.type,
      };
    case 'SET_RESULT':
      return {
        ...state,
        nextPage: action.nextPage,
        users: action.foundUsers,
        latestAction: action.type,
      };
    case 'APPEND_RESULT':
      return {
        ...state,
        nextPage: action.nextPage,
        users: [...state.users, ...action.foundUsers],
        latestAction: action.type,
      };
    case 'LOAD_MORE':
      return { ...state, latestAction: action.type };
    default:
      throw new Error(`usersReducer does not implement ${action.type}.`);
  }
}
