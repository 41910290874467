import { Server } from 'miragejs/server';

export default function seeds(server: Server<any>) {
  const user = server.create('user', { id: 'user@example.com', email: 'user@example.com' });
  server.create('subscription', { user, channelId: server.create('channel').id });
  server.create('channel', { name: 'Owned channel', owners: [user] });
  server.create('channel', { name: 'Publishing channel', publishers: [user] });

  server.create('user', { id: 'owner@example.com', email: 'owner@example.com' });
  server.create('user', { id: 'publisher@example.com', email: 'publisher@example.com' });

  server.create('channel');

  const europe = server.create('region', { name: 'Europe' });
  server.create('country', { name: 'Sweden', region: europe });
  server.create('country', { name: 'Germany', region: europe });

  const asia = server.create('region', { name: 'Asia' });
  server.create('country', { name: 'China', region: asia });
  server.create('country', { name: 'Japan', region: asia });
}
