import factories from 'factories';
import { Factory } from 'miragejs';
import { Server } from 'miragejs/server';
import NotificationType from 'notifications/models/NotificationType';

const FACTORIES = {
  termsOfUseAgreement: Factory.extend(factories.termsOfUseAgreement.build()),
  subscription: Factory.extend(factories.channelSubscription.build()),
  user: Factory.extend({ ...factories.user.build(), ownedChannels: [] }),
  privilege: Factory.extend({
    ...factories.privilege.build(),
    id(i: number) {
      return i.toString();
    },
  }),
  channel: Factory.extend({
    ...factories.channel.build(),
    id(i: number) {
      return i.toString();
    },
  }),
  region: Factory.extend({
    ...factories.region.build(),
    regionId(i: number) {
      return i.toString();
    },
  }),
  hub: Factory.extend({
    ...factories.hub.build(),
    HubName(i: string) {
      return `Hub-${i}`;
    },
  }),
  notification: Factory.extend({
    notificationType: NotificationType.OtherInformation,
    createdAt() {
      return new Date();
    },
    afterCreate(notification: any, server: Server<any>) {
      notification.update({
        channel: server.create('channel'),
        notifications: notification.notifications ?? [],
      });
    },
    id(i: number) {
      return i.toString();
    },
  }),
};

export default FACTORIES;
