import TokenUser from 'authentication/models/TokenUser';
import { Factory } from 'fishery';

export default Factory.define<TokenUser>(
  ({ sequence }) => new TokenUser({
    email: 'john.doe@example.com',
    family_name: 'Doe',
    given_name: 'John',
    name: 'John Doe',
    nickname: 'john.doe',
    picture: 'https://via.placeholder.com/100x100',
    sub: `${sequence}`,
    updated_at: '2021-05-06T08:25:05.350Z',
    'http://accounts.ikea.com/networkid': 'jodoe',
    privileges: [],
    isActive: true,
  }),
);
