import Configuration from 'configuration/models/Configuration';
import BackendClient from 'http/services/BackendClient';
import { JSONAPIDocument } from 'json-api-serializer';
import serialize from 'json-api/serialize';
import MailPreview from 'notifications/models/MailPreview';
import deserialize from 'json-api/deserialize';
import PreviewNotification from 'notifications/models/PreviewNotification';
import reactHtmlParser from 'react-html-parser';
import ReactDOMServer from 'react-dom/server';
import DOMPurify from 'dompurify';

export default class TemplateService {
  constructor(private client: BackendClient) {}

  async getEmailPreview(notification: PreviewNotification): Promise<MailPreview> {
    const purifiedDescription = notification.description ? DOMPurify.sanitize(notification.description) : '';
    const finalDescription = (() => {
      let modifiedDescription = purifiedDescription;
      modifiedDescription = modifiedDescription.replace(/<li class="ql-indent-(\d+)">(.*?)<\/li>/g,
        (match, p1, p2) => {
          const indentLevel = parseInt(p1, 10);
          const paddingLeft = indentLevel === 1 ? '0' : `${30 * (indentLevel - 1)}px`;
          return `<ul><li style="list-style-type: disc; list-style-position: inside; padding-left: ${paddingLeft}; margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">${p2}</li></ul>`;
        });
      for (let indentLevel = 1; indentLevel <= 3; indentLevel += 1) {
        const ulPattern = new RegExp(`(<li class="ql-indent-${indentLevel}".*?>.*?</li>)(?=<li class="ql-indent-${indentLevel}".*?>|<li class="ql-indent-${indentLevel + 1}".*?>|$)`, 'g');
        modifiedDescription = modifiedDescription
          .replace(ulPattern, (match) => `<ul style="padding-left: ${40 * (indentLevel - 1)}px;">${match}</ul>`);
      }
      modifiedDescription = modifiedDescription
        .replace(/<p>/g, '<p style="margin: 0; padding: 0">')
        .replace(/<p class="ql-align-center">(.*?)<\/p>/g, '<center style="margin: 0; margin-top: 0;margin-bottom: 0; padding-bottom:0; padding-top: 0;">$1</center>')
        .replace(/<p class="ql-align-right">(.*?)<\/p>/g, '<p style="text-align: right;margin: 0; padding: 0;">$1</p>')
        .replace(/<p class="ql-align-justify">(.*?)<\/p>/g, '<p style="text-align: justify;margin: 0; padding: 0;">$1</p>')
        .replace(/<p class="ql-indent-(\d+)">(.*?)<\/p>/g,
          (match, p1, p2) => {
            const indentLevel = parseInt(p1, 10);
            const paddingLeft = `${40 * indentLevel}px`;
            return `<p style="padding-left: ${paddingLeft};margin-top: 0; padding-top: 0; margin-bottom:0; padding-bottom:0;">${p2}</p>`;
          })
        .replace(/<strong(?!.*?<\/strong>)/g, '<strong style="margin: 0;">')
        .replace(/<(\w+)([^>]*)>/g, '<$1$2 style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">');
      return modifiedDescription;
    })();
    const parsedDescription = reactHtmlParser(finalDescription);
    for (let i = parsedDescription.length - 2; i > 0; i -= 1) {
      const objectOne = parsedDescription[i];
      const objectTwo = parsedDescription[i + 1];
      if (objectOne.props.children[0].length > 1) {
        if (objectTwo.props.children[0].length === 1) delete parsedDescription[i + 1];
        break;
      } else if (objectOne.props.children[0].length === 1
        && objectTwo.props.children[0].length === 1) {
        delete parsedDescription[i + 1];
      }
    }
    const htmlString = ReactDOMServer.renderToStaticMarkup(parsedDescription as any);
    const updatedNotification = { ...notification, description: htmlString };
    const { notificationType } = updatedNotification;
    const url = `${Configuration.get('API_BASE_URL')}/templates/${notificationType}/mail-previews`;
    return this.client
      .post<JSONAPIDocument>(url, serialize('mailPreview', { notification: updatedNotification }))
      .then((response) => deserialize('mailPreview', response.data));
  }
}
