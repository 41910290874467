import { UiComponents } from 'ui';
import { Switch, Route, Redirect } from 'react-router-dom';
import ChannelsRouter from 'channels/components/ChannelsRouter';
import React from 'react';
import channelsPath from 'channels/channelsPath';
import NotificationsRouter from 'notifications/components/NotificationsRouter';
import usagePath from 'help/usagePath';
import Usage from 'help/components/Usage';
import profilePath from 'profile/profilePath';
import ShowProfile from 'profile/components/ShowProfile';
import AdminRouter from 'admin/components/AdminRouter';
import adminPagePath from 'admin/services/adminPagePath';
import dashboardPath from 'Dashboard/services/dashboardPath';
import Dashboard from 'Dashboard/components/Dashboard';

export default function AppRouter() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={channelsPath()} exact />
      </Route>

      <Route path={channelsPath()}>
        <ChannelsRouter />
      </Route>

      <Route path="/notifications">
        <NotificationsRouter />
      </Route>

      <Route path={adminPagePath()}>
        <AdminRouter />
      </Route>

      <Route path={profilePath()}>
        <ShowProfile />
      </Route>

      <Route path="/ui-components">
        <UiComponents />
      </Route>

      <Route path={usagePath()}>
        <Usage />
      </Route>

      <Route path={dashboardPath()}>
        <Dashboard />
      </Route>

      <Route>Not found</Route>
    </Switch>
  );
}
