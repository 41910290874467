import Button from '@ingka/button';
import React, { Dispatch } from 'react';
import {
  Row, Column, Space, Alignment, Stack, IconLink,
} from 'ui';
import Table, { TableBody, TableHeader } from '@ingka/table';
import eyeOpen from '@ingka/ssr-icon/paths/eye-open';
import lock from '@ingka/ssr-icon/paths/lock';
import channelPath from 'channels/channelPath';
import pencil from '@ingka/ssr-icon/paths/pencil';
import cross from '@ingka/ssr-icon/paths/cross';
import shopping from '@ingka/ssr-icon/paths/shopping-list';
import Channel from 'channels/models/Channel';
import useAsyncResource from 'http/useAsyncResource';
import useBackendService from 'services/useBackendService';
import UserService from 'users/services/UserService';
import ErrorPage from 'errors/components/ErrorPage';
import UserContextType from 'admin/models/UserContextType';
import channelNotificationsPath from 'admin/services/channelNotificationsPath';
import editChannelPath from 'admin/services/editChannelPath';
import deleteChannelPath from 'admin/services/deleteChannelPath';
import ChannelsState from 'channels/models/ChannelsState';
import ChannelAction from 'channels/models/ChannelAction';
import VisibilitySelect from './VisibilitySelect';

type Props = {
  channels: Channel[];

  onLoadMore: () => void;

  isAllLoaded: boolean;

  searchText: string;
  state: ChannelsState;
  dispatch: Dispatch<ChannelAction>;
};

export default function ChannelSearchResult({
  searchText, channels, isAllLoaded, onLoadMore, state, dispatch,
}: Props) {
  const userService = useBackendService(UserService);
  const [user, error] = useAsyncResource(() => userService.findCurrentUser());
  if (error) {
    return <ErrorPage error={error} />;
  }
  return (
    <section>
      <Stack space={Space.Space50}>
        <h3>{searchText}</h3>

        <Table
          className="demo-sticky-table"
          inset
        >
          <TableHeader sticky>
            <tr>
              <th>
                <h2>
                  <VisibilitySelect
                    isVisibilityActive={(visibility) => state.visibility.includes(visibility)}
                    toggleVisibility={(visibility) => dispatch({ type: 'TOGGLE_VISIBILITY', visibility })}
                  />
                </h2>
              </th>
              <th>
                <div className="tabel__header">Channels</div>
              </th>
              <th>
                <div className="tabel__header">Actions</div>
              </th>
            </tr>
          </TableHeader>
          <TableBody striped>
            {channels.map((channel) => (
              <tr key={channel.id}>
                <td>
                  <div className="tabel__body">
                    {channel.approvalStatus === 'Approved'
                      ? <IconLink icon={eyeOpen} text="Go to profile" to={channelPath(channel)} />
                      : <IconLink icon={lock} text=" Waiting for approval" to={channelPath(channel)} />}
                  </div>
                </td>

                <td>
                  <div className="tabel__body">{channel.name}</div>
                </td>
                <td>
                  {user?.privileges.map((p) => {
                    switch (p.userContextType) {
                      case UserContextType.Channels:
                        return (

                          <>
                            <IconLink icon={pencil} text="Edit channel" to={editChannelPath(channel)} />
                            <IconLink icon={cross} text="Delete channel" to={deleteChannelPath(channel)} />
                          </>

                        );

                      case UserContextType.Notifications:
                        return (

                          <IconLink icon={shopping} text="Channel notifications" to={channelNotificationsPath(channel)} />

                        );

                      default:
                        return null;
                    }
                  })}
                </td>
              </tr>
            ))}
          </TableBody>
        </Table>
        {isAllLoaded ? (
          <p>No more search results</p>
        ) : (
          <Row align={Alignment.Center}>
            <Column>
              <Button text="Load more" onClick={onLoadMore} />
            </Column>
          </Row>
        )}
      </Stack>
    </section>
  );
}
