import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RegionsRouter from 'admin/components/countryManagement/RegionsRouter';
import AdminChannelsRouter from './channelManagement/AdminChannlsRouter';
import AdminPage from './AdminPage';

import UserRouter from './userManagement/UserRouter';

export default function AdminRouter() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/channels/:id`}>
        <AdminChannelsRouter />
      </Route>

      <Route path={`${match.path}/users/:id`}>
        <UserRouter />
      </Route>

      <Route path={`${match.path}/regions/:id`}>
        <RegionsRouter />
      </Route>

      <Route path={match.path}>
        <AdminPage />
      </Route>
    </Switch>
  );
}
