import React from 'react';
import CountUp from 'react-countup';

export default function Counter({ number }: { number: number }) {
  return (
    <CountUp
      className="account-balance"
      start={0}
      end={number}
      duration={2.75}
      useEasing
      useGrouping
      separator=" "
    />
  );
}
