import ShowChannel from 'channels/models/ShowChannel';
import Country from 'regions/models/Country';
import NotificationSeverity from './NotificationSeverity';
import NotificationStatus from './NotificationStatus';
import NotificationType from './NotificationType';

export default class Notification {
  id: string;

  title: string;

  status?: NotificationStatus;

  notificationType: NotificationType;

  impact?: string;

  channel: ShowChannel;

  headline: string;

  notifications: Notification[];

  attachmentName?: string;

  attachmentUrl?: string;

  description: string;

  signature: string;

  severity?: NotificationSeverity;

  createdAt: Date;

  releasedAt?: Date;

  url?: string;

  startsAt?: Date;

  endsAt?: Date;

  countries: Country[];

  constructor(properties: Notification) {
    this.title = properties.title;

    this.releasedAt = properties.releasedAt;

    this.url = properties.url;

    this.createdAt = properties.createdAt;

    this.severity = properties.severity;

    this.attachmentUrl = properties.attachmentUrl;

    this.attachmentName = properties.attachmentName;

    this.signature = properties.signature;

    this.description = properties.description;

    this.attachmentUrl = properties.attachmentUrl;

    this.impact = properties.impact;

    this.status = properties.status;

    this.notificationType = properties.notificationType;

    this.channel = properties.channel;

    this.id = properties.id;

    this.headline = properties.headline;

    this.notifications = properties.notifications;

    this.startsAt = properties.startsAt;

    this.endsAt = properties.endsAt;

    this.countries = properties.countries;
  }
}
