import React, { Dispatch, SetStateAction } from 'react';
import logo from 'assets/ikea-logo.svg';
import Footer from 'layouts/components/Footer';
import {
  Alignment, Column, Inline, Row, Space, Stack, ColumnSize,
} from 'ui';
import homePath from 'navigation/homePath';
import Button from '@ingka/button';
import Accordion, { AccordionItem } from '@ingka/accordion';
import TermsOfUseAgreement from 'termsOfUse/models/TermsOfUseAgreement';
import AuthHook from 'authentication/models/AuthHook';
import Configuration from 'configuration/models/Configuration';
import useBackendService from 'services/useBackendService';
import TermsOfUseService from 'termsOfUse/services/TermsOfUseService';

type Props = {
  agreements: TermsOfUseAgreement[];

  setAgreements: Dispatch<SetStateAction<TermsOfUseAgreement[]>>;

  logout: ReturnType<AuthHook>['logout'];
};

export default function TermsOfUse({ logout, agreements, setAgreements }: Props) {
  const termsService = useBackendService(TermsOfUseService);

  return (
    <main className="container">
      <Row align={Alignment.SpaceBetween}>
        <Column>
          <a href={homePath()}>
            <Inline space={Space.Space250}>
              <img src={logo} alt="IKEA Logo" className="width--space-550 padding--y-150" />

              <Stack align={Alignment.Center}>
                <b>iSubscribe</b>
              </Stack>
            </Inline>
          </a>
        </Column>
      </Row>

      <Stack space={Space.Space550}>
        <Row align={Alignment.Center}>
          <Column>
            <h1>Terms & Conditions</h1>
          </Column>
        </Row>

        <Row align={Alignment.Center}>
          <Column size={{ m: ColumnSize.TwoThirds }}>
            <Accordion>
              <AccordionItem
                id="personal-info"
                title="What personal information is extracted from IKEA CDS?"
              >
                <Stack space={Space.Space200}>
                  <p>
                    The following three personal attributes are extracted from IKEA Corporate
                    Directory Services (CDS) and stored encrypted in the iSubscribe system:
                  </p>

                  <ul>
                    <li>Phone Number</li>
                    <li>IKEA Corporate ID</li>
                    <li>IKEA E-Mail Address</li>
                  </ul>
                </Stack>
              </AccordionItem>

              <AccordionItem
                id="storage-location"
                title="Where is my data saved and who can see it?"
              >
                This information is saved and encrypted in the IKEA database and only you will have
                the ability to view this information from within the iSubscribe application.
              </AccordionItem>

              <AccordionItem
                id="data-usage"
                title="General usage of personal data in iSubscribe aligned with GDPR"
              >
                For everyone that has the possibility to add or edit fields in this application, we
                would like to inform you to not add any personal data to these value fields. This
                will violate against EU General Data Protection Regulation (GDPR) rules.
              </AccordionItem>
            </Accordion>
          </Column>
        </Row>

        <Row align={Alignment.Center}>
          <Column>
            <Inline space={Space.Space100}>
              <Button
                type="secondary"
                text="Decline"
                onClick={() => logout({ returnTo: Configuration.get('AUTH0_REDIRECT_URI') })}
              />

              <Button
                type="emphasised"
                text="Accept"
                onClick={() => {
                  termsService.create({ decision: 'accepted' }).then((agreement) => {
                    setAgreements([...agreements, agreement]);
                  });
                }}
              />
            </Inline>
          </Column>
        </Row>
      </Stack>

      <Footer />
    </main>
  );
}
