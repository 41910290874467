import INITIAL_CHANNEL_FORM_STATE from './INITIAL_CHANNEL_FORM_STATE';
import ChannelFormState from './models/ChannelFormState';
import ShowChannel from './models/ShowChannel';

export default function getInitialChannelFormState(
  channel?: Partial<ShowChannel>,
): ChannelFormState {
  if (channel) {
    return {
      ...INITIAL_CHANNEL_FORM_STATE,
      ...channel,
      ownersText: channel?.owners?.map((owner) => owner.email).join(', ') ?? '',
      publishersText: channel?.publishers?.map((publisher) => publisher.email).join(', ') ?? '',
      thumbnailUrl: '',
    };
  }

  return INITIAL_CHANNEL_FORM_STATE;
}
