import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export default class BackendClient {
  constructor(private getAccessToken: () => Promise<string>) {}

  async get<T>(url: string, config?: AxiosRequestConfig) {
    return this.getAuthenticatedClient().then((client) => client.get<T>(url, config));
  }

  async post<T>(url: string, data: unknown, config?: AxiosRequestConfig) {
    return this.getAuthenticatedClient().then((client) => client.post<T>(url, data, config));
  }

  async put<T>(url: string, data: unknown, config?: AxiosRequestConfig) {
    return this.getAuthenticatedClient().then((client) => client.put<T>(url, data, config));
  }

  async delete<T>(url: string, config?: AxiosRequestConfig) {
    return this.getAuthenticatedClient().then((client) => client.delete<T>(url, config));
  }

  async patch<T>(url: string, data: unknown, config?: AxiosRequestConfig) {
    return this.getAuthenticatedClient().then((client) => client.patch<T>(url, data, config));
  }

  private async getAuthenticatedClient(): Promise<AxiosInstance> {
    const token = await this.getAccessToken();
    return axios.create({
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/vnd.api+json' },
    });
  }
}
