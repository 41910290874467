import SearchParameters from 'channels/models/SearchParameters';
import UserSearchParameters from 'users/models/UserSearchParameters';

export default function adminPagePath({
  query,
  categories,
  visibility,
}:
Partial<SearchParameters> = {}, {
  userQuery,
}:
Partial<UserSearchParameters> = {}): string {
  const params:
  {
    userQuery?: string;
    query?: string;
    categories?: string;
    visibility?: string
  } = {};

  if (query) {
    params.query = query;
  }

  if (userQuery) {
    params.userQuery = userQuery;
  }

  if (categories && categories.length > 0) {
    params.categories = JSON.stringify(categories);
  }
  if (visibility && visibility.length > 0) {
    params.visibility = JSON.stringify(visibility);
  }

  if (Object.keys(params).length > 0) {
    return `/admin?${new URLSearchParams(params)}`;
  }

  return '/admin';
}
