import React, { ReactNode } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import InlineMessage from '@ingka/inline-message';
import Landing from 'landing/components/Landing';
import useAuth from 'authentication/useAuth';

type Props = {
  children: ReactNode;
};

export default function AuthenticationGuard({ children }: Props) {
  const {
    isLoading, isAuthenticated, loginWithRedirect, error,
  } = useAuth();

  if (isLoading) {
    return (
      <Loading text="Loading...">
        <LoadingBall />
      </Loading>
    );
  }

  if (error) {
    const body = `Got error: ${error.message}`;
    return <InlineMessage variant="negative" title="Failed to sign in" body={body} />;
  }

  if (!isAuthenticated) {
    return <Landing loginWithRedirect={loginWithRedirect} />;
  }

  return <>{children}</>;
}
