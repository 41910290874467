import paperclip from '@ingka/ssr-icon/paths/paperclip';
import SSRIcon from '@ingka/ssr-icon';
import React from 'react';
import Notification from 'notifications/models/Notification';
import { Inline, Space } from 'ui';
import NotificationAttribute from './NotificationAttribute';

type Props = {
  notification: Notification;
};

export default function AttachmentNotificationAttribute({ notification }: Props) {
  if (notification.attachmentUrl) {
    return (
      <NotificationAttribute label="Attachment">
        <Inline space={Space.Space50}>
          <SSRIcon paths={paperclip} />
          <a className="link" download href={notification.attachmentUrl}>
            {notification.attachmentName}
          </a>
        </Inline>
      </NotificationAttribute>
    );
  }

  return (
    <NotificationAttribute label="Attachment">
      <Inline space={Space.Space50}>
        <SSRIcon paths={paperclip} />
        <span className="text--grey-500">(No attachment added)</span>
      </Inline>
    </NotificationAttribute>
  );
}
