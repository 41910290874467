import React, { useEffect, useState } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import ChannelService from 'channels/services/ChannelService';
import ErrorPage from 'errors/components/ErrorPage';
import HttpStatusCode from 'http/HttpStatusCode';
import useAsyncResource from 'http/useAsyncResource';
import _ from 'lodash';
import UserService from 'users/services/UserService';
import NewChannelNotification from 'notifications/components/NewChannelNotification';
import NotificationRouter from 'notifications/components/NotificationRouter';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import useBackendService from 'services/useBackendService';
import ChannelSubscription from 'channels/models/ChannelSubscription';
import AccessForbidden from 'admin/components/AccessForbidden';
import UserContextType from 'admin/models/UserContextType';
import NewChannelApproval from 'admin/components/channelManagement/NewChannelApproval';
import Channel from './Channel';
import ChannelNotFound from './ChannelNotFound';
import EditChannel from './EditChannel';
import ShowChannelNotifications from './ShowChannelNotifications';

export default function ChannelRouter() {
  const match = useRouteMatch<{ id: string }>('/channels/:id');
  const isAdmin = false;
  const { id } = match?.params ?? { id: '' };
  const service = useBackendService(ChannelService);
  const [channel, error] = useAsyncResource(async () => service.findById(id));
  const userService = useBackendService(UserService);
  const [user, userError] = useAsyncResource(() => userService.findCurrentUser());
  const [state, setChannel] = useState(channel);
  const [subscription, setSubscription] = useState<ChannelSubscription | any>();

  const hasPermission = user?.privileges
    .some((p) => p.userContextType === UserContextType.Notifications);
  const isPublisher = _.some(
    state?.publishers,
    (publisher) => publisher.id.toLowerCase() === user?.id.toLowerCase(),
  );
  const isOwner = _.some(
    state?.owners,
    (owner) => owner.id.toLowerCase() === user?.id.toLowerCase(),
  );
  useEffect(() => {
    if (state) {
      setChannel(state);
    } else {
      setChannel(channel);
    }
  }, [state, channel]);

  useEffect(() => {
    if (channel) {
      setSubscription(channel.subscription);
    }
  }, [channel]);

  if (error) {
    if (_.get(error, ['response', 'status']) === HttpStatusCode.NotFound) {
      return <ChannelNotFound id={id} />;
    } return <ErrorPage error={error} />;
  }
  if (userError) {
    return <ErrorPage error={userError} />;
  }

  if (state && subscription && user) {
    return (
      <Switch>

        <Route path={`${match?.path}/edit`}>
          {isOwner && state.approvalStatus === 'Approved'
            ? (
              <EditChannel
                isAdmin={isAdmin}
                channel={state}
                editedChannel={state}
                setChannel={setChannel}
              />

            ) : <AccessForbidden />}
        </Route>
        <Route path={`${match?.path}/notifications/new`}>
          <NewChannelNotification channel={state} />
        </Route>

        <Route path={`${match?.path}/notifications/:id`}>
          {hasPermission || isOwner || isPublisher ? (

            <NotificationRouter channel={state} />

          ) : <AccessForbidden />}
        </Route>
        <Route path={`${match?.path}/notifications`}>
          <ShowChannelNotifications channel={state} />
        </Route>
        {state.approvalStatus === 'Approved'
          ? (
            <Route path={`${match?.path}`}>
              <Channel
                channel={state}
                user={user}
                owners={state.owners}
                subscription={subscription}
                setSubscription={setSubscription}
              />
            </Route>
          )
          : <NewChannelApproval />}
      </Switch>
    );
  }

  return (
    <Loading>
      <LoadingBall />
    </Loading>
  );
}
