import React, { ReactNode } from 'react';
import ColorName from 'ui/models/ColorName';

type Props = {
  children: ReactNode;
  color?: ColorName;
};

export default function TimelineEntry({ children, color }: Props) {
  return (
    <>
      <div className="timeline__header">
        <div className="timeline__line" />
        <div className={`timeline__dot timeline__dot--${color}`} />
      </div>

      <div>{children}</div>
    </>
  );
}

TimelineEntry.defaultProps = {
  color: ColorName.Grey900,
};
