import { InputFieldProps } from '@ingka/input-field';
import React, { useRef, useState } from 'react';
import paperclip from '@ingka/ssr-icon/paths/paperclip';
import SSRIcon from '@ingka/ssr-icon';
import FormFieldStatus from '@ingka/form-field-status';

type Props = Pick<
InputFieldProps,
'id' | 'onChange' | 'label' | 'validation' | 'valid' | 'name' | 'helper'
>;

export default function FileInput({
  id,
  onChange = () => {},
  label,
  validation,
  valid,
  name,
  helper,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileList | null>(null);
  const getText = () => {
    if (files && files.length > 0) {
      const filesArray = Array.prototype.slice.call(files);
      return (
        <>
          {label}
          {' '}
          (
          {filesArray.map((file) => file.name).join(', ')}
          )
        </>
      );
    }

    return <>{label}</>;
  };
  let className = 'input-field input-field--leading-icon';
  if (!valid) {
    className += ' input-field--error';
  }

  return (
    <div className={className}>
      <button
        className="input-field__wrapper btn--plain"
        type="button"
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <input
          type="file"
          aria-required="false"
          name={name}
          id={id}
          ref={inputRef}
          style={{ display: 'none' }}
          aria-invalid={!valid}
          onChange={(event) => {
            setFiles(event.target.files);
            return onChange(event);
          }}
        />
        <SSRIcon paths={paperclip} />
        <label htmlFor={`${id}-input`}>{getText()}</label>
        <span className="input-field__divider" />
      </button>
      <FormFieldStatus
        valid={valid}
        shouldValidate={!valid}
        validation={validation}
        helper={helper}
      />
    </div>
  );
}
