import React from 'react';
import { Link } from 'react-router-dom';
import channelsPath from 'channels/channelsPath';
import { Column, Row } from 'ui';

export default function ChannelNotFound({ id }: { id: string }) {
  return (
    <>
      <h1>Channel not found</h1>
      <Row>
        <Column size={{ s: 6 }}>
          <p>
            Channel with id &quot;
            {id}
            &quot; could not be found. Please check your spelling or go back to the
            {' '}
            <Link to={channelsPath()}>channel search</Link>
            .
          </p>
        </Column>
      </Row>
    </>
  );
}
