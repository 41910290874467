import React, { ReactNode } from 'react';
import useBackendService from 'services/useBackendService';
import ErrorPage from 'errors/components/ErrorPage';
import _ from 'lodash';
import HttpStatusCode from 'http/HttpStatusCode';
import AppAccessForbidden from 'authorization/components/AppAccessForbidden';
import useAsyncResource from 'http/useAsyncResource';
import UserService from 'users/services/UserService';
import InactiveUserMessage from './InactiveUserMessage';

type Props = {
  children: ReactNode;
};

export default function UserStatus({ children }: Props) {
  const userService = useBackendService(UserService);
  const [user, error] = useAsyncResource(() => userService.findCurrentUser());

  if (error) {
    if (_.get(error, ['response', 'status']) === HttpStatusCode.Forbidden) {
      return <AppAccessForbidden />;
    }

    return <ErrorPage error={error} />;
  }

  if (user && !user.isActive) {
    return <InactiveUserMessage />;
  }
  return <>{children}</>;
}
