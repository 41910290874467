enum Space {
  None = '0',
  Space25 = '25',
  Space50 = '50',
  Space75 = '75',
  Space100 = '100',
  Space125 = '125',
  Space150 = '150',
  Space200 = '200',
  Space250 = '250',
  Space300 = '300',
  Space400 = '400',
  Space550 = '550',
  Space750 = '750',
  Space1000 = '1000',
  Space1350 = '1350',
}

export default Space;
