import React from 'react';
import ChannelVisibility from 'channels/models/ChannelVisibility';
import Switch from '@ingka/switch';

export default function VisibilitySelect({
  isVisibilityActive,
  toggleVisibility,
}: {
  isVisibilityActive: (visibility: ChannelVisibility) => boolean;
  toggleVisibility: (visibility: ChannelVisibility) => void;
}) {
  return (
    <Switch
      id="Channel-visibility"
      checked={isVisibilityActive(
        ChannelVisibility.Hidden ? ChannelVisibility.Hidden : ChannelVisibility.Visible,
      )}
      label={isVisibilityActive(ChannelVisibility.Hidden) ? <h2>Visible</h2> : <h2>Hidden</h2>}
      onChange={() => {
        toggleVisibility(
          ChannelVisibility.Hidden ? ChannelVisibility.Hidden : ChannelVisibility.Visible,
        );
      }}
    />
  );
}
