export default class TermsOfUseAgreement {
  id: string;

  decision: 'accepted' | 'declined';

  createdAt: Date;

  constructor({ id, decision, createdAt }: TermsOfUseAgreement) {
    this.id = id;

    this.decision = decision;

    this.createdAt = createdAt;
  }
}
