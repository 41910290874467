import React from 'react';

type Props = {
  text: string;
};

export default function TimelineSeparator({ text }: Props) {
  return (
    <>
      <div className="timeline__header">
        <div className="padding--y-200">{text}</div>
      </div>

      <div />
    </>
  );
}
