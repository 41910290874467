import { RouteHandler } from 'miragejs/server';
import { ModelInstance, Response } from 'miragejs';
import NotificationType from 'notifications/models/NotificationType';
import NotificationStatus from 'notifications/models/NotificationStatus';
import hasPublishingRights from './hasPublishingRights';
import Server from './models/Server';
import findCurrentUser from './findCurrentUser';

export default function declareUserRoutes(this: Server) {
  this.get('/user/get-user', (schema) => {
    const user = findCurrentUser(schema);

    if (user) {
      return user;
    }

    return new Response(404);
  });

  this.patch(
    '/user',
    function handler(
      this: RouteHandler<any> & { normalizedRequestAttrs: (type: string) => any },
      schema,
    ) {
      const user = findCurrentUser(schema);

      if (user) {
        user.update(this.normalizedRequestAttrs('user'));
        return user;
      }

      return new Response(404);
    },
  );

  this.post('/users', (schema) => {
    const agreement = schema.create('termsOfUseAgreement', { decision: 'accepted' });
    return schema.create('user', { termsOfUseAgreements: [agreement] });
  });

  this.get('/user/publishable-channels', (schema) => {
    const user = findCurrentUser(schema);

    if (user) {
      return (schema.where('channel', {}) as any).filter((channel: any) => hasPublishingRights(user, channel));
    }

    return new Response(404);
  });

  this.get('/user/open-notifications', (schema) => {
    const user = findCurrentUser(schema);

    if (user) {
      return (schema.where('notification', {}) as any).filter(
        (n: ModelInstance<any>) => hasPublishingRights(user, n.channel)
          && n.attrs.type === NotificationType.Issue
          && n.attrs.status === NotificationStatus.InProgress,
      );
    }

    return new Response(404);
  });
}
