import ChannelsState from 'channels/models/ChannelsState';
import { Factory } from 'fishery';

export default Factory.define<ChannelsState>(() => ({
  nextPage: null,
  query: '',
  categories: [],
  visibility: [],
  isSearchPerformed: false,
  channels: [],
  latestAction: 'INIT',
}));
