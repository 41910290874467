import ShowChannel from 'channels/models/ShowChannel';
import _ from 'lodash';
import PreviewNotification from 'notifications/models/PreviewNotification';
import React from 'react';
import { Space, Stack } from 'ui';

type Props = {
  notification: PreviewNotification;
  channel: ShowChannel;
};

export default function PushNotificationPreview({ notification, channel }: Props) {
  function toFriendlyString(value: any): string {
    if (_.isNil(value)) {
      return '';
    }

    return value;
  }

  return (
    <Stack space={Space.Space100}>
      <p className="text--align-center">Push Notification</p>
      <div className="background--grey-200 border-radius--200 padding--200">
        <Stack space={Space.Space200}>
          <h3>{channel.name}</h3>

          <div>
            <span>
              {`${toFriendlyString(notification.title)} - ${toFriendlyString(notification.status)}`}
            </span>

            <br />
            <br />

            <span>{toFriendlyString(notification.headline)}</span>
            <br />
            <br />
          </div>
        </Stack>
      </div>
    </Stack>
  );
}
