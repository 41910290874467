import { IconLink, Space, Stack } from 'ui';
import React from 'react';
import arrowLeftIconPath from '@ingka/ssr-icon/paths/arrow-left';
import Table, { TableBody, TableHeader } from '@ingka/table';
import cross from '@ingka/ssr-icon/paths/cross';
import ShowRegion from 'regions/models/ShowRegion';
import deleteCountryPath from 'admin/services/deleteCountryPath';
import { useHistory } from 'react-router-dom';
import newCountryPath from 'admin/services/newCountryPath';
import Button from '@ingka/button';

export default function CountriesTable({
  region,

}: {
  region: ShowRegion;
}) {
  const history = useHistory();
  return (

    <>
      <Stack space={Space.Space200}>
        <Button onClick={() => history.goBack()} type="tertiary" ssrIcon={arrowLeftIconPath} />
        <h2>{`Countries for '${region.name}'`}</h2>

        <Stack space={Space.Space200}>
          <Button text=" Add new country" type="emphasised" onClick={() => history.push(newCountryPath(region))} />
        </Stack>

        <Stack space={Space.Space150}>
          <Table
            className="demo-sticky-table"
            inset
          >
            <TableHeader sticky>
              <tr>
                <th>
                  <h2>Country</h2>
                </th>
                <th>
                  <h2>Country code</h2>
                </th>
                <th>
                  <h2>Actions</h2>
                </th>
              </tr>
            </TableHeader>
            <TableBody striped>
              {region.countries.map((country) => (
                <tr key={country.id}>
                  <td>
                    <div className="tabel__body">
                      {country.name}
                    </div>
                  </td>
                  <td>
                    <div className="tabel__body">
                      {country.code}
                    </div>
                  </td>
                  <td>
                    <div className="tabel__body">
                      <IconLink icon={cross} text="Delete country" to={deleteCountryPath(country)} />
                    </div>
                  </td>
                </tr>
              ))}
            </TableBody>
          </Table>
        </Stack>
      </Stack>
    </>
  );
}
