import _ from 'lodash';

/*
 * Return the given object excluding keys with nil values.
 *
 * @Param {Object} object = An object.
 *
 * @Example
 *
 *     compact({ pizza: 'Yummy', banana: undefined, salad: null })
 *     //=> { pizza: 'Yummy' }
 */
export default function compact<T extends Record<string, any>>(
  obj: T,
): { [key in keyof T]?: NonNullable<T[key]> } {
  return _.pickBy(obj, (val) => !_.isNil(val)) as any;
}
