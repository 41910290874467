import React, { Dispatch, useEffect, useState } from 'react';
import _ from 'lodash';
import { Space, Stack } from 'ui';
import ChannelsState from 'channels/models/ChannelsState';
import buildSearchText from 'channels/buildSearchText';
import UserSelectSearchParams from 'users/models/UserSelectSearchParams';
import useBackendService from 'services/useBackendService';
import { useHistory } from 'react-router-dom';
import Search from '@ingka/search';
import UserState from 'profile/models/UserState';
import UserAction from 'profile/models/UserAction';
import UserService from 'users/services/UserService';
import adminPagePath from 'admin/services/adminPagePath';
import selectSearchParams from 'channels/selectSearchParams';
import UserSearchResult from './UserSearchResult';

type Props = {
  state: UserState;
  channelState: ChannelsState
  dispatch: Dispatch<UserAction>;
};

export default function UsersTable({ dispatch, state, channelState }: Props) {
  const history = useHistory();
  const { userQuery, isSearchPerformed, latestAction } = state;
  const [searchText, setSearchText] = useState(buildSearchText(
    userQuery,
    isSearchPerformed,
    state,
  ));
  const service = useBackendService(UserService);

  useEffect(() => {
    if (['SEARCH', 'LOAD_MORE', 'INIT'].includes(latestAction)) {
      const params = UserSelectSearchParams(state);
      const channelParams = selectSearchParams(channelState);
      history.push(adminPagePath(channelParams, params));
      setSearchText(buildSearchText(userQuery, isSearchPerformed, state));
      service.search(params).then(({ users: foundUsers, nextPage: page }) => {
        if (latestAction === 'LOAD_MORE') {
          return dispatch({ type: 'APPEND_RESULT', foundUsers, nextPage: page });
        }

        return dispatch({ type: 'SET_RESULT', foundUsers, nextPage: page });
      });
    }
  }, [history, isSearchPerformed, userQuery, dispatch, state, channelState, latestAction, service]);

  return (
    <Stack space={Space.Space200}>
      <Stack space={Space.Space550}>

        <Search
          id="user-search"
          placeholder="Search for users by id, name or email"
          ariaLabel="Search among users"
          onClear={() => dispatch({ type: 'SET_User_QUERY', userQuery: '' })}
          onChange={(ev) => dispatch({ type: 'SET_User_QUERY', userQuery: ev.target.value })}
          onSearch={() => dispatch({ type: 'SEARCH' })}
        />

        <UserSearchResult
          searchText={searchText}
          users={state.users}
          isAllLoaded={_.isNil(state.nextPage)}
          onLoadMore={() => dispatch({ type: 'LOAD_MORE' })}
        />
      </Stack>
    </Stack>

  );
}
