import React, { useEffect, useState } from 'react';
import { Space, Stack } from 'ui';
import NotificationType from 'notifications/models/NotificationType';
import CreateNotification from 'channels/models/CreateNotification';
import ShowChannel from 'channels/models/ShowChannel';
import toPreviewNotification from 'notifications/toPreviewNotification';
import useBackendService from 'services/useBackendService';
import Region from 'regions/models/Region';
import RegionService from 'regions/services/RegionService';
import Loading, { LoadingBall } from '@ingka/loading';
import ErrorPage from 'errors/components/ErrorPage';
import EmailPreview from './EmailPreview';
import PushNotificationPreview from './PushNotificationPreview';
import SmsPreview from './SmsPreview';

type Props = {
  notification: Partial<CreateNotification>;
  channel: ShowChannel;
};

export default function NotificationPreview({ notification, channel }: Props) {
  const previewNotification = toPreviewNotification(notification, channel);
  const countries = notification.countries ?? [];
  const countriesForNow = countries.map((country) => country.id);
  const [state, setState] = useState('INITIAL');
  const [error, setError] = useState<Error | undefined>(undefined);
  const [regions, setRegions] = useState<Region[]>([]);
  const regionService = useBackendService(RegionService);
  useEffect(() => {
    if (state === 'INITIAL') {
      setState('FETCHING_REGIONS');
      regionService
        .findAll()
        .then((newRegions) => {
          setRegions(newRegions);
          setState('FETCHED_REGIONS');
        })
        .catch((err) => {
          setError(err);
          setState('FAILED_FETCHING_REGIONS');
        });
    }
  }, [regionService, state]);

  if (state === 'FAILED_FETCHING_REGIONS') {
    return <ErrorPage error={error ?? new Error()} />;
  }

  regions.forEach((item) => {
    let count = 0;
    const countryOnRegions = item.countries.length;
    countriesForNow?.forEach((selectedContry) => {
      for (let i = 0; i < item.countries.length; i += 1) {
        if (selectedContry === item.countries[i].id) {
          count += 1;
        }
      }
    });
    if (count === countryOnRegions) {
      previewNotification.countryCodes.push(item.name);
    } else {
      item.countries.filter((c1) => countries.some((c2) => c1.code === c2.code))
        .map((c) => (previewNotification.countryCodes.push(c.name)));
    }
  });
  if (notification.notificationType === NotificationType.Issue) {
    return (
      <Stack space={Space.Space400}>
        {state === 'FETCHING_REGIONS' ? (
          <Loading text="Loading regions...">
            <LoadingBall />
          </Loading>
        ) : (
          <EmailPreview notification={previewNotification} />
        )}
        <PushNotificationPreview notification={previewNotification} channel={channel} />
        <SmsPreview notification={previewNotification} channel={channel} />
      </Stack>
    );
  }

  return (
    <Stack space={Space.Space200}>
      {state === 'FETCHING_REGIONS' ? (
        <Loading text="Loading regions...">
          <LoadingBall />
        </Loading>
      ) : (
        <EmailPreview notification={previewNotification} />
      )}
      <p>SMS and push notifications are not shown, since they are only sent out for issues.</p>
    </Stack>
  );
}
