import useAuth from 'authentication/useAuth';
import BackendClient from 'http/services/BackendClient';
import { useState } from 'react';

export default function useBackendService<T>(ServiceClass: new (client: BackendClient) => T): T {
  const { getAccessTokenSilently } = useAuth();
  const client = new BackendClient(getAccessTokenSilently);
  const [service] = useState(new ServiceClass(client));
  return service;
}
