import React from 'react';
import List from '@ingka/list';
import Space from 'ui/models/Space';
import {
  Column, ColumnSize, Row, Stack,
} from 'ui';
import howToGoToChannelDetails from 'assets/how-to-go-to-channel-details.jpg';
import howToSubscribeToAChannel from 'assets/how-to-subscribe-to-a-channel.jpg';

export default function HowToSubscribeToAChannel() {
  return (
    <Stack space={Space.Space150}>
      <List ordered>
        <li>
          Find your information channel by using the search field or scroll down on the main page.
        </li>
        <li>
          <Stack space={Space.Space100}>
            Go to the information channel details.
            <Row space={Space.Space200}>
              <Column size={{ m: ColumnSize.Half }}>
                <img
                  className="width--100"
                  src={howToGoToChannelDetails}
                  alt="Hand pointing to arrow which leads to channel details"
                />
              </Column>
              <Column size={{ m: ColumnSize.Half }}>
                Click on the arrow at the left placed at the end of the information channel card to
                open the channel details.
              </Column>
            </Row>
          </Stack>
        </li>

        <li>
          <Stack space={Space.Space100}>
            Click on Subscribe toggle switch.
            <Row space={Space.Space200}>
              <Column size={{ m: ColumnSize.Half }}>
                <img
                  className="width--100"
                  src={howToSubscribeToAChannel}
                  alt="Channel page with a hand pointing on the subscribe button."
                />
              </Column>
              <Column size={{ m: ColumnSize.Half }}>
                Click on the Subscribe toggle switch and the confirmation of choice will be visible
                as notification type options and notifications to choose becomes highlighted.
              </Column>
            </Row>
          </Stack>
        </li>
      </List>
    </Stack>
  );
}
